






































































































































import Vue from "vue";
import { config, env, saveLocalstorage, updateLocalConfigOverrideStatus } from "@/services/config";
import { formatLargeNumber } from "@/lib-on-fhir/utils";
import { resourceResolver } from "@/services/resolvers/ResourceResolver";
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import { CapabilityStatementRestResource } from "fhir/r4";
import { eventBus } from "@/state/eventBus";
import { resourceCategories } from "@/data/resourceCategories"; // Import the categorized resources

interface ResourceItem {
  name: string;
  icon: string;
}

interface ResourceCategory {
  displayName: string;
  level: number;
  resources: ResourceItem[];
}

interface ResourceCategories {
  [key: string]: ResourceCategory;
}

interface CategorizedResources {
  [level: number]: {
    [category: string]: {
      displayName: string;
      level: number;
      resources: string[];
    }
  }
}

export default Vue.extend({
  setup(_, { root }) {
    const capabilityStatement = ref<fhir4.CapabilityStatement | null>(null);

    const allResourceTypes = computed(() => {
      if (!capabilityStatement.value || !capabilityStatement.value.rest || !capabilityStatement.value.rest[0].resource) return [];
      let resources: CapabilityStatementRestResource[] = capabilityStatement.value.rest[0].resource!;
      return resources?.map((r) => r.type);
    });

    onMounted(async () => {
      capabilityStatement.value = await resourceResolver.getCapabilityStatement();
      console.log("capabilityStatement", capabilityStatement.value);
    });

    const resourceTypeCounts = ref<{ [resource: string]: number }>({});
    watch(allResourceTypes, async (newVal) => {
      if (!newVal) return;
      for (let resourceType of newVal) {
        let count = await resourceResolver.getResourceTypeCount(resourceType);
        Vue.set(resourceTypeCounts.value, resourceType, count);
      }
      eventBus.$emit("resource-counts-loaded", resourceTypeCounts.value);
    });

    const categorizedResourcesByLevel = computed(() => {
      const categorized: CategorizedResources = {};
      const allResources = allResourceTypes.value || [];

      for (const categoryKey of Object.keys(resourceCategories)) {
        const categoryData = resourceCategories[categoryKey as keyof typeof resourceCategories];
        const level = categoryData.level;
        const resourcesInCategory = allResources.filter((resource) =>
          categoryData.resources.some((item: ResourceItem) => item.name === resource)
        );

        if (resourcesInCategory.length > 0) {
          if (!categorized[level]) {
            categorized[level] = {};
          }
          categorized[level][categoryKey] = {
            displayName: categoryData.displayName || categoryKey,
            level: categoryData.level,
            resources: resourcesInCategory,
          };
        }
      }

      return categorized;
    });

    const levelChecked = ref<{ [level: number]: boolean }>({});
    const categoryChecked = ref<{ [category: string]: boolean }>({});

    const prettyPrintResourceName = (resourceType: string) => {
      // insert whitespace before every capital letter
      resourceType = resourceType.replace(/([A-Z])/g, " $1");
      return resourceType;
    };

    const isPrimaryResource = (resourceType: string): boolean => {
      return config.appConfig.primaryResources.some(entry => entry.baseResource === resourceType);
    };

    const getResourceIcon = (resourceType: string): string => {
      for (const category of Object.keys(resourceCategories)) {
        const resourceItem = resourceCategories[category as keyof typeof resourceCategories].resources.find((item: ResourceItem) => item.name === resourceType);
        if (resourceItem) {
          return resourceItem.icon;
        }
      }
      return "mdi-default-icon";
    };

    const handleCheckboxChange = (resourceType: string, event: boolean): void => {
      if (event) {
        // Checkbox is checked
        const exists = config.appConfig.primaryResources.some(resource => resource.baseResource === resourceType);
        if (!exists) {
          const icon = getResourceIcon(resourceType);
          const newResource = {
            name: resourceType,
            singularName: resourceType,
            icon: icon,
            baseResource: resourceType,
            inactiveColumns: [],
            columns: [
              {
                label: "ID",
                paths: ["id"],
                internalField: "_id",
                renderer: "text"
              }
            ]
          };
          config.appConfig.primaryResources.push(newResource);
        }
      } else {
        config.appConfig.primaryResources = config.appConfig.primaryResources.filter(resource => resource.baseResource !== resourceType);
      }
      saveLocalstorage();
      const updatedEnv = updateLocalConfigOverrideStatus() || { localConfigOverride: false, environment: env, isDev: false };
      if (updatedEnv) {
        Vue.set(root, 'env', updatedEnv);
      } else {
        console.error("Failed to update environment configuration");
      }
    };

    const isLevelSelected = (level: number): boolean => {
      const categories = categorizedResourcesByLevel.value[level];
      return Object.keys(categories).every(category => isCategorySelected(category));
    };

    const isLevelIndeterminate = (level: number): boolean => {
      const categories = categorizedResourcesByLevel.value[level];
      let hasSelected = false;
      let hasUnselected = false;

      Object.keys(categories).forEach(category => {
        if (isCategorySelected(category)) {
          hasSelected = true;
        } else if (isCategoryIndeterminate(category)) {
          hasSelected = true;
          hasUnselected = true;
        } else {
          hasUnselected = true;
        }
      });

      return hasSelected && hasUnselected;
    };

    const handleLevelCheckboxChange = (level: number, event: boolean): void => {
      const categories = categorizedResourcesByLevel.value[level];
      Object.keys(categories).forEach(category => {
        handleCategoryCheckboxChange(category, event);
      });
    };

    const toggleLevelCheckbox = (level: number): void => {
      const isSelected = isLevelSelected(level);
      handleLevelCheckboxChange(level, !isSelected);
    };

    const isCategorySelected = (categoryName: string): boolean => {
      const resources = resourceCategories[categoryName as keyof typeof resourceCategories].resources.map((r: ResourceItem) => r.name);
      return resources.every(resource => isPrimaryResource(resource));
    };

    const isCategoryIndeterminate = (categoryName: string): boolean => {
      const resources = resourceCategories[categoryName as keyof typeof resourceCategories].resources.map((r: ResourceItem) => r.name);
      const selectedResources = resources.filter(resource => isPrimaryResource(resource));
      return selectedResources.length > 0 && selectedResources.length < resources.length;
    };

    const handleCategoryCheckboxChange = (categoryName: string, event: boolean): void => {
      const resources = resourceCategories[categoryName as keyof typeof resourceCategories].resources.map((r: ResourceItem) => r.name);
      const availableResources = resources.filter(resource => allResourceTypes.value.includes(resource));
      
      availableResources.forEach((resource: string) => {
        handleCheckboxChange(resource, event);
      });
    };

    const toggleCategoryCheckbox = (categoryName: string): void => {
      const isSelected = isCategorySelected(categoryName);
      handleCategoryCheckboxChange(categoryName, !isSelected);
    };

    return {
      prettyPrintResourceName,
      capabilityStatement,
      allResourceTypes,
      resourceTypeCounts,
      categorizedResourcesByLevel,
      isPrimaryResource,
      getResourceIcon,
      handleCheckboxChange,
      isLevelSelected,
      isLevelIndeterminate,
      handleLevelCheckboxChange,
      toggleLevelCheckbox,
      isCategorySelected,
      isCategoryIndeterminate,
      handleCategoryCheckboxChange,
      toggleCategoryCheckbox,
      levelChecked,
      categoryChecked,
    };
  },
  data() {
    return {
      appConfig: config.appConfig,
      env: env,
      config: config,
      user: {
        firstName: "Testina",
        lastName: "Test",
      },
    };
  },
  methods: {
    formatLargeNumber, // Add formatLargeNumber to methods
  },
});
