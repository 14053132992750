
















































































































































































































































































import { COLUMN_RENDERERS } from "@/components/column-renderers";
import { ColumnConfig } from "@/config/config";
import { deepClone } from "@/lib-on-fhir";
import { saveLocalstorage } from "@/services/config";
import { getSearchParametersForResource } from "@/services/helpers";
import { useResourceListStore } from "@/state/resourceListStore";
import Vue from "vue";
import draggable from "vuedraggable";
export default Vue.extend({
  data: () => ({
    columns: [] as ColumnConfig[],
    inactiveColumns: [] as ColumnConfig[],

    currentColumn: null as null | ColumnConfig,
  }),
  components: {
    draggable,
  },

  computed: {
    store() {
      return useResourceListStore();
    },
    availableRenderers() {
      return Array.from(Object.entries(COLUMN_RENDERERS)).map(([key, value]) => ({
        value: key,
        text: value.name
      }));
    },

    availableInternalReferences() {
      return [
        { value: "", text: "<None>" },
        ...getSearchParametersForResource(useResourceListStore().resourceType).map(entry => ({
          value: entry.code,
          text: entry.code,
        }))
      ];
    },

    showColumnPaths() {
      return this.currentColumn?.renderer !== 'validation';
    },

    showInternalReference() {
      return this.currentColumn?.renderer !== 'validation';
    },

  },

  mounted() {
    this.currentColumn = null;

    const config = this.store.resourceConfig;
    this.columns = deepClone(config.columns);
    this.inactiveColumns = deepClone(config.inactiveColumns);
    if (this.columns.length > 0) {
      this.selectColumn(this.columns[0]);
    }
  },

  methods: {
    cancel() {
      this.$emit("close");
    },

    deleteCurrentColumm() {
      this.columns = this.columns.filter(column => column !== this.currentColumn);
      this.currentColumn = null;
    },

    resetToDefault() {
      alert("Not yet implemented - coming soon!");
    },

    selectColumn(column: ColumnConfig) {
      this.currentColumn = column;
      if (!COLUMN_RENDERERS[column.renderer!]) {
        column.renderer = "text";
      }
      if (!column.internalField) {
        column.internalField = "";
      }
    },

    addNewColumn() {
      const column: ColumnConfig = {
        label: "New column",
        paths: ["<path>"],
        userDefined: true,
        renderer: "text"
      };
      this.columns.push(column);
      this.currentColumn = column;
    },

    save() {
      const config = this.store.resourceConfig;
      config.columns = this.columns;
      config.inactiveColumns = this.inactiveColumns;
      saveLocalstorage();

      useResourceListStore().refresh();
      this.$emit("close");
    }
  },
});
