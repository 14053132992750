import {
    BaseDefinition,
    ChoiceDefinition,
    ContextualLogger,
    decapitalizeString,
    ElementDefinition,
    InvalidProfileError,
    JSONLoaderExtractor,
    PrimitiveDefinition,
    Profile,
    ProfileNotSupportedError,
    ProfilePathMember,
    ProfilePathParser,
    ProfilePathResource,
    ProfilePathSlice,
    SlicedElementDefinition,
    SlicedPrimitiveDefinition,
    SystemTypeMappings,
} from '@/lib-on-fhir';
import FHIR from 'fhir/r4';

// Shared payload type for adding a new member, slice or resource
type LoaderPayload<Accessor> = {
    parent: BaseDefinition | null;
    accessor: Accessor;
    element: FHIR.ElementDefinition;
    logger: ContextualLogger;

    anySlicesDefined: boolean;
    anyFieldsDefined: boolean;
};

const ProfileKindToDefinitionClass: Partial<Record<
    FHIR.StructureDefinition['kind'],
    new () => ElementDefinition
>> = {
    'complex-type': ElementDefinition,
    'resource': ElementDefinition,
    'primitive-type': PrimitiveDefinition,
};

/**
 * Allows to load JSON structured FHIR profiles into our internal profile tree
 */
export class ProfileLoader {
    protected profile: Profile;
    protected extractor = new JSONLoaderExtractor();

    constructor(profile: Profile) {
        this.profile = profile;
    }

    /**
     * Loads a new profile from a fhir JSON Structure Definition into this profile
     */
    public loadProfile(
        definition: FHIR.StructureDefinition,
        logger: ContextualLogger = this.profile.logger,
        profileScope: string = ''
    ) {
        logger.debug('LOAD canonical profile:', definition.url);

        if (!definition.snapshot) {
            throw logger.scopedException(
                ProfileNotSupportedError,
                'Profiles without snapshot are not supported yet: ' +
                    definition.url +
                    ' / ' +
                    definition.id
            );
        }

        // Pre parse the $path
        const elements = definition.snapshot.element.map(element => ({
            ...element,
            $path: ProfilePathParser.parse(element.id!, profileScope),
        }));

        for (const element of elements) {
            const parentPath = element.$path.slice();
            const accessor = parentPath.pop()!;
            let parent: BaseDefinition | null = null;

            if (parentPath.length > 0) {
                // Load parent, so we know where to add this element
                parent = this.profile.traverser.traverse(parentPath);
            }

            if (parent?.logger) {
                logger = parent.logger;
            }

            // @todo: Find better solution
            // Figuring out if the profile contains any child definitions for either slices or fields
            const childTypes = elements
                .filter(entry => ProfilePathParser.isChildOf(element.$path, entry.$path))
                .map(child => child.$path[element.$path.length].type);

            const payload: Omit<LoaderPayload<any>, 'logger'> = {
                parent,
                accessor,
                element,

                anyFieldsDefined: childTypes.includes('member'),
                anySlicesDefined: childTypes.includes('slice'),
            };

            // Apply the right loading strategy based on the element type
            switch (accessor.type) {
                case 'resource': {
                    this.loadResourceFromSnapshot({
                        ...payload,
                        definition,
                        logger: logger.child('#' + accessor.resource),
                    });
                    break;
                }
                case 'member': {
                    this.loadMemberFromSnapshot({
                        ...payload,
                        logger: logger.child('.' + accessor.member),
                    });
                    break;
                }
                case 'slice': {
                    this.loadSliceFromSnapshot({
                        ...payload,
                        logger: logger.child(':' + accessor.slice),
                    });
                    break;
                }
            }
        }
    }

    /**
     * Internal method to load a new base resource into the profile
     */
    protected loadResourceFromSnapshot({
        parent,
        accessor,
        element,
        logger,
        definition,
    }: LoaderPayload<ProfilePathResource> & { definition: FHIR.StructureDefinition }) {
        if (parent) {
            throw logger.scopedException(
                InvalidProfileError,
                `Base resources can only be loaded on root level: '${accessor.resource}'`
            );
        }
        if (this.profile.definitions[accessor.resource]) {
            throw logger.scopedException(
                InvalidProfileError,
                `Double definition for ${logger.scope} at ${accessor.resource}`
            );
        }

        logger.debug(`Creating ElementDefinition for ${accessor.resource}`);
        const elementClass = ProfileKindToDefinitionClass[definition.kind];
        if (!elementClass) {
            throw logger.scopedException(
                ProfileNotSupportedError,
                `Profile kind ${definition.kind} not yet supported`
            );
        }

        const childDefinition = new elementClass();
        childDefinition.$profile = this.profile;
        childDefinition.typeName = element.id!;
        childDefinition.canonical = accessor.resource;
        childDefinition.logger = logger;
        this.extractor.extractAllNonTypeAware(childDefinition, element);

        if (element.type) {
            throw logger.scopedException(
                ProfileNotSupportedError,
                `Resource should not have type field.`
            );
        }

        logger.debug(`ADD type '${accessor.resource}' on profile`);
        this.profile.definitions[accessor.resource] = childDefinition;
    }

    /**
     * Helper method to load all member types from a FHIR.ElementDefinition.
     * This handles both regular types and content references. The result is
     * a list of extracted types
     */
    protected loadMemberTypesFromSnapshot({
        element,
        logger,
        anyFieldsDefined,
        anySlicesDefined,
    }: LoaderPayload<ProfilePathMember>): BaseDefinition[] {
        if (element.contentReference) {
            // Content references, just copy it
            if (!element.contentReference.startsWith('#')) {
                throw logger.scopedException(
                    InvalidProfileError,
                    'Content references must start with "#": ' + element.contentReference
                );
            }
            const referencePath = ProfilePathParser.parse(element.contentReference.substring(1));
            const entry = this.profile.traverser.traverse(referencePath);
            if (!entry) {
                throw logger.scopedException(
                    InvalidProfileError,
                    'Could not resolve content reference ' + element.contentReference
                );
            }

            // @NOTICE: Content references are an exact clone, EXCEPT for the cardinality
            const shallowClone = entry.cloneShallow();
            this.extractor.extractContentReference(shallowClone, element);
            return [shallowClone];
        } else if (element.type) {
            // Regular type, convert all contained types to our internal definition
            return element.type!.map(type => {
                const typeHandle = this.loadTypeDefinitionsFor({
                    type,
                    logger: logger.child('&' + type.code),
                    anyFieldsDefined,
                    anySlicesDefined,
                });
                this.extractor.extractAll(typeHandle, element, type);
                typeHandle.$profile = this.profile;
                return typeHandle;
            });
        } else {
            console.warn('Invalid Element:', element);
            throw logger.scopedException(
                InvalidProfileError,
                `Member type definition has no type nor content reference: ${element.type}`
            );
        }
    }

    /**
     * Helper method to convert a list of types into the appropriate
     * structure of a ChoiceDefinition. If it's not a choice definition, simply returns
     * the first type.
     */
    protected resolveChoiceTypes({
        accessor,
        element,
        mappedTypes,
        logger,
    }: {
        accessor: ProfilePathMember;
        element: FHIR.ElementDefinition;
        mappedTypes: BaseDefinition[];
        logger: ContextualLogger;
    }): BaseDefinition {
        if (accessor.choiceType) {
            // Choice type
            if (!mappedTypes.every(type => type instanceof ElementDefinition)) {
                throw logger.scopedException(
                    ProfileNotSupportedError,
                    `Choice type must consist only out of element definitions`
                );
            }
            if (mappedTypes.length === 0) {
                throw logger.scopedException(
                    ProfileNotSupportedError,
                    `Choice type must have at least one type`
                );
            }

            // Choice types
            const result = new ChoiceDefinition({
                allowedTypes: mappedTypes as ElementDefinition[],
                baseName: accessor.member,
            });

            result.$profile = this.profile;
            result.logger = logger;

            // @NOTICE: Not sure whether we should load the cardinality here as well.
            this.extractor.extractAllNonTypeAware(result, element);

            return result;
        } else {
            // Regular types
            if (mappedTypes.length !== 1) {
                throw logger.scopedException(
                    InvalidProfileError,
                    `Types list length SHALL be 1 if not a choice type`
                );
            }
            return mappedTypes[0];
        }
    }

    /**
     * Loads the member definition from a profile element definition
     */
    protected loadMemberFromSnapshot({
        parent,
        accessor,
        element,
        logger,
        anyFieldsDefined,
        anySlicesDefined,
    }: LoaderPayload<ProfilePathMember>) {
        if (!parent) {
            throw logger.scopedException(
                InvalidProfileError,
                'Members can only be added with a parent'
            );
        }

        // Step 1: Mapp all types
        const mappedTypes = this.loadMemberTypesFromSnapshot({
            parent,
            accessor,
            element,
            logger,
            anyFieldsDefined,
            anySlicesDefined,
        });

        // Step 2: If it's a choice type, pack it into a ChoiceDefinition
        const result = this.resolveChoiceTypes({
            accessor,
            element,
            logger,
            mappedTypes,
        });

        // Step 3: Find the right spot to add the member on
        const target = parent.getTargetForNewDefinitions();
        if (!(target instanceof ElementDefinition)) {
            throw logger.scopedException(
                InvalidProfileError,
                `New members can only be added on element or choice definitions: parent is ${parent}`
            );
        }

        // Step 4: Finally apply it to the target
        logger.debug(`ADD member '${accessor.member}' on '${parent.toString()}'`);
        if (target.fieldTypes[accessor.member]) {
            throw logger.scopedException(
                InvalidProfileError,
                `Double member definition of ${accessor.member}, is ${
                    target.fieldTypes[accessor.member]
                }`
            );
        }
        target.fieldTypes[accessor.member] = result;
    }

    /**
     * Loads slicing on a choice type, which allows to narrow down a specific
     * data type in cardinality, pattern etc
     */
    protected loadSliceOnChoiceType({
        parent,
        accessor,
        element,
        logger,
    }: LoaderPayload<ProfilePathSlice> & { parent: ChoiceDefinition }) {
        // FHIR specific: e.g. 'deceasedDateTime' slice means slice on the 'dateTime' type of the choice type

        if (!accessor.slice.startsWith(parent.baseName)) {
            throw logger.scopedException(
                InvalidProfileError,
                `Slice definition '${accessor.slice}' on choice type must be prefixed with choice member name '${parent.baseName}'`
            );
        }

        // Find the type name we are looking for, e.g. 'deceasedDateTime' -> 'dateTime'
        const matchedTypeName = decapitalizeString(
            accessor.slice.substring(parent.baseName.length)
        );

        // Try to find matching definition
        const matchedType = parent.allowedTypes.find(type => type.typeName === matchedTypeName);
        if (!matchedType) {
            throw logger.scopedException(
                InvalidProfileError,
                `Slice definition '${
                    accessor.slice
                }' on choice type could not find member for type ${matchedTypeName}, allowed are (${parent.allowedTypes.map(
                    t => t.typeName
                )})`
            );
        }

        // Actually apply the slice now
        if (matchedType instanceof PrimitiveDefinition) {
            if (element.type?.length !== 1) {
                throw logger.scopedException(
                    InvalidProfileError,
                    `Slice definition '${accessor.slice}' on choice type SHALL have exactly one type (has ${element.type?.length})`
                );
            }

            if (matchedType instanceof SlicedPrimitiveDefinition) {
                throw logger.scopedException(
                    ProfileNotSupportedError,
                    `Choice data type was sliced twice, first sliced by '${matchedType.sliceName}' now by '${accessor.slice}' / '${matchedTypeName}'`
                );
            }

            logger.debug('Applying slice on choice primitive type', matchedTypeName);

            const type = element.type[0];
            const slicedDefinition = SlicedPrimitiveDefinition.convertFromNonSliced(matchedType);

            slicedDefinition.sliceName = 'sliced';
            slicedDefinition.logger = logger;
            slicedDefinition.$profile = this.profile;
            this.extractor.extractAll(slicedDefinition, element, type);

            // Replace original type by the sliced definition
            const index = parent.allowedTypes.indexOf(matchedType);
            parent.allowedTypes[index] = slicedDefinition;
        } else if (matchedType instanceof ElementDefinition) {
            // On complex types, would have to replace it with the slice
            throw logger.scopedException(
                ProfileNotSupportedError,
                `Slice on non-primitive choice type not yet supported`
            );
        }
    }

    /**
     * Internal method to load a slice definition into the profile tree
     */
    protected loadSliceFromSnapshot({
        parent,
        accessor,
        element,
        logger,
        anyFieldsDefined,
        anySlicesDefined,
    }: LoaderPayload<ProfilePathSlice>) {
        if (parent instanceof ChoiceDefinition) {
            return this.loadSliceOnChoiceType({
                parent,
                accessor,
                element,
                logger,
                anyFieldsDefined,
                anySlicesDefined,
            });
        }

        if (!(parent instanceof ElementDefinition)) {
            throw logger.scopedException(
                InvalidProfileError,
                `Slice definition '${accessor.slice}' must be defined on element definition, is on ${parent?.constructor.name}`
            );
        }

        if (element.type?.length !== 1) {
            throw logger.scopedException(InvalidProfileError, `Slice must have exactly one type`);
        }

        const type = element.type[0];
        const typeHandle = this.loadTypeDefinitionsFor({
            type,
            logger: logger.child('&' + type.code),
            anyFieldsDefined,
            anySlicesDefined,
        });

        if (!(typeHandle instanceof ElementDefinition)) {
            throw logger.scopedException(
                InvalidProfileError,
                `Slice type must be an element definition`
            );
        }

        // Notice: Since a slice can be based on a regular element definition,
        // we need to convert it to an actual slice, and we need to copy it since
        // we might have gotten a cached variant.
        const slicedDefinition = SlicedElementDefinition.convertFromNonSliced(typeHandle);
        slicedDefinition.sliceName = accessor.slice;
        slicedDefinition.logger = logger;
        slicedDefinition.$profile = this.profile;
        this.extractor.extractAll(slicedDefinition, element, type);

        if (parent.slicingFieldTypes[accessor.slice]) {
            throw logger.scopedException(
                InvalidProfileError,
                `Double definition of slice ${accessor.slice}`
            );
        }

        logger.debug(`ADD slice ':${accessor.slice}' on ${slicedDefinition.typeName}`);
        parent.slicingFieldTypes[accessor.slice] = slicedDefinition;
    }

    /**
     * Loads the type definitions for a given type using only its ID or canonical URL.
     * Utilizes caching to avoid infinite resolving of circular references.
     */
    protected loadTypeDefinitionsFor({
        type,
        logger,
        anySlicesDefined,
        anyFieldsDefined,
    }: {
        type: NonNullable<FHIR.ElementDefinition['type']>[0];
        logger: ContextualLogger;
        anySlicesDefined: boolean;
        anyFieldsDefined: boolean;
    }): BaseDefinition {
        // Sanity check, should never trigger
        if (logger.depth > 200) {
            throw logger.scopedException(
                InvalidProfileError,
                'Internal error: Max recursion reached'
            );
        }

        let canonical = type.code;

        // If the type has a special profile, use that instead. This can e.g. be the
        // case for slicing on extensions, where the slice defines a custom profile
        // referencing an extension
        if (type.profile) {
            if (type.profile.length > 1) {
                throw logger.scopedException(
                    ProfileNotSupportedError,
                    `type.profile SHALL have length = 1, otherwise not yet supported`
                );
            }
            canonical = type.profile[0];
        }

        if (!canonical) {
            throw logger.scopedException(
                InvalidProfileError,
                `Element is missing 'code' or 'profile' field in type`
            );
        }

        // System types, like System.String, System.Integer etc
        if (SystemTypeMappings[canonical]) {
            const type = new SystemTypeMappings[canonical]();
            type.logger = logger;
            type.$profile = this.profile;
            return type;
        }

        // Check if we have a cached variant - however make sure we return a shallow
        // clone so once we modify it we can easily create a modificable instance
        const cachedDefinition = this.profile.definitions[canonical];
        if (cachedDefinition) {
            const result = cachedDefinition.cloneShallow() as ElementDefinition;
            if (anyFieldsDefined) {
                result.fieldTypes = {};
            }
            if (anySlicesDefined) {
                result.slicingFieldTypes = {};
            }
            return result;
        }

        logger.debug(`LOAD ${canonical}`);

        // Fetch the preloaded profile
        const childProfile = this.profile.prefetcher.resolve(canonical);
        if (!childProfile) {
            // Most likely a usage error - the prefetcher does not seem to have loaded the profile yet
            throw logger.scopedException(
                InvalidProfileError,
                `Profile for type ${canonical} was not prefetched - be sure to initialize the prefetcher.`
            );
        }

        // Actually load the child profile into our profile
        this.loadProfile(childProfile, logger.child('[' + canonical + ']'), canonical);

        // Read the actual ElementDefinition we just loaded from our profile
        const resolvedElement = this.profile.definitions[canonical];
        if (!resolvedElement || !(resolvedElement instanceof ElementDefinition)) {
            throw logger.scopedException(
                InvalidProfileError,
                `Resolved profile of ${canonical} did not contain its definition`
            );
        }

        // Make sure we never modify it again
        this.profile.definitions[canonical] = Object.freeze(this.profile.definitions[canonical]);

        // Recursively call ourselfs again, taking a different branch since it's now cached
        return this.loadTypeDefinitionsFor({
            type,
            logger,
            anyFieldsDefined,
            anySlicesDefined,
        });
    }
}
