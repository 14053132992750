import {
    ContextualLogger,
    SlicedElementDefinition,
    traverseSlicePath,
    validateSlicingPattern,
} from '@/lib-on-fhir';
import FHIR from 'fhir/r4';

/**
 * Base for all slicing discriminators
 */
export abstract class SlicingDiscriminatorDefinition {
    path: string[];
    logger = new ContextualLogger('discriminator');

    constructor(params: { path: string }) {
        this.path = params.path.split(/\./g);
    }

    /**
     * Tests the discriminator on a given element with the given definition
     */
    abstract test(wrappedElement: any, definition: SlicedElementDefinition): boolean;
}

/**
 * Value slicing discriminator
 */
class SlicingValueDiscriminatorDefinition extends SlicingDiscriminatorDefinition {
    test(wrappedElement: any, definition: SlicedElementDefinition): boolean {
        const target = traverseSlicePath(this.path, wrappedElement, definition);
        if (!target) {
            this.logger.warn('Slicing: Target is NULL', wrappedElement, definition, this.path);
            return false;
        }
        return validateSlicingPattern(target.definition.fixedValue, target.resource);
    }
}

/**
 * Pattern slicing discriminator
 */
class SlicingPatternDiscriminatorDefinition extends SlicingDiscriminatorDefinition {
    test(wrappedElement: any, definition: SlicedElementDefinition) {
        const target = traverseSlicePath(this.path, wrappedElement, definition);
        if (!target) {
            this.logger.warn('Slicing: Target is NULL', wrappedElement, definition, this.path);
            return false;
        }
        return validateSlicingPattern(target.definition.pattern, target.resource);
    }
}

// Mapping from slicing discriminator types to their implementation
export const SlicingDiscriminators: Partial<Record<
    FHIR.ElementDefinitionSlicingDiscriminator['type'],
    new (args: any) => SlicingDiscriminatorDefinition
>> = {
    value: SlicingValueDiscriminatorDefinition,
    pattern: SlicingPatternDiscriminatorDefinition,
};
