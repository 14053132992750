import { LocalResourceError, ProfilePathParser } from "@/lib-on-fhir";
import { fhirApi } from "@/services/data";
import { notifyError, notifySuccess, notifyWarning } from "@/services/notify";
import FHIR from "fhir/r4";
import { defineStore } from "pinia";

export type GenericResourceError = LocalResourceError & {
  outdated?: boolean;
  fromServer?: boolean;
};

export const useResourceIssueStore = defineStore("resourceIssueStore", {
  state: () => ({
    issues: null as null | GenericResourceError[],

    // A map from path to issues, with the path containing also all child issues
    serverErrorsByPathPropagated: {} as Record<string, GenericResourceError[]>,

    // A map from path to issues
    serverErrorsByPath: {} as Record<string, GenericResourceError[]>,
  }),

  getters: {
    errorIssues(): GenericResourceError[] | undefined {
      return this.issues?.filter((issue) => issue.severity === "error");
    },
    warningIssues(): GenericResourceError[] | undefined {
      return this.issues?.filter((issue) => issue.severity === "warning");
    },
    errorCount(): number {
      return this.errorIssues?.length || 0;
    },
    warningCount(): number {
      return this.warningIssues?.length || 0;
    },
  },
  actions: {
    reset() {
      this.issues = null;
      this.serverErrorsByPathPropagated = {};
      this.serverErrorsByPath = {};
    },

    async validateResource(resource: any, notify = false) {
      const response = (await fhirApi.validateResource(
        resource
      )) as FHIR.OperationOutcome;
      this.issues =
        response?.issue.map((issue) => ({
          path: ProfilePathParser.parse(issue.location?.[0] || ""),
          severity: issue.severity as any,
          text: issue.details?.text || issue.diagnostics || "No message",
          fromServer: true,
          outdated: false,
        })) || null;
      this.serverErrorsByPathPropagated = {};
      this.serverErrorsByPath = {};

      if (this.issues) {
        for (const issue of this.issues) {
          // Absolute path
          const absolutePath = ProfilePathParser.toString(issue.path);
          if (this.serverErrorsByPath[absolutePath]) {
            this.serverErrorsByPath[absolutePath].push(issue);
          } else {
            this.serverErrorsByPath[absolutePath] = [issue];
          }

          // Also add it to all parents
          const subPath = issue.path.slice();
          while (subPath.length > 0) {
            const subPathString = ProfilePathParser.toString(subPath);
            if (this.serverErrorsByPathPropagated[subPathString]) {
              this.serverErrorsByPathPropagated[subPathString].push(issue);
            } else {
              this.serverErrorsByPathPropagated[subPathString] = [issue];
            }
            subPath.pop();
          }
        }
      }
      if (notify) {
        this.notifyValidationResult();
      }
    },

    markOutdated() {
      this.issues?.forEach((issue) => (issue.outdated = true));
    },

    notifyValidationResult() {
      if (this.errorIssues && this.errorIssues.length > 0) {
        notifyError(
          "Validation failed with " + this.errorIssues.length + " errors."
        );
        return;
      }

      if (this.warningIssues && this.warningIssues.length > 0) {
        notifyWarning("Validation completed with warnings.");
        return;
      }

      notifySuccess("Validation successful.");
    },
  },
});
