















import ElementFieldList from "@/components/explore/ElementFieldList.vue";
import { ElementWrapper } from "@/lib-on-fhir";
import Vue from "vue";

export default Vue.extend({
  props: {
    resource: {
      type: ElementWrapper,
    },
  },
  components: {
    ElementFieldList,
  },
});
