export type FilterComparator = {
  label: string;
  keySuffix?: string;
  valuePrefix?: string;
  glueText: string;

  // for fields like :missing
  fixedValue?: string;
};

export type FilterComparatorConfig = {
  comparators: Record<string, FilterComparator>;
};

export const FILTER_COMPARATORS: Record<string, FilterComparatorConfig> = {
  string: {
    comparators: {
      none: {
        label: "Starts with",
        glueText: "startswith",
      },
      contains: {
        label: "Contains",
        keySuffix: ":contains",
        glueText: "contains",
      },
      exact: {
        label: "Exactly",
        keySuffix: ":exact",
        glueText: "=",
      },
      missing: {
        label: "Missing",
        keySuffix: ":missing",
        glueText: "is missing",
        fixedValue: "true",
      },
      notMissing: {
        label: "Not missing",
        keySuffix: ":missing",
        glueText: "is not missing",
        fixedValue: "false",
      },
    },
  },

  number: {
    comparators: {
      none: {
        label: "Equal",
        valuePrefix: "eq",
        glueText: "=",
      },
      ne: {
        label: "Not equal",
        valuePrefix: "ne",
        glueText: "not",
      },
      gt: {
        label: "Greater",
        valuePrefix: "gt",
        glueText: ">",
      },
      lt: {
        label: "Less",
        valuePrefix: "lt",
        glueText: "<",
      },
      ge: {
        label: "Greater/equal",
        valuePrefix: "ge",
        glueText: ">=",
      },
      le: {
        label: "Less or equal",
        valuePrefix: "le",
        glueText: "<=",
      },
      //   sa: {
      //     label: "Starts after",
      //   },
      //   eb: {
      //     label: "Ends before",
      //   },
      ap: {
        label: "Approximately",
        valuePrefix: "ap",
        glueText: "approximately",
      },
      missing: {
        label: "Missing",
        keySuffix: ":missing",
        glueText: "is missing",
        fixedValue: "true",
      },
      notMissing: {
        label: "Not missing",
        keySuffix: ":missing",
        glueText: "is not missing",
        fixedValue: "false",
      },
    },
  },

  date: {
    comparators: {
      none: {
        label: "Equal",
        valuePrefix: "eq",
        glueText: "=",
      },
      ne: {
        label: "Not equal",
        valuePrefix: "ne",
        glueText: "not",
      },
      gt: {
        label: "Greater",
        valuePrefix: "gt",
        glueText: ">",
      },
      lt: {
        label: "Less",
        valuePrefix: "lt",
        glueText: "<",
      },
      ge: {
        label: "Greater/equal",
        valuePrefix: "ge",
        glueText: ">=",
      },
      le: {
        label: "Less or equal",
        valuePrefix: "le",
        glueText: "<=",
      },
      sa: {
        label: "Starts after",
        valuePrefix: "sa",
        glueText: "starts after",
      },
      eb: {
        label: "Ends before",
        valuePrefix: "eb",
        glueText: "ends before",
      },
      ap: {
        label: "Approximately",
        valuePrefix: "ap",
        glueText: "approximately",
      },
      missing: {
        label: "Missing",
        keySuffix: ":missing",
        glueText: "is missing",
        fixedValue: "true",
      },
      notMissing: {
        label: "Not missing",
        keySuffix: ":missing",
        glueText: "is not missing",
        fixedValue: "false",
      },
    },
  },

  uri: {
    comparators: {
      none: {
        label: "Exactly",
        glueText: "exactly",
      },
      above: {
        label: "Above",
        keySuffix: ":above",
        glueText: "above",
      },
      below: {
        label: "Below",
        keySuffix: ":beloww",
        glueText: "below",
      },
      missing: {
        label: "Missing",
        keySuffix: ":missing",
        glueText: "is missing",
        fixedValue: "true",
      },
      notMissing: {
        label: "Not missing",
        keySuffix: ":missing",
        glueText: "is not missing",
        fixedValue: "false",
      },
    },
  },

  token: {
    comparators: {
      none: {
        label: "Equals",
        glueText: "equals",
      },
      text: {
        label: "Text search",
        keySuffix: ":text",
        glueText: "contains text",
      },
      not: {
        label: "Not",
        keySuffix: ":not",
        glueText: "not",
      },
      above: {
        label: "Above",
        keySuffix: ":above",
        glueText: "above",
      },
      below: {
        label: "Below",
        keySuffix: ":below",
        glueText: "below",
      },
      in: {
        label: "In",
        keySuffix: ":in",
        glueText: "in",
      },
      notIn: {
        label: "Not In",
        keySuffix: ":not-in",
        glueText: "not in",
      },
      ofType: {
        label: "Of type",
        keySuffix: ":of-type",
        glueText: "of type",
      },
      missing: {
        label: "Missing",
        keySuffix: ":missing",
        glueText: "is missing",
        fixedValue: "true",
      },
      notMissing: {
        label: "Not missing",
        keySuffix: ":missing",
        glueText: "is not missing",
        fixedValue: "false",
      },
    },
  },

  reference: {
    comparators: {
      none: {
        label: "Equals",
        glueText: "=",
      },
      identifier: {
        label: "By Identifier",
        keySuffix: ":identifier",
        glueText: "equals identifier",
      },
      missing: {
        label: "Missing",
        keySuffix: ":missing",
        glueText: "is missing",
        fixedValue: "true",
      },
      notMissing: {
        label: "Not missing",
        keySuffix: ":missing",
        glueText: "is not missing",
        fixedValue: "false",
      },
    },
  },
};
