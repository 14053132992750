import { ElementWrapper, ProfilePathParser } from "@/lib-on-fhir";
import { usePathHighlightStore } from "@/state/pathHighlightStore";
import { computed, ref, Ref } from "@vue/composition-api";
import Vue from "vue";

export function useErrorHighlighting(
  props: { element: ElementWrapper },
  payload: {
    elementReference: Ref<any>;
  }
) {
  const pathHighlightStore = usePathHighlightStore();

  // Compute how we interact with the error
  const errorHighlights = computed(() => {
    const focusedPath = pathHighlightStore.highlightedPath;

    if (!focusedPath) {
      return {
        containsError: false,
        isError: false,
      };
    }

    const path = props.element.absolutePath;
    return {
      containsError: ProfilePathParser.isChildOf(path, focusedPath),
      isError: ProfilePathParser.isEqual(path, focusedPath),
    };
  });

  const isHighlighted = ref(false);

  // Allow to highlight the node and consume the highlight focus request
  const highlight = () => {
    isHighlighted.value = false;

    Vue.nextTick(() => {
      isHighlighted.value = true;
      if (pathHighlightStore.scrollToHighlightedPath) {
        (payload.elementReference.value as HTMLElement).scrollIntoView();
      }

      pathHighlightStore.consumePathHighlight();
    });
  };

  return {
    highlight,
    errorHighlights,
    isHighlighted,
  };
}
