import Vue from "vue";
import TextRenderer from "./text.vue";
import RelativeDateRenderer from "./relative-date.vue";
import ValidationRenderer from "./validation.vue";
import ReferenceRenderer from "./reference.vue";

export const COLUMN_RENDERERS: Record<
  string,
  { name: string; component: new () => Vue }
> = {
  text: {
    name: "Text (default)",
    component: TextRenderer,
  },
  "relative-date": {
    name: "Relative Date",
    component: RelativeDateRenderer,
  },
  validation: {
    name: "Validation result",
    component: ValidationRenderer,
  },
  reference: {
    name: "Reference",
    component: ReferenceRenderer,
  },
};
