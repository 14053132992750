import { BaseWrapper, ElementDefinition, ElementWrapper } from "@/lib-on-fhir";

type ExistingField = {
  exists: true;
  fieldId: string;
  field: BaseWrapper;
};

type MissingField = {
  missing: true;
  fieldId: string;
};

type AnyField = ExistingField | MissingField;

export type SortedFieldList = AnyField[];

/**
 * Computes a sorted field list, inserting missing fields inbetween to
 * make sure they appear at the right spot
 */
export function computeSortedFieldList(
  element: ElementWrapper
): SortedFieldList {
  const result: SortedFieldList = [];

  const addable = element.getAvailableFieldsToAdd();

  // First, go over all types we have
  for (const fieldId in element.$type.fieldTypes) {
    // Check if there is a value present - if so, that always goes first
    const field = element.fields[fieldId];
    if (field) {
      result.push({
        exists: true,
        fieldId,
        field,
      });
    }

    // Check if the type itself is missing
    if (addable[fieldId]?.missing) {
      result.push({
        missing: true,
        fieldId,
      });
    }

    // Check if any slice of the type is missing - this is seperate from the type,
    // e.g. a slice can have a min=1 while the base type has min=0
    const fieldType = element.$type.fieldTypes[fieldId];
    if (fieldType instanceof ElementDefinition) {
      for (const sliceName in fieldType.slicingFieldTypes) {
        const fullFieldId = fieldId + ":" + sliceName;
        if (addable[fullFieldId]?.missing) {
          result.push({
            missing: true,
            fieldId: fullFieldId,
          });
        }
      }
    }
  }
  return result;
}
