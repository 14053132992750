import {
    BaseWrapper,
    isNullOrUndefined,
    SystemTypeDefinition,
    collectValueSetExpansionEntries,
} from '@/lib-on-fhir';

export class SystemTypeWrapper extends BaseWrapper<SystemTypeDefinition> {
    get value() {
        const { resource, key } = this._resolveRef();
        return resource[key];
    }

    set value(v: any) {
        const { resource, key } = this._resolveRef();
        resource[key] = v;
        this._changeHandler();
    }

    deleteValue() {
        const { resource, key } = this._resolveRef();
        resource[key] = undefined;
        this._changeHandler();
    }

    _computeLocalErrors() {
        // Fixed Value
        if (this.fixedValue && this.value !== this.fixedValue) {
            this._emitError({
                id: 'fieldFixedMismatch',
                payload: {
                    fixedValue: this.fixedValue,
                },
                fix: () => (this.value = this.fixedValue),
            });
        }

        if (isNullOrUndefined(this.value)) {
            this._emitError({
                id: 'systemTypeEmpty',
                payload: {},
            });
        }

        // Patern Value
        if (this.pattern && this.value !== this.pattern) {
            this._emitError({
                id: 'fieldPatternMismatch',
                payload: {
                    pattern: this.pattern,
                },
                fix: () => (this.value = this.pattern),
            });
        }

        // Binding
        if (this.binding) {
            // Fetch resolved values
            const resolved = this.$type.$profile.prefetcher.expandValueSetSync(
                this.binding.valueSet
            );

            const values = collectValueSetExpansionEntries(resolved?.expansion?.contains);
            const valueCodes = values.filter(c => c.code).map(c => c.code!);

            if (valueCodes.length > 0) {
                // Check for 'required' bindings
                if (!valueCodes.includes(this.value) && this.binding.strength === 'required') {
                    this._emitError({
                        id: 'bindingMismatchingValue',
                        payload: {
                            valueSetUrl: this.binding.valueSet,
                            value: this.value,
                        },
                    });
                }
            } else {
                // Handle unresolved value sets
                this._emitError({
                    id: 'bindingNotResolvable',
                    payload: {
                        valueSetUrl: this.binding.valueSet,
                    },
                    severity: 'warning',
                });
            }
        }

        this.combinedErrors = this.localErrors.slice();
    }
}
