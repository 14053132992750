


















import Vue from "vue";
export default Vue.extend({
  props: {
    promise: Promise,
  },

  data: () => ({
    result: undefined as any,
    resolved: false,
    rejected: false,
  }),

  watch: {
    promise: {
      immediate: true,
      handler(promise: Promise<any>) {
        this.resolved = false;
        this.rejected = false;
        this.result = undefined;
        promise.then(
          (result) => {
            this.result = result;
            this.resolved = true;
          },
          (err) => {
            this.rejected = true;
            this.result = undefined;
            throw err;
          }
        );
      },
    },
  },
});
