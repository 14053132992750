










































































































































import {
  config,
  env,
  resetLocalstorage,
  saveLocalstorage,
} from "@/services/config";
import { profileResolver } from "@/services/resolvers/ProfileResolver";
import { valueSetResolver } from "@/services/resolvers/ValueSetResolver";
import { validationCache } from "@/state/resourceValidationStore";
import Vue from "vue";

const availableServers = [
  "https://server.fire.ly",
  "http://hapi.fhir.org/baseR4",
  "http://hapi.fhir.org/baseR5",
  "https://r4.ontoserver.csiro.au/fhir",
  "https://r5.ontoserver.csiro.au/fhir",
];
const availableTerminologyServer = [
  "https://r5.ontoserver.csiro.au/fhir"
];
const fhirVersions = [
  { text: "Server default", value: null },
  { text: "R1 DSTU 1", value: "0.0" },
  { text: "R2 DSTU 2", value: "1.0" },
  { text: "R3 STU3", value: "3.0" },
  { text: "R4", value: "4.0" },
  { text: "R5", value: "5.0" },
];

export default Vue.extend({
  components: {},

  data: () => ({
    appConfig: config.appConfig,
    availableServers,
    fhirVersions,
    availableTerminologyServer,
    currentTab: 'form',
    showSuccessSnackbar: false,
    initialConfig: JSON.stringify(config.appConfig), // storing the initial configuration
    env: env,
    isJsonEditorChanged: false,
  }),

  computed: {
    debug() {
      return config.showDebug;
    },
    isConfigChanged() { // check if the configuration has changed
      return JSON.stringify(this.appConfig) !== this.initialConfig;
    },
  },

  async mounted() {},

  methods: {
    resetLocalstorage() {
      resetLocalstorage();
      location.reload();
    },
    saveLocalstorage() {
      saveLocalstorage();
      location.reload();
    },

    overrideResourceFromJSON(json: any) {
      config.appConfig = json;
      this.isJsonEditorChanged = true;
    },

    async clearCaches() {
      await validationCache.resetCache();
      await profileResolver.resetCache();
      await valueSetResolver.resetCache();
      this.showSuccessSnackbar = true;
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
  },
});
