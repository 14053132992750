import {
    BaseDefinition,
    capitalizeString,
    Cardinality,
    ElementDefinition,
    FieldModifiers,
    InvalidProfileError,
    isNullOrUndefined,
    ProfileNotSupportedError,
    SlicingDiscriminators,
} from '@/lib-on-fhir';
import FHIR from 'fhir/r4';

// Map the FHIR flags to our flag definition
const FLAG_CONDITIONS = Object.entries({
    mustSupport: FieldModifiers.MustSupport,
    isSummary: FieldModifiers.Summary,
    isModifier: FieldModifiers.ModifyingElement,
    constraint: FieldModifiers.Constraints,
}) as [keyof FHIR.ElementDefinition, FieldModifiers][];

/**
 * Allows to extract values from a FHIR JSON Element Definition into our
 * own element definition. This is basically the compatibility layer.
 */
export class JSONLoaderExtractor {
    extractAll(
        target: BaseDefinition,
        element: FHIR.ElementDefinition,
        type: FHIR.ElementDefinitionType
    ) {
        this.extractAllNonTypeAware(target, element);
        this.extractPatternAndFixed(target, element, type);
        this.extractTargetProfile(target, element, type);
    }

    extractAllNonTypeAware(target: BaseDefinition, element: FHIR.ElementDefinition) {
        this.extractSlicingDiscriminators(target, element);
        this.extractCardinality(target, element);
        this.extractFlags(target, element);
        this.extractOriginal(target, element);
        this.extractBinding(target, element);
    }

    extractContentReference(target: BaseDefinition, element: FHIR.ElementDefinition) {
        this.extractCardinality(target, element);
    }

    // min, max
    extractCardinality(target: BaseDefinition, element: FHIR.ElementDefinition) {
        target.cardinality = new Cardinality({
            min: element.min || 0,
            max: element.max === null || element.max === '*' ? Infinity : +(element.max || 0),
        });
    }

    // flags: e.g. must support, summary, ...
    extractFlags(target: BaseDefinition, element: FHIR.ElementDefinition) {
        const flags = new Set<FieldModifiers>();
        for (const [key, flag] of FLAG_CONDITIONS) {
            if (element[key]) {
                flags.add(flag);
            }
        }
        target.flags = flags;
    }

    // reference to the original FHIR.ElementDefinition
    extractOriginal(target: BaseDefinition, element: FHIR.ElementDefinition) {
        target.$original = element;
    }

    // value set binding
    extractBinding(target: BaseDefinition, element: FHIR.ElementDefinition) {
        if (element.binding) {
            target.binding = Object.freeze({
                strength: element.binding.strength,
                valueSet: element.binding.valueSet!,
            });
        }
    }

    // targetProfile for references
    extractTargetProfile(
        target: BaseDefinition,
        element: FHIR.ElementDefinition,
        type: FHIR.ElementDefinitionType
    ) {
        if (type.targetProfile) {
            target.targetProfile = Object.freeze(type.targetProfile) as string[];
        } else if (type.code === 'Reference') {
            // Special case
            target.targetProfile = [];
        }
    }

    // e.g. patternBoolean, fixedCodeableConcept, ...
    extractPatternAndFixed(
        target: BaseDefinition,
        element: FHIR.ElementDefinition,
        type: FHIR.ElementDefinitionType
    ) {
        let typeName = type.code;

        for (const extension of type.extension || []) {
            // @NOTE: This is FHIR compiler magic
            if (
                extension.url ===
                'http://hl7.org/fhir/StructureDefinition/structuredefinition-fhir-type'
            ) {
                typeName = extension.valueUrl!;
            }
        }

        if (!typeName) {
            throw new InvalidProfileError(`Failed to reasonably detect type name`);
        }

        const nameCamelCase = capitalizeString(typeName);

        const pattern = (element as any)['pattern' + nameCamelCase];
        if (pattern) {
            target.pattern = Object.freeze(pattern);
        }

        const fixedValue = (element as any)['fixed' + nameCamelCase];
        if (fixedValue) {
            target.fixedValue = Object.freeze(fixedValue);
        }

        const defaultValue = (element as any)['defaultValue' + nameCamelCase];
        if (defaultValue) {
            target.defaultValue = Object.freeze(defaultValue);
        }

        this.sanityCheckFixedValues(target, element, typeName);
    }

    // Sanity check to make sure we pick up all "fixed", "pattern" and "defaultValues"
    // Could be disabled in prod but doesn't hurt either
    private sanityCheckFixedValues(
        target: BaseDefinition,
        element: FHIR.ElementDefinition,
        typeName: string
    ) {
        for (const key in element) {
            if (key.startsWith('fixed') && isNullOrUndefined(target.fixedValue)) {
                throw new InvalidProfileError(
                    `fixed value not detected: ${key} of type ${typeName}`
                );
            }
            if (key.startsWith('pattern') && isNullOrUndefined(target.pattern)) {
                throw new InvalidProfileError(
                    `pattern value not detected: ${key} of type ${typeName}`
                );
            }
            if (key.startsWith('defaultValue') && isNullOrUndefined(target.defaultValue)) {
                throw new InvalidProfileError(
                    `defaultValue value not detected: ${key} of type ${typeName}`
                );
            }
        }
    }

    // slicing
    extractSlicingDiscriminators(target: BaseDefinition, element: FHIR.ElementDefinition) {
        if (!element.slicing) {
            return;
        }

        if (!(target instanceof ElementDefinition)) {
            throw new ProfileNotSupportedError(
                `slicingDiscriminators can only be specified on ElementDefinitions (${
                    target.logger?.scope
                }) but got ${target.toString()}`
            );
        }

        element.slicing.discriminator!.forEach(discriminator => {
            const discriminatorType = SlicingDiscriminators[discriminator.type];
            if (!discriminatorType) {
                throw new ProfileNotSupportedError(
                    `Slicing type '${discriminator.type}' not yet supported (${target.logger?.scope})`
                );
            }
            target.slicingDiscriminators.push(new discriminatorType(discriminator as any));
        });
    }
}
