import { PolyfilledIndexedDBCache } from "@/lib-on-fhir";
import { profileResolverLocalServer } from "@/services/resolvers/ProfileResolverServer";
import { profileResolverSimplifier } from "@/services/resolvers/ProfileResolverSimplifier";
import FHIR from "fhir/r4";
import Vue from "vue";

export const profileResolver = new PolyfilledIndexedDBCache({
  id: "profile-v2",
  maxConcurrency: 10,
  resolver: async (canonical: string): Promise<FHIR.StructureDefinition> => {
    // Local fhir server
    if (!canonical.startsWith("http")) {
      canonical = "http://hl7.org/fhir/StructureDefinition/" + canonical;
    }

    try {
      console.log("Profile:: Fetch local profile:", canonical);
      const locallyResolved = await profileResolverLocalServer.resolveProfileLocalServer(
        canonical
      );

      if (!locallyResolved.snapshot) {
        console.warn(
          "Profile:: Got local profile without snapshot:",
          locallyResolved
        );
        throw new Error(
          "Locally fetched profil '" + canonical + "' has no snapshot!"
        );
      }

      return locallyResolved;
    } catch (ex) {
      console.warn("Could not resolve profile", canonical, "locally:", ex);
    }

    // Simplifier
    const simplifierResolved = (await profileResolverSimplifier.resolveProfileSimplifier(
      canonical
    )) as FHIR.StructureDefinition;

    if (!simplifierResolved.snapshot) {
      console.warn(
        "Profile:: Got simplifier profile without snapshot:",
        simplifierResolved
      );
      throw new Error(
        "Simplifier fetched profil '" + canonical + "' has no snapshot!"
      );
    }

    return simplifierResolved;
  },
});

// Make it reactive
new Vue({
  data: {
    profileResolver,
  },
});
