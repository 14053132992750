import { ElementWrapper } from "@/lib-on-fhir";
import { usePathHighlightStore } from "@/state/pathHighlightStore";
import { useResourceStore } from "@/state/resourceStore";
import { computed } from "@vue/composition-api";
import Vue from "vue";

// Addable fields
export const useAddableFields = () => {
  const currentResource = computed(
    () => useResourceStore().wrappedResource as ElementWrapper | undefined
  );
  const availableFieldsToAdd = computed(() =>
    currentResource.value?.getAvailableFieldsToAdd()
  );

  const addNewField = (key: string) => {
    const path = currentResource.value?.addNewField(key);
    if (path) {
      Vue.nextTick(() => {
        usePathHighlightStore().requestPathHighlight(path, true);
      });
    }
  };

  return {
    availableFieldsToAdd,
    addNewField,
  };
};
