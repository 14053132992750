export const resourceCategories = {
  Foundation: {
    displayName: "Foundation",
    level: 1,
    resources: [
      { name: "Resource", icon: "mdi-help-circle-outline" },
      { name: "DomainResource", icon: "mdi-help-box" },
      { name: "Basic", icon: "mdi-dots-circle" },
      { name: "Binary", icon: "mdi-square-rounded-outline" },
      { name: "Bundle", icon: "mdi-gift-outline" },
      { name: "Questionnaire", icon: "mdi-file-question-outline" },
      { name: "QuestionnaireResponse", icon: "mdi-message-reply-text-outline" },
      { name: "List", icon: "mdi-format-list-bulleted" },
      { name: "Composition", icon: "mdi-pencil-outline" },
      { name: "DocumentReference", icon: "mdi-file-link-outline" },
      { name: "OperationOutcome", icon: "mdi-rhombus-outline" },
      { name: "Parameters", icon: "mdi-tune-vertical" },
      { name: "Subscription", icon: "mdi-bell-outline" },
      { name: "SubscriptionStatus", icon: "mdi-bell-outline" },
      { name: "SubscriptionTopic", icon: "mdi-bell-outline" },
      { name: "MessageHeader", icon: "mdi-message-star-outline" },
    ],
  },
  ImplementerSupport: {
    displayName: "Implementer Support",
    level: 2,
    resources: [
      { name: "TestPlan", icon: "mdi-clipboard-list-outline" },
      { name: "TestScript", icon: "mdi-clipboard-outline" },
      { name: "TestReport", icon: "mdi-clipboard-text-outline" },
      { name: "ExampleScenario", icon: "mdi-movie-open-outline" },
      { name: "StructureMap", icon: "mdi-sitemap-outline" },
    ],
  },
  SecurityPrivacy: {
    displayName: "Security and Privacy",
    level: 2,
    resources: [
      { name: "AuditEvent", icon: "mdi-clipboard-text-clock-outline" },
      { name: "Consent", icon: "mdi-check-circle-outline" },
      { name: "Provenance", icon: "mdi-shoe-print" },
      { name: "Permission", icon: "mdi-lock-outline" },
      { name: "Signature", icon: "mdi-pencil-lock" },
    ],
  },
  Conformance: {
    displayName: "Conformance",
    level: 2,
    resources: [
      { name: "CapabilityStatement", icon: "mdi-desktop-classic" },
      { name: "StructureDefinition", icon: "mdi-file-tree" },
      { name: "ImplementationGuide", icon: "mdi-file-cog-outline" },
      { name: "OperationDefinition", icon: "mdi-clipboard-list-outline" },
      { name: "SearchParameter", icon: "mdi-toy-brick-search-outline" },
      { name: "CompartmentDefinition", icon: "mdi-folder-outline" },
      { name: "ElementDefinition", icon: "mdi-format-list-checks" },
    ],
  },
  Terminology: {
    displayName: "Terminology",
    level: 2,
    resources: [
      { name: "CodeSystem", icon: "mdi-code-tags" },
      { name: "ValueSet", icon: "mdi-format-list-bulleted-square" },
      { name: "ConceptMap", icon: "mdi-map-outline" },
      { name: "NamingSystem", icon: "mdi-tournament" },
      { name: "TerminologyCapabilities", icon: "mdi-transit-connection-horizontal" },
    ],
  },
  Exchange: {
    displayName: "Exchange",
    level: 2,
    resources: [
      { name: "REST API + Search", icon: "mdi-cloud-search-outline" },
      { name: "Documents", icon: "mdi-file-document-outline" },
      { name: "Messaging", icon: "mdi-message-outline" },
      { name: "Services", icon: "mdi-cog-outline" },
      { name: "Databases", icon: "mdi-database-outline" },
      { name: "Subscriptions", icon: "mdi-bell-outline" },
    ],
  },
  Administration: {
    displayName: "Administration",
    level: 3,
    resources: [
      { name: "Patient", icon: "mdi-account" },
      { name: "Practitioner", icon: "mdi-doctor" },
      { name: "PractitionerRole", icon: "mdi-account-cog-outline" },
      { name: "Organization", icon: "mdi-domain" },
      { name: "OrganizationAffiliation", icon: "mdi-domain-plus" },
      { name: "RelatedPerson", icon: "mdi-account-supervisor" },
      { name: "Person", icon: "mdi-account-box" },
      { name: "Group", icon: "mdi-google-circles-communities" },
      { name: "Encounter", icon: "mdi-hospital-box-outline" },
      { name: "EpisodeOfCare", icon: "mdi-calendar-week" },
      { name: "Location", icon: "mdi-map-marker-outline" },
      { name: "HealthcareService", icon: "mdi-heart-box-outline" },
      { name: "Endpoint", icon: "mdi-ray-end" },
    ],
  },
  Clinical: {
    displayName: "Clinical",
    level: 4,
    resources: [
      { name: "AllergyIntolerance", icon: "mdi-allergy" },
      { name: "Condition", icon: "mdi-radiology-box-outline" },
      { name: "Procedure", icon: "mdi-chart-gantt" },
      { name: "FamilyMemberHistory", icon: "mdi-history" },
      { name: "AdverseEvent", icon: "mdi-calendar-alert" },
      { name: "CarePlan", icon: "mdi-chart-timeline" },
      { name: "Goal", icon: "mdi-bullseye" },
      { name: "CareTeam", icon: "mdi-account-group-outline" },
      { name: "ClinicalImpression", icon: "mdi-stethoscope" },
      { name: "DetectedIssue", icon: "mdi-alert-circle-outline" },
      { name: "ServiceRequest", icon: "mdi-message-cog-outline" },
      { name: "VisionPrescription", icon: "mdi-glasses" },
      { name: "RiskAssessment", icon: "mdi-scale-balance" },
      { name: "NutritionIntake", icon: "mdi-food-apple-outline" },
      { name: "NutritionOrder", icon: "mdi-food-apple-outline" },
    ],
  },
  Diagnostics: {
    displayName: "Diagnostics",
    level: 4,
    resources: [
      { name: "Observation", icon: "mdi-eye-outline" },
      { name: "DiagnosticReport", icon: "mdi-file-chart-outline" },
      { name: "ServiceRequest", icon: "mdi-message-cog-outline" },
      { name: "DocumentReference", icon: "mdi-file-link-outline" },
      { name: "ImagingSelection", icon: "mdi-image-album" },
      { name: "ImagingStudy", icon: "mdi-image-album" },
      { name: "MolecularSequence", icon: "mdi-dna" },
      { name: "GenomicStudy", icon: "mdi-magnify" },
      { name: "Specimen", icon: "mdi-package-variant-closed" },
      { name: "BodyStructure", icon: "mdi-human" },
    ],
  },
  Medications: {
    displayName: "Medications",
    level: 4,
    resources: [
      { name: "Medication", icon: "mdi-medication-outline" },
      { name: "MedicationRequest", icon: "mdi-message-plus-outline" },
      { name: "MedicationDispense", icon: "mdi-hand-extended-outline" },
      { name: "MedicationAdministration", icon: "mdi-timeline-outline" },
      { name: "MedicationStatement", icon: "mdi-clipboard-plus-outline" },
      { name: "MedicationKnowledge", icon: "mdi-book-open-page-variant-outline" },
      { name: "FormularyItem", icon: "mdi-format-list-bulleted" },
      { name: "Immunization", icon: "mdi-needle" },
      { name: "ImmunizationEvaluation", icon: "mdi-shield-sync-outline" },
      { name: "ImmunizationRecommendation", icon: "mdi-shield-star-outline" },
    ],
  },
  Workflow: {
    displayName: "Workflow",
    level: 4,
    resources: [
      { name: "Task", icon: "mdi-checkbox-marked-circle-outline" },
      { name: "Appointment", icon: "mdi-calendar-outline" },
      { name: "AppointmentResponse", icon: "mdi-calendar-badge-outline" },
      { name: "Schedule", icon: "mdi-calendar-clock" },
      { name: "Slot", icon: "mdi-rectangle-outline" },
      { name: "Referral", icon: "mdi-arrow-right-bold-circle" },
      { name: "PlanDefinition", icon: "mdi-calendar-multiselect-outline" },
      { name: "DeviceRequest", icon: "mdi-wheelchair" },
      { name: "DeviceUsage", icon: "mdi-human-wheelchair" },
      { name: "DeviceDispense", icon: "mdi-basket" },
      { name: "DeviceAssociation", icon: "mdi-link" },
      { name: "BiologicallyDerivedProductDispense", icon: "mdi-leaf" },
      { name: "SupplyRequest", icon: "mdi-basket-outline" },
      { name: "SupplyDelivery", icon: "mdi-package-variant-closed-check" },
      { name: "InventoryItem", icon: "mdi-archive" },
      { name: "InventoryReport", icon: "mdi-file-document-box-multiple" },
      { name: "Transport", icon: "mdi-truck" },
    ],
  },
  Financial: {
    displayName: "Financial",
    level: 4,
    resources: [
      { name: "Account", icon: "mdi-account-circle-outline" },
      { name: "ChargeItem", icon: "mdi-circle-box-outline" },
      { name: "ChargeItemDefinition", icon: "mdi-cash" },
      { name: "Claim", icon: "mdi-check-underline-circle-outline" },
      { name: "ClaimResponse", icon: "mdi-checkbox-marked-circle-auto-outline" },
      { name: "Coverage", icon: "mdi-shield-outline" },
      { name: "CoverageEligibilityRequest", icon: "mdi-shield-half-full" },
      { name: "CoverageEligibilityResponse", icon: "mdi-shield-check-outline" },
      { name: "EnrollmentRequest", icon: "mdi-hand-extended-outline" },
      { name: "EnrollmentResponse", icon: "mdi-handshake-outline" },
      { name: "ExplanationOfBenefit", icon: "mdi-plus-box-outline" },
      { name: "Invoice", icon: "mdi-receipt-text-outline" },
      { name: "PaymentNotice", icon: "mdi-cash-check" },
      { name: "PaymentReconciliation", icon: "mdi-cash-sync" },
      { name: "Contract", icon: "mdi-file-sign" },
    ],
  },
  ClinicalReasoning: {
    displayName: "Clinical Reasoning",
    level: 5,
    resources: [
      { name: "Library", icon: "mdi-library-outline" },
      { name: "PlanDefinition", icon: "mdi-calendar-multiselect-outline" },
      { name: "GuidanceResponse", icon: "mdi-sign-direction" },
      { name: "Measure", icon: "mdi-ruler-square-compass" },
      { name: "MeasureReport", icon: "mdi-chart-bar" },
      { name: "ActivityDefinition", icon: "mdi-playlist-check" },
      { name: "ArtifactAssessment", icon: "mdi-format-list-checks" },
      { name: "Citation", icon: "mdi-format-quote-open" },
      { name: "DataRequirement", icon: "mdi-database-search" },
      { name: "Evidence", icon: "mdi-magnify" },
      { name: "EvidenceVariable", icon: "mdi-selection-search" },
      { name: "RequestOrchestration", icon: "mdi-checkbox-multiple-marked" },
    ],
  },
  MedicationDefinition: {
    displayName: "Medication Definition",
    level: 5,
    resources: [
      { name: "MedicinalProductDefinition", icon: "mdi-medical-bag" },
      { name: "Ingredient", icon: "mdi-mortar-pestle-plus" },
      { name: "ClinicalUseDefinition", icon: "mdi-clipboard-text-outline" },
      { name: "PackagedProductDefinition", icon: "mdi-package" },
      { name: "AdministrableProductDefinition", icon: "mdi-medication" },
      { name: "SubstanceDefinition", icon: "mdi-molecule" },
      { name: "ManufacturedItemDefinition", icon: "mdi-factory" },
      { name: "RegulatedAuthorization", icon: "mdi-file-document-outline" },
    ],
  },
};
