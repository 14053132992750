import {
  fhirBackend,
  ontoserverBackend,
  simplifierBackend,
} from "@/services/http";

export const fhirApi = {
  getFhirResource: fhirBackend.get,
  postFhirResource: fhirBackend.post,
  putFhirResource: fhirBackend.put,
  deleteFhirResource: fhirBackend.delete,
  getExamplePatient: () => fhirBackend.get("Patient/ExamplePatientPatient"),
  getCapabilityStatement: () => fhirBackend.get("metadata"),
  loadBaseResourceList: (baseResource: string, count = 10, skip = 0) =>
    fhirBackend.get(baseResource + `?_count=${count}&_skip=${skip}`),

  loadResourceCount: (baseResource: string) =>
    fhirBackend.get(baseResource + `?_summary=count`),

  validateResource: (resource: any) => fhirBackend.post(`/$validate`, resource),
  expandValueSet: (resourceUri: any) =>
    // TODO: fhir spec url is `/ValueSet/$expand`, currently causing issues therefore using administration endpoint
    fhirBackend.post(`administration/ValueSet/$expand`, {
      resourceType: "Parameters",
      parameter: [{ name: "url", valueUri: resourceUri }],
    }),
  getIndirectSubjectReferences: (
    subject: string,
    type: string,
    property: string
  ) => fhirBackend.get(`/${type}?${property}=${subject}&_count=30`),
};

export const simplifierApi = {
  backend: simplifierBackend.backend,
  getSimplifierResource: simplifierBackend.get,
  resolveCanonicalDownloadLink,
  loadCoreProfile: (baseResource: string) =>
    simplifierBackend.get(
      `coreprofilesstu3/${baseResource}/$download?format=json`
    ),
};

export const ontoserverApi = {
  expandValueSet: (resourceUri: any) =>
    ontoserverBackend.post(`/ValueSet/$expand`, {
      resourceType: "Parameters",
      parameter: [{ name: "url", valueUri: resourceUri }],
    }),
};

// TODO: paginate using the links
// TODO: resource counts by summary=count

// resolve profiles from fhir server
// /administration/StructureDefinition?url=<canonical>
// /StructureDefinition/$snapshot

async function resolveCanonicalDownloadLink(canonical: string, scope = "") {
  if (canonical.startsWith("http://hl7.org/fhirpath/System.")) {
    canonical = canonical.substring("http://hl7.org/fhirpath/System.".length);
  }

  if (!canonical.startsWith("http")) {
    canonical = "http://hl7.org/fhir/StructureDefinition/" + canonical;
  }

  let resolveResponse = await simplifierApi.backend.get(
    `resolve?fhirVersion=R4&scope=${scope}&canonical=${canonical}`
  );
  let simplifierUrl = resolveResponse.request.responseURL;
  let resolvePage = resolveResponse.data;

  // search json download link
  // let jsonDownloadRegex = /href="(\S+\/\$download\?format=json)"/;
  const parser = new DOMParser();
  const resolveDocument = parser.parseFromString(resolvePage, "text/html");

  let linkElements = Array.from(resolveDocument.querySelectorAll("a"));
  let downloadLinkElm:
    | HTMLAnchorElement
    | undefined = linkElements.find((elm) =>
    elm.text.includes("Download snapshot as JSON")
  );

  if (!downloadLinkElm) {
    downloadLinkElm = linkElements.find((elm) =>
      elm.text.includes("Download as JSON")
    );
  }

  let href = downloadLinkElm?.getAttribute("href");
  if (href) {
    return {
      simplifierUrl,
      downloadLink: href,
    };
  }

  throw new Error(`download link not found for ${canonical}, linkElements`);
}

// https://simplifier.net/resolve
// http://hl7.org/fhir/ValueSet/administrative-gender|4.0.0
// https://simplifier.net/vrdr/administrativegendervs/$download?format=json

// https://fire.ly/products/firely-terminal/

// POST https://server.fire.ly/$validate

// alle resourcen mit profil suchen
// https://server.fire.ly/Patient?_profile=<url>

// https://simplifier.net/resolve?fhirVersion=R4&scope=hl7.fhir.r4.core@4.0.1&canonical=http://hl7.org/fhir/StructureDefinition/Patient
// downlaod snapshot json link
