import ConfirmButton from "@/components/ConfirmButton.vue";
import Debug from "@/components/Debug.vue";
import Loader from "@/components/Loader.vue";
import ReferenceLink from "@/components/ReferenceLink.vue";
import Tooltip from "@/components/Tooltip.vue";
import LazyLoaded from "@/components/LazyLoaded.vue";
import RecordsInput from "@/components/base/input.vue";
import RecordsCombobox from "@/components/base/combobox.vue";
import RecordsSwitch from "@/components/base/switch.vue";
import RecordsSelect from "@/components/base/select.vue";
import RecordsIndicatorChip from "@/components/base/indicator-chip.vue";
import RecordsSliceChip from "@/components/base/slice-chip.vue";
import VTooltip from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
import { VueConstructor } from "vue";
import { Promised } from "vue-promised";

export function registerGlobalComponents(vue: VueConstructor) {
  vue.use(VTooltip, {
    container: "[data-app]",
  });
  vue.component("Loader", Loader);
  vue.component("ReferenceLink", ReferenceLink);
  vue.component("Debug", Debug);
  vue.component("ConfirmButton", ConfirmButton);
  vue.component("Promised", Promised);
  vue.component("LazyLoaded", LazyLoaded);
  vue.component("Tooltip", Tooltip);
  vue.component("vue-json-editor", () => ({
    component: import("vue-json-editor"),
    loading: Loader,
  }));

  vue.component("r-input", RecordsInput);
  vue.component("r-combobox", RecordsCombobox);
  vue.component("r-switch", RecordsSwitch);
  vue.component("r-select", RecordsSelect);
  vue.component("r-indicator-chip", RecordsIndicatorChip);
  vue.component("r-slice-chip", RecordsSliceChip);
}
