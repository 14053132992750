import { render, staticRenderFns } from "./Root.vue?vue&type=template&id=7d19636b&scoped=true&lang=pug&"
import script from "./Root.vue?vue&type=script&lang=ts&"
export * from "./Root.vue?vue&type=script&lang=ts&"
import style0 from "./Root.vue?vue&type=style&index=0&id=7d19636b&lang=scss&scoped=true&"
import style1 from "./Root.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d19636b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VAppBar,VBtn,VFooter,VIcon,VMain,VNavigationDrawer,VProgressCircular,VSpacer,VToolbarTitle})
