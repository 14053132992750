import Vue from "vue";

const position = "bottom";
const duration = 5000;

export function notifySuccess(message: string) {
  Vue.$toast.open({
    message,
    position,
    duration,
    type: "success",
  });
}

export function notifyError(message: string) {
  Vue.$toast.open({
    message,
    position,
    duration,
    type: "error",
  });
}

export function notifyWarning(message: string) {
  Vue.$toast.open({
    message,
    position,
    duration,
    type: "warning",
  });
}

export function notifyInfo(message: string) {
  Vue.$toast.open({
    message,
    position,
    duration,
    type: "info",
  });
}
