import {
    BaseDefinition,
    ChoiceDefinition,
    ElementDefinition,
    InvalidProfileError,
    Profile,
    ProfilePath,
    ProfilePathMember,
    ProfilePathResource,
    ProfilePathSlice,
    SlicedElementDefinition,
} from '@/lib-on-fhir';

/**
 * Allows to traverse a ProfilePath within a Profile
 */
export class ProfileTraverser {
    private profile: Profile;

    constructor(profile: Profile) {
        this.profile = profile;
    }

    /**
     * Traverses a given path in the profile tree and returns the matching
     * Element, Primitive, Slice or Choice definition.
     */
    traverse(path: ProfilePath): BaseDefinition | null {
        let location: BaseDefinition | null = null;
        for (let i = 0; i < path.length; i++) {
            const accessor = path[i];
            switch (accessor.type) {
                case 'resource': {
                    location = this.traverseResource(location, accessor);
                    break;
                }
                case 'member': {
                    location = this.traverseMember(location, accessor);
                    break;
                }
                case 'slice': {
                    location = this.traverseSlice(location, accessor);
                    break;
                }
            }
        }

        return location;
    }

    /**
     * Access a resource, e.g. 'Patient' -> Must be the root element
     */
    private traverseResource(
        location: BaseDefinition | null,
        accessor: ProfilePathResource
    ): ElementDefinition {
        if (location) {
            throw new InvalidProfileError(
                `Profile path invalid: '${accessor.resource}' -> resource accessor at non-root`
            );
        }

        const resource = this.profile.definitions[accessor.resource];
        if (!resource) {
            throw new InvalidProfileError(
                `Profile path invalid: Resource '${accessor.resource}' not defined yet`
            );
        }
        return resource;
    }

    /**
     * Access a member of an ElementDefintion
     */
    private traverseMember(
        location: BaseDefinition | null,
        accessor: ProfilePathMember
    ): BaseDefinition {
        if (!location || !(location instanceof ElementDefinition)) {
            throw new InvalidProfileError(
                `Profile path invalid: ${
                    accessor.member
                } -> not applied on an element definition (instead '${location?.toString()}')`
            );
        }

        let member = location.fieldTypes[accessor.member];
        if (!member) {
            throw new InvalidProfileError(
                `Profile path invalid: member '${
                    accessor.member
                }' not yet defined on '${location.toString()}'`
            );
        }

        if (accessor.choiceType && !(member instanceof ChoiceDefinition)) {
            throw new InvalidProfileError(
                `Profile path invalid: Choice type '${
                    accessor.member
                }' on '${location.toString()}' is NOT defined as a choice definition (instead ${member.toString()})`
            );
        }

        return member;
    }

    /**
     * Access a slice of an ElementDefinition
     */
    private traverseSlice(
        location: BaseDefinition | null,
        accessor: ProfilePathSlice
    ): SlicedElementDefinition {
        if (!location || !(location instanceof ElementDefinition)) {
            throw new InvalidProfileError(
                `Profile path invalid: Slice '${
                    accessor.slice
                }' must ONLY be accessed on ElementDefinition, but is on type '${location?.toString()}' `
            );
        }

        const sliceDefinition = location.slicingFieldTypes[accessor.slice];
        if (!sliceDefinition || !(sliceDefinition instanceof SlicedElementDefinition)) {
            throw new InvalidProfileError(
                `Profile path invalid: Slice '${
                    accessor.slice
                }' on ${location.toString()} has not yet been initialized or is not properly initialized`
            );
        }
        return sliceDefinition;
    }
}
