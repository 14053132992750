





import Vue from "vue";
import { config, env } from "@/services/config";

export default Vue.extend({
  data: () => ({
    config,
  }),
});
