







































































import { AddableFields, BaseWrapper } from "@/lib-on-fhir";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    items: {
      type: Object as PropType<AddableFields | null>,
    },
    element: {
      type: Object as PropType<BaseWrapper>,
    },
  },
});
