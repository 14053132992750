








import { SystemTypeDefinition } from '@/lib-on-fhir';
import Vue from 'vue';
import { PropType } from 'vue-demi';

import ExplorerMetaInfo from './ExplorerMetaInfo.vue';

export default Vue.extend({
    props: {
        element: {
            type: Object as PropType<SystemTypeDefinition>,
            required: true,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },

    components: { ExplorerMetaInfo },

    data: () => ({
        SystemTypeDefinition,
    }),

    mounted() {},
});
