import { BaseWrapper, ContextualLogger, Profile } from '@/lib-on-fhir';
import FHIR from 'fhir/r4';

/**
 * Cardinality, widely used within the profile tree
 */
export class Cardinality {
    min: number;
    max: number | typeof Infinity;

    constructor({ min, max }: { min: Cardinality['min']; max: Cardinality['max'] }) {
        this.min = min;
        this.max = max;
    }

    clone() {
        return new Cardinality({ ...this });
    }
}

/**
 * All possible field modifiers we support
 */
export enum FieldModifiers {
    ModifyingElement = 'ModifyingElement',
    MustSupport = 'MustSupport',
    Summary = 'Summary',
    Constraints = 'Constraints',
    NoExtensions = 'NoExtensions',
    TrialUse = 'TrialUse',
    Normative = 'Normative',
    Draft = 'Draft',
}

/**
 * The payload for creating a default object, used while traversing downstream
 */
export type DefaultFieldsDownstreamPayload = {
    pattern?: any;
    fixedValue?: any;
    defaultValue?: any;
};

export type ElementBinding = {
    valueSet: string;
    strength: NonNullable<FHIR.ElementDefinitionBinding>['strength'];
};

/**
 * Base type for all profile type definitions
 */
export abstract class BaseDefinition {
    logger: ContextualLogger = new ContextualLogger('definition');

    // Allow access to the underlying fhir element, NOT recommended to use
    $original?: FHIR.ElementDefinition;

    // Reference to the original profile
    $profile!: Profile;

    // Element modifiers
    flags = new Set<FieldModifiers>();

    // Generic pattern matching and fixed values
    pattern: any;
    fixedValue: any;
    defaultValue: any;

    // Cardinality of the element
    cardinality = new Cardinality({
        min: -1,
        max: -1,
    });

    // Optional value binding
    binding?: ElementBinding;

    // Optional profile target
    targetProfile?: string[];

    abstract toString(): string;

    /**
     * Wraps a resource without knowledge of the parent, used within arrays
     */
    abstract wrapResourceWithinArray(
        resource: any,
        key: string,
        index: number
    ): BaseWrapper | undefined;

    /**
     * Wraps a resource stored on the resource with the given key
     */
    abstract wrapResourceWithinObject(resource: any, key: string): BaseWrapper | undefined;

    /**
     * Adds this type's default value on the given target, at the given key.
     * If the target is an array, should push the value to that array. Shall be
     * implemented by all definition classes
     */
    abstract addDefaultFieldOnObject(
        target: any,
        key: string | number,
        payload: DefaultFieldsDownstreamPayload
    ): void;

    /**
     * Should return the definition where new members should be added - usually on the definition
     * itself, but sometimes (e.g. for choice types) it makes sense to add it on a children
     */
    getTargetForNewDefinitions(): BaseDefinition {
        return this;
    }

    /**
     * Copies the other definition to this one, but only shallow, meaning we still
     * share the fieldTypes reference for example on an element definition
     */
    copyShallowFrom(other: BaseDefinition) {
        this.$original = other.$original;
        this.logger = other.logger;
        this.flags = other.flags;
        this.pattern = other.pattern;
        this.fixedValue = other.fixedValue;
        this.defaultValue = other.fixedValue;
        this.cardinality = other.cardinality.clone();
        this.$profile = other.$profile;
        this.targetProfile = other.targetProfile;
    }

    /**
     * Creates a shallow clone of this definition.
     * @see BaseDefinition.copyShallowFrom
     */
    cloneShallow() {
        const element = new (this.constructor as new () => BaseDefinition)();
        element.copyShallowFrom(this);
        return element;
    }
}
