import { ElementDefinition, InvalidProfileError, PrimitiveDefinition } from '@/lib-on-fhir';

/**
 * A specific element definition for a slice
 */
export class SlicedElementDefinition extends ElementDefinition {
    sliceName: string = '';

    copyShallowFrom(other: SlicedElementDefinition) {
        super.copyShallowFrom(other);
        if (!(other instanceof SlicedElementDefinition)) {
            throw this.logger.scopedException(
                InvalidProfileError,
                'copyShallowFrom(): Target type mismatch, is not a slice'
            );
        }
        this.sliceName = other.sliceName;
    }

    cloneShallow(): never {
        throw this.logger.scopedException(Error, 'cloneShallow(): Not allowed');
    }

    static convertFromNonSliced(other: ElementDefinition) {
        const definition = new SlicedElementDefinition();
        ElementDefinition.prototype.copyShallowFrom.call(definition, other);
        return definition;
    }

    toString(): string {
        return `#${this.typeName}:${this.sliceName}`;
    }
}

/**
 * A specific primitive definition for a slice
 */
export class SlicedPrimitiveDefinition extends PrimitiveDefinition {
    sliceName: string = '';

    copyShallowFrom(other: SlicedPrimitiveDefinition) {
        super.copyShallowFrom(other);
        if (!(other instanceof SlicedPrimitiveDefinition)) {
            throw this.logger.scopedException(
                InvalidProfileError,
                'copyShallowFrom(): Target type mismatch, is not a slice'
            );
        }
        this.sliceName = other.sliceName;
    }

    cloneShallow(): never {
        throw this.logger.scopedException(Error, 'cloneShallow(): Not allowed');
    }

    static convertFromNonSliced(other: PrimitiveDefinition) {
        const definition = new SlicedPrimitiveDefinition();
        PrimitiveDefinition.prototype.copyShallowFrom.call(definition, other);
        return definition;
    }

    toString(): string {
        return `#primitive:${this.typeName}:${this.sliceName}`;
    }
}
