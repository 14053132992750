import router from "@/router";
import { FILTER_COMPARATORS } from "@/state/filter-comparators";
import { useResourceListStore } from "@/state/resourceListStore";
import FHIR from "fhir/r4";
import { defineStore } from "pinia";
import Vue from "vue";
import {
  deserializeResourceFilterConfig,
  serializeResourceFilterConfig,
} from "./filterConfigSerialize";

export type ResourceFilterConfig = {
  parameter: FHIR.SearchParameter;
  comparator: string;
  value: string;

  pendingValue: string;
  pendingComparator: string;
};

export const useResourceFilterStore = defineStore("resourceFilter", {
  state: () => ({
    filtersByResource: {} as Record<string, ResourceFilterConfig[]>,
  }),

  getters: {
    filters(): ResourceFilterConfig[] {
      return this.filtersByResource[useResourceListStore().resourceType];
    },
  },

  actions: {
    /**
     * Initializes the per-resource filters
     */
    initForResource() {
      const resourceType = useResourceListStore().resourceType;
      if (!this.filtersByResource[resourceType]) {
        console.warn("filters:: initialize for", resourceType);
        Vue.set(this.filtersByResource, resourceType, []);
      } else {
        console.warn("filters:: already initialized for", resourceType);
      }
    },

    /**
     * Adds a new filter for the current resource
     */
    addFilter(parameter: FHIR.SearchParameter) {
      const entry: ResourceFilterConfig = {
        parameter,
        comparator: "none",
        value: "",
        pendingValue: "",
        pendingComparator: "none",
      };
      this.filters.push(entry);
      return entry;
    },

    /**
     * Removes the gien filter
     */
    removeFilter(filter: ResourceFilterConfig) {
      this.filters.splice(this.filters.indexOf(filter), 1);
      this.refreshAfterFiltersChanged();
    },

    /**
     * Applies a given filter, that is, applying all pending changes
     */
    applyFilter(filter: ResourceFilterConfig) {
      filter.value = filter.pendingValue;
      filter.comparator = filter.pendingComparator;
      this.refreshAfterFiltersChanged();
    },

    /**
     * Internal method to refresh the list when the filters changed
     */
    async refreshAfterFiltersChanged() {
      useResourceListStore().currentState.paginationPage = 1;
      await useResourceListStore().refresh();
    },

    /**
     * Builds the query with the parameters provided by the current filter configuration
     */
    buildSearchQuery() {
      const params: Record<string, string[]> = {};

      for (const filter of this.filters) {
        const code = filter.parameter.code;

        let key = code;
        let value = String(filter.value);

        const comparatorConfig = FILTER_COMPARATORS[filter.parameter.type];
        const comparator = comparatorConfig.comparators[filter.comparator];

        if (comparator.keySuffix) {
          key += comparator.keySuffix;
        }
        if (comparator.valuePrefix) {
          value = comparator.valuePrefix + value;
        }
        if (comparator.fixedValue) {
          value = comparator.fixedValue;
        }

        if (!value) {
          continue;
        }

        if (params[key]) {
          (params[key] as string[]).push(value);
        } else {
          params[key] = [value];
        }
      }

      return params;
    },
  },
});

export function restoreFilterStateFromRoute() {
  let query = router.currentRoute.query;
  let routerFilterQueryString = query.filters as string;

  if (routerFilterQueryString) {
    restoreFilterStateFromQueryString(routerFilterQueryString);
  }
}

function restoreFilterStateFromQueryString(queryString: string) {
  let filterConfig = deserializeResourceFilterConfig(
    queryString,
    useResourceListStore().resourceType
  );

  useResourceFilterStore().$patch({
    filtersByResource: {
      [useResourceListStore().resourceType]: filterConfig,
    },
  });

  useResourceFilterStore().refreshAfterFiltersChanged();
}

// pinia workaround..
setTimeout(() => {
  // watch store for changes and serialize state to url
  useResourceFilterStore().$subscribe((mutation, state) => {
    let activeResourceType = useResourceListStore().resourceType;

    if (!activeResourceType) {
      return;
    }

    let activeFilters = state.filtersByResource[activeResourceType];
    let filterQueryString = serializeResourceFilterConfig(activeFilters);

    if (router.currentRoute.query["filters"] !== filterQueryString) {
      router.replace({ query: { filters: filterQueryString || undefined } });
    }
  });
});
