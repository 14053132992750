
























































































































import ErrorsBadge from "@/components/ErrorsBadge.vue";
import AutofixButton from "@/components/explore/AutofixButton.vue";
import EditItemButton from "@/components/explore/EditItemButton.vue";
import { useElementErrors } from "@/components/explore/renderer/setup/element-errors";
import { useErrorHighlighting } from "@/components/explore/renderer/setup/error-highlight";
import BindingRenderer from "@/components/explore/system-types/binding.vue";
import BooleanRenderer from "@/components/explore/system-types/boolean.vue";
import DateRenderer from "@/components/explore/system-types/date.vue";
import DatetimeRenderer from "@/components/explore/system-types/datetime.vue";
import ReadonlyRenderer from "@/components/explore/system-types/readonly.vue";
import TextRenderer from "@/components/explore/system-types/text.vue";
import ReferenceRenderer from "@/components/explore/system-types/reference.vue";
import {
  LocalResourceError,
  SystemTypeBoolean,
  SystemTypeDate,
  SystemTypeDateTime,
  SystemTypeDecimal,
  SystemTypeDefinition,
  SystemTypeInteger,
  SystemTypeString,
  SystemTypeTime,
  SystemTypeWrapper,
} from "@/lib-on-fhir";
import Vue from "vue";
import { defineComponent, onMounted, ref, watch } from "vue-demi";
import { config } from "@/services/config";

const typeMappings = new Map<new () => SystemTypeDefinition, new () => Vue>();

typeMappings.set(SystemTypeString, TextRenderer);
typeMappings.set(SystemTypeInteger, TextRenderer);
typeMappings.set(SystemTypeDateTime, DatetimeRenderer);
typeMappings.set(SystemTypeDate, DateRenderer);
typeMappings.set(SystemTypeBoolean, BooleanRenderer);
typeMappings.set(SystemTypeDecimal, TextRenderer);
typeMappings.set(SystemTypeTime, TextRenderer);

export default defineComponent({
  props: {
    element: {
      type: SystemTypeWrapper,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    errorStackSize: {
      type: Number,
      required: true,
    },
  },
  components: {
    EditItemButton,
    ErrorsBadge,
    AutofixButton,
  },

  computed: {
    indent(): string {
      return Math.max(this.depth - 1, 0) * 25 + "px";
    },

    renderer(): (new () => Vue) | undefined {
      if (this.element.readonly) {
        return ReadonlyRenderer;
      }

      if (this.element.binding) {
        return BindingRenderer;
      }

      if (this.element.targetProfile) {
        return ReferenceRenderer;
      }

      return typeMappings.get(this.element.$type.constructor as any);
    },

    availableFix(): LocalResourceError | undefined {
      return this.element.combinedErrors.find((error) => error.fix);
    },

    readonly(): boolean {
      return config.appConfig.readOnlyMode;
    },
  },

  methods: {
    deleteValue() {
      this.element.deleteValue();
    },
  },

  setup(props, root) {
    // Required for vue3
    const elementReference = ref(null);

    const highlighting = useErrorHighlighting(props as any, {
      elementReference,
    });

    const updateHighlighting = ({
      containsError,
      isError,
    }: {
      containsError: boolean | null;
      isError: boolean | null;
    }) => {
      if (isError || containsError) {
        if (props.fieldId) {
          highlighting.highlight();
        }
      }
    };

    // Auto-expand and highlight
    watch(highlighting.errorHighlights, (value) => updateHighlighting(value));

    onMounted(() => {
      updateHighlighting(highlighting.errorHighlights.value);
    });

    // Errors
    const elementErrors = useElementErrors(props, {});

    return {
      elementReference,
      ...elementErrors,
      ...highlighting,
    };
  },
});
