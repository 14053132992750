


























































import AutofixButton from "@/components/explore/AutofixButton.vue";
import DynamicResolver from "@/components/explore/DynamicResolver.vue";
import IndentedEntry from "@/components/explore/IndentedEntry.vue";
import {
  computeSortedFieldList,
  SortedFieldList,
} from "@/components/explore/support/sorting";
import { AddableFields, ElementWrapper } from "@/lib-on-fhir";
import Vue from "vue";

export default Vue.extend({
  props: {
    element: ElementWrapper,
    depth: Number,
    errorStackSize: {
      type: Number,
      required: true,
    },
  },
  components: {
    DynamicResolver,
    IndentedEntry,
    AutofixButton,
  },

  computed: {
    addableFields(): AddableFields {
      return this.element.getAvailableFieldsToAdd();
    },

    fieldList(): SortedFieldList {
      return computeSortedFieldList(this.element);
    },
  },
});
