import {
    ContextualLogger,
    ExternalProfileResolver,
    InvalidProfileError,
    SystemTypeMappings,
} from '@/lib-on-fhir';
import FHIR from 'fhir/r4';

export const FHIR_CANONICAL_BASE = 'http://hl7.org/fhir/StructureDefinition/';

/**
 * Allows to prefetch all referenced profiles starting from a given base profile
 */
export class ProfilePrefetcher {
    logger = new ContextualLogger('prefetch');
    resolver: ExternalProfileResolver;
    profiles: Record<string, FHIR.StructureDefinition> = {};

    valueSets: Record<string, Promise<FHIR.ValueSet>> = {};
    resolvedValueSets: Record<string, FHIR.ValueSet> = {};

    changeHandler: () => void;

    constructor(resolver: ExternalProfileResolver, changeHandler: () => void) {
        this.resolver = resolver;
        this.changeHandler = changeHandler;
    }

    /**
     * Allows to resolve a previously cached profile by its canonical url
     */
    resolve(canonical: string): FHIR.StructureDefinition {
        if (canonical.startsWith(FHIR_CANONICAL_BASE)) {
            canonical = canonical.substring(FHIR_CANONICAL_BASE.length);
        }
        return this.profiles[canonical];
    }

    /**
     * Allows to resolve a previously cached profile by its canonical url
     */
    expandValueSet(canonical: string): Promise<FHIR.ValueSet | undefined> {
        const result = this.valueSets[canonical];
        if (!result) {
            return (this.valueSets[canonical] = this.loadValueSet(canonical));
        }
        return result;
    }

    /**
     * Allows to resolve a previously cached profile by its canonical url,
     * but sync
     */
    expandValueSetSync(canonical: string): FHIR.ValueSet | undefined {
        const result = this.resolvedValueSets[canonical];
        if (!result) {
            this.expandValueSet(canonical);
        }
        return result;
    }

    /**
     * Prefetches all profiles which are referenced in the given structure definition
     */
    async prefetch(profile: FHIR.StructureDefinition, logger: ContextualLogger = this.logger) {
        if (!profile.snapshot) {
            console.warn('Profile has no snapshot:', profile);
            throw logger.scopedException(
                Error,
                'Profile has no snapshot: ' + profile.url + ' (' + profile.url + ')'
            );
        }

        for (const element of profile.snapshot.element) {
            if (!element.type) {
                continue;
            }

            const promises: Promise<void>[] = [];
            for (const type of element.type) {
                // Load externally resolved profiles
                if (type.profile) {
                    if (type.profile.length > 1) {
                        throw logger.scopedException(
                            InvalidProfileError,
                            'Types with more than 1 external profile are not supported yet: ' +
                                JSON.stringify(type)
                        );
                    }

                    promises.push(
                        this.loadRecursive(type.profile[0], logger.child(type.profile[0]))
                    );
                }

                // Always also load the base type
                if (type.code) {
                    promises.push(this.loadRecursive(type.code, logger.child(type.code)));
                }
            }

            await Promise.all(promises);
        }
    }

    /**
     * Internal method to fetch a given profile from a canonical
     */
    protected async loadRecursive(canonical: string, logger: ContextualLogger = this.logger) {
        if (canonical.startsWith(FHIR_CANONICAL_BASE)) {
            canonical = canonical.substring(FHIR_CANONICAL_BASE.length);
        }

        if (this.profiles[canonical]) {
            return;
        }

        if (SystemTypeMappings[canonical]) {
            return;
        }

        logger.debug('Prefetching', canonical);
        const resolved = await this.resolver.resolve(canonical);
        this.profiles[canonical] = Object.freeze(resolved);
        await this.prefetch(resolved, logger);
    }

    /**
     * Internal method to expand a given value set
     */
    protected async loadValueSet(
        canonical: string,
        logger: ContextualLogger = this.logger
    ): Promise<FHIR.ValueSet> {
        logger.debug('Prefetching value set', canonical);
        const result = Object.freeze(await this.resolver.expandValueSet(canonical));
        this.resolvedValueSets[canonical] = result;
        this.changeHandler();
        return result as FHIR.ValueSet;
    }
}
