import Vue from "vue";
import { fhirApi } from "@/services/data";
import { eventBus } from "@/state/eventBus";

const ResourceCache: { [key: string]: Promise<any> } = {};
const ResourceCountCache: { [key: string]: Promise<number> } = {};

async function getResourceTypeCount(resourceType: string) {
  if (typeof ResourceCountCache[resourceType] === "undefined") {
    Vue.set(
      ResourceCountCache,
      resourceType,
      fhirApi.loadResourceCount(resourceType).then((res) => res.total)
    );
  }
  return ResourceCountCache[resourceType];
}

async function getFhirResource(resource: string) {
  if (typeof ResourceCache[resource] === "undefined") {
    Vue.set(ResourceCache, resource, fhirApi.getFhirResource(resource));
  }
  return ResourceCache[resource];
}

export const resourceResolver = {
  getResourceTypeCount,
  getFhirResource,
  getCapabilityStatement,
  _ResourceCache: ResourceCache,
};

let capabilityStatementCache: Promise<any> | null = null;
export function getCapabilityStatement() {
  if (capabilityStatementCache === null) {
    capabilityStatementCache = fhirApi.getCapabilityStatement();
  }
  return capabilityStatementCache;
}
