import {
    BaseDefinition,
    BaseWrapper,
    capitalizeString,
    ChoiceWrapper,
    DefaultFieldsDownstreamPayload,
    ElementDefinition,
    InvalidResourceError,
    isNullOrUndefined,
    ProfileNotSupportedError,
    InvalidProfileError,
} from '@/lib-on-fhir';

/**
 * Type definition for a choice type, which has multiple allowed types
 */
export class ChoiceDefinition extends BaseDefinition {
    allowedTypes: ElementDefinition[];
    baseName: string;

    constructor({
        allowedTypes,
        baseName,
    }: {
        allowedTypes: ElementDefinition[];
        baseName: string;
    }) {
        super();
        this.allowedTypes = allowedTypes;
        this.baseName = baseName;
    }

    addDefaultFieldOnObject(
        target: any,
        key: string | number,
        payload: DefaultFieldsDownstreamPayload
    ) {
        // @NOTICE: Could be more sophisticated, but for now it works -> maybe we can improve this tho
        const type = this.allowedTypes[0];

        if (!(type instanceof ElementDefinition)) {
            throw this.logger.scopedException(
                ProfileNotSupportedError,
                'Choice type must have only element definitions'
            );
        }

        payload.pattern = this.pattern || payload.pattern;
        payload.fixedValue = this.fixedValue || payload.fixedValue;
        payload.defaultValue = this.defaultValue || payload.defaultValue;

        const subKey = key + capitalizeString(type.typeName);
        type.addDefaultFieldOnObject(target, subKey, payload);
    }

    wrapResourceWithinObject(resource: any, key: string): BaseWrapper | undefined {
        for (const type of this.allowedTypes) {
            if (!(type instanceof ElementDefinition)) {
                throw this.logger.scopedException(
                    ProfileNotSupportedError,
                    'Choice type must have only element definitions'
                );
            }

            const subKey = key + capitalizeString(type.typeName);
            if (isNullOrUndefined(resource[subKey])) {
                continue;
            }

            const childValue = type.wrapResourceWithinObject(resource, subKey);
            if (isNullOrUndefined(childValue)) {
                throw this.logger.scopedException(
                    InvalidResourceError,
                    'Resource has key for choice type but no value'
                );
            }

            const wrapper = new ChoiceWrapper();
            wrapper.$type = this;
            wrapper._ref = {
                resource: resource,
                key: key,
            };
            wrapper.currentType = type;
            wrapper.value = childValue;
            return wrapper;
        }
    }

    getTargetForNewDefinitions() {
        if (this.allowedTypes.length !== 1) {
            throw this.logger.scopedException(
                InvalidProfileError,
                `Accessing members on choice types is only allowed when there is only one allowed type`
            );
        }

        const onlyChild = this.allowedTypes[0];
        if (!(onlyChild instanceof ElementDefinition)) {
            throw this.logger.scopedException(
                InvalidProfileError,
                `Accessing members on choice types is only allowed when the only type is an element definition`
            );
        }
        return onlyChild;
    }

    wrapResourceWithinArray(): BaseWrapper | undefined {
        throw new Error('not allowed');
    }

    copyShallowFrom() {
        throw this.logger.scopedException(
            ProfileNotSupportedError,
            'Can not shallow clone choice (yet)'
        );
    }

    toString() {
        return `Choice[${this.allowedTypes.map(type => type.toString()).join('|')}]`;
    }
}
