















































































































































import AddItemButton from '@/components/explore/AddItemButton.vue';
import {
    AddableFields,
    ElementWrapper,
    PrimitiveWrapper,
    SlicedElementDefinition,
} from '@/lib-on-fhir';
import ExplorerResolver from '@/lib-on-fhir/vue-example/resource-explorer/ExplorerResolver.vue';
import { config } from '@/services/config';
import Vue from 'vue';

export default Vue.extend({
    props: {
        element: {
            type: ElementWrapper,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },

    components: { ExplorerResolver, AddItemButton },
    data: () => ({
        ElementWrapper,
        PrimitiveWrapper,
        SlicedElementDefinition,
    }),

    methods: {
        addNewField(key: string) {
            this.element.addNewField(key);
        },
        deleteElementValue() {
            this.element.deleteValue();
        },
    },

    computed: {
        isPrimitive(): boolean {
            return this.element instanceof PrimitiveWrapper;
        },

        availableFieldsToAdd(): AddableFields {
            return this.element.getAvailableFieldsToAdd();
        },

        hideLabels(): boolean {
            return (
                !config.showDebug &&
                this.isPrimitive &&
                !this.element.fields.id &&
                !this.element.fields.extension
            );
        },
    },
});
