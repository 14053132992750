// E.g. Resource
export interface ProfilePathResource {
    type: 'resource';
    resource: string;
}

// E.g. Resource.observations
export interface ProfilePathMember {
    type: 'member';
    member: string;

    choiceType?: true;
}

// E.g. Resource.observations:SomeSlice
export interface ProfilePathSlice {
    type: 'slice';
    slice: string;
}

// E.g. Resource.observations[0]
export interface ProfilePathIndex {
    type: 'index';
    index: number;
}

export type ProfilePath = (
    | ProfilePathResource
    | ProfilePathMember
    | ProfilePathSlice
    | ProfilePathIndex
)[];

export class ProfilePathParser {
    /**
     * Converts a string path similar to fhir-path into a data structure for easier processing.
     */
    static parse(path: string, profileScope: string = ''): ProfilePath {
        const parts = path
            // Make it easier to split the path for slicing, by replacing ":" to ".:"
            .replace(/\:/g, '.:')
            .replace(/\[x\]/gi, '$CHOICETYPE$')
            .replace(/\[/g, '.[')
            .split(/\./);

        return parts.map((part, index) => {
            switch (part[0]) {
                case ':':
                    return {
                        type: 'slice',
                        slice: part.substring(1),
                    };
                case '[': {
                    return {
                        type: 'index',
                        index: +part.substring(1, part.length - 1),
                    };
                }
                default: {
                    if (index === 0) {
                        return {
                            type: 'resource',
                            resource: profileScope || part,
                        };
                    } else {
                        if (part.endsWith('$CHOICETYPE$')) {
                            return {
                                type: 'member',
                                member: part.replace(/\$CHOICETYPE\$$/, ''),
                                choiceType: true,
                            };
                        }

                        return {
                            type: 'member',
                            member: part,
                        };
                    }
                }
            }
        });
    }

    /**
     * Extends a path with a member access
     */
    static appendMember(path: ProfilePath, member: string): ProfilePath {
        return [...path, { type: 'member', member }];
    }

    /**
     * Extends a path with a slice access
     */
    static appendSlice(path: ProfilePath, slice: string): ProfilePath {
        return [...path, { type: 'slice', slice }];
    }

    /**
     * Extends a path with an index access
     */
    static appendIndex(path: ProfilePath, index: number): ProfilePath {
        return [...path, { type: 'index', index }];
    }

    /**
     * Checks if two path members are equal
     */
    static isSubPathEqual(a: ProfilePath[0], b: ProfilePath[0]): boolean {
        // Notice; Doing a type check on every case to help typescript
        switch (a.type) {
            case 'slice': {
                return a.type === b.type && a.slice === b.slice;
            }
            case 'index': {
                return a.type === b.type && a.index === b.index;
            }
            case 'member': {
                return (
                    a.type === b.type &&
                    a.member === b.member &&
                    Boolean(a.choiceType) === Boolean(b.choiceType)
                );
            }
            case 'resource': {
                return a.type === b.type && a.resource === b.resource;
            }
            default: {
                throw new Error('not implemented');
            }
        }
    }

    /**
     * Checks if the given child path is a sub path of the parent path.
     * Returns FALSE for the same path
     */
    static isChildOf(parent: ProfilePath, child: ProfilePath): boolean {
        if (parent.length >= child.length) {
            return false;
        }
        for (let i = 0; i < parent.length; ++i) {
            if (!this.isSubPathEqual(parent[i], child[i])) {
                return false;
            }
        }
        return true;
    }

    /**
     * Compares two paths for equality
     */
    static isEqual(a: ProfilePath, b: ProfilePath): boolean {
        if (a.length !== b.length) {
            return false;
        }
        return a.every((entry, index) => this.isSubPathEqual(entry, b[index]));
    }

    /**
     * Converts from a path data structure to a regular string
     */
    static toString(path: ProfilePath): string {
        return path
            .map(part => {
                switch (part.type) {
                    case 'slice': {
                        return ':' + part.slice;
                    }
                    case 'member': {
                        if (part.choiceType) {
                            return '.' + part.member + '[x]';
                        }
                        return '.' + part.member;
                    }
                    case 'resource': {
                        return part.resource;
                    }
                    case 'index': {
                        return `[${part.index}]`;
                    }
                }
            })
            .join('');
    }
}
