














import { ColumnConfig } from "@/config/config";
import { resourceResolverCache } from "@/state/resourceListStore";
import Vue from "vue";
import { PropType } from "vue-demi";

export default Vue.extend({
  props: {
    resource: Object,
    values: Array as PropType<string[]>,
    columnDefinition: Object as PropType<ColumnConfig>,
  },

  data: () => ({
    cache: resourceResolverCache,
  }),

  watch: {
    resource: {
      immediate: true,
      async handler() {
        this.values
          .filter((url) => Boolean(url))
          .forEach((url) => {
            this.cache.resolve(url);
          });
      },
    },
  },
});
