import {
    BaseDefinition,
    ContextualLogger,
    ElementBinding,
    isNullOrUndefined,
    ValidationErrors,
    escapeHtml,
} from '@/lib-on-fhir';
import { ProfilePath, ProfilePathParser } from '@/lib-on-fhir';

export type DownstreamKeyConfig = Record<
    string,
    {
        readonly?: boolean;
        hidden?: boolean;
        meta?: boolean;
    }
>;

export type DownstreamPassPayload = {
    // Pattern etc
    pattern?: Record<string, any> | undefined;
    defaultValue?: Record<string, any> | undefined;
    fixedValue?: Record<string, any> | undefined;

    // Fixed binding
    binding?: ElementBinding;

    // Target profile
    targetProfile?: string[];

    // Path
    genericPath: ProfilePath;
    genericPathWithSlices: ProfilePath;
    absolutePath: ProfilePath;

    // Flags
    readonly?: boolean;
    meta?: boolean;
    hidden?: boolean;

    // Profile config (just forwarding)
    keyConfig: DownstreamKeyConfig;
    changeHandler: () => void;
};

export type WrapperPatchOptions = {
    // Should change object[key] to value if not already equal
    $patch: (object: any, key: string | number, value: any) => void;

    // Should delete object[key]
    $deleteKey: (object: any, key: string | number) => void;
};

export type LocalResourceError = {
    text: string;
    fix?: () => void;
    path: ProfilePath;
    severity: 'info' | 'warning' | 'error';
};

/**
 * Base wrapper class, effectively combining an actual resource with its
 * type definition. Allows modifications by writing them back to the original
 * json object and invoking the $changeHandler
 */
export abstract class BaseWrapper<T extends BaseDefinition = BaseDefinition> {
    $type!: T;

    /**
     * The reference to the original resource
     *  -> If index is undefined: Reference is resource[key]
     *  -> If index is set: Reference is resource[key][index]
     */
    _ref!: {
        resource: any;
        key: any;
        index?: number;
    };

    /**
     * The change handler is passed top-down and called whenever a value changes,
     * causing the render tree to be reevaluated.
     */
    _changeHandler!: () => void;

    logger = new ContextualLogger('wrapper');

    // Patterns etc
    pattern: any;
    defaultValue: any;
    fixedValue: any;

    // Flags
    readonly = false;
    meta = false;
    hidden = false;

    // Fixed bindings from profile
    binding?: ElementBinding;

    // Target profile
    targetProfile?: string[];

    // Generic path, like Patient.id.extension
    genericPath!: ProfilePath;

    // Generic path, but including slices, like Patient.name:name.family.value
    genericPathWithSlices!: ProfilePath;

    // Absolute path, like Patient.id[1].extension[0].value
    absolutePath!: ProfilePath;

    // Local errors, and combined errors (including child errors)
    localErrors: LocalResourceError[] = [];
    combinedErrors: LocalResourceError[] = [];

    /**
     * Deletes this element from the resource, also causing an invalidation of the tree
     */
    abstract deleteValue(): void;

    /**
     * Patches <other> so that it matches <this>, with minimal changes as possible
     */
    patch(other: BaseWrapper<BaseDefinition>, options: WrapperPatchOptions): void {
        options.$patch(other, '$type', this.$type);

        // Special handling for resources
        if (
            typeof other._ref.resource.$patch === 'function' &&
            typeof this._ref.resource.$patch === 'function'
        ) {
            this._ref.resource.$patch(other._ref.resource, options);
        } else {
            options.$patch(other._ref, 'resource', this._ref.resource);
        }
        options.$patch(other._ref, 'key', this._ref.key);
        options.$patch(other._ref, 'index', this._ref.index);

        // Regular props
        for (const prop of [
            'pattern',
            'defaultValue',
            'fixedValue',
            'readonly',
            'targetProfile',
            'meta',
            'hidden',
            'binding',
            'genericPath',
            'genericPathWithSlices',
            'absolutePath',
            'localErrors',
            'combinedErrors',
        ] as (keyof BaseWrapper)[]) {
            options.$patch(other, prop, this[prop]);
        }
    }

    /**
     * Resolves a readable ref to our resource. While the ref is not guaranteed
     * to be also writeable, it may be in some cases. However, this should not
     * be relied upon.
     */
    _resolveRef(): { resource: any; key: string | number } {
        if (isNullOrUndefined(this._ref.index)) {
            // Reference without index, the easiest case, and in this case the ref is also writeable
            return { resource: this._ref.resource, key: this._ref.key };
        } else {
            let value = this._ref.resource[this._ref.key];
            if (!Array.isArray(value)) {
                value = [value];
            }
            return { resource: value, key: this._ref.index! };
        }
    }

    /**
     * Propatages values like pattern, defaultValues etc to the child wrappers by
     * 'unpacking' them, which is not possible to do in the profile tree earlier due to
     * circular dependencies.
     */
    _propagateDownstream(payload: DownstreamPassPayload) {
        this.pattern = this.$type.pattern;
        this.defaultValue = this.$type.defaultValue;
        this.fixedValue = this.$type.fixedValue;
        this.binding = this.$type.binding;
        this.genericPath = payload.genericPath;
        this.genericPathWithSlices = payload.genericPathWithSlices;
        this.absolutePath = payload.absolutePath;
        this.targetProfile = this.$type.targetProfile;
        this._changeHandler = payload.changeHandler;

        for (const prop of ['pattern', 'fixedValue', 'defaultValue', 'binding', 'targetProfile']) {
            let propKey = prop as
                | 'pattern'
                | 'fixedValue'
                | 'defaultValue'
                | 'binding'
                | 'targetProfile';
            if (payload[propKey]) {
                if (isNullOrUndefined(this[propKey])) {
                    this[propKey] = payload[propKey];
                }
            }
        }

        // Extract readonly, hidden and meta flags from profile config
        let matchingEntries: DownstreamKeyConfig[''][] = [];
        for (const path of [this.genericPath, this.genericPathWithSlices, this.absolutePath]) {
            const config = payload.keyConfig[ProfilePathParser.toString(path)];
            if (config) {
                matchingEntries.push(config);
            }
        }

        // Merge keys with parent options
        for (const key of ['readonly', 'hidden', 'meta']) {
            const typedKey = key as keyof DownstreamKeyConfig[''];
            if (payload[typedKey]) {
                this[typedKey] = payload[typedKey]!;
            }
            if (matchingEntries.some(entry => entry[typedKey])) {
                this[typedKey] = true;
            }
        }
    }

    /**
     * Should compute all errors we are able to detect locally within this resource
     */
    abstract _computeLocalErrors(): void;

    /**
     * Pushes a new error to the local error stack, using the given error templates
     */
    protected _emitError({
        id,
        payload,
        severity = 'error',
        fix,
    }: {
        id: keyof typeof ValidationErrors;
        payload: Record<string, any>;
        severity?: LocalResourceError['severity'];
        fix?: () => void;
    }) {
        let errorMessage = ValidationErrors[id];
        for (const [placeholder, replacement] of Object.entries(payload)) {
            errorMessage = errorMessage.replaceAll(
                '<' + placeholder + '>',
                '<span class="coded">' + escapeHtml(String(replacement)) + '</span>'
            );
        }
        this.localErrors.push({
            text: errorMessage,
            fix,
            severity,
            path: this.absolutePath,
        });
    }
}
