























































import Vue from "vue";
import { SystemTypeWrapper } from "@/lib-on-fhir";

export default Vue.extend({
  props: {
    element: {
      type: SystemTypeWrapper,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    dateObject(): Date {
      return new Date(this.element.value);
    },

    hasIssues(): boolean {
      return this.element.combinedErrors.some(
        (error) => error.severity === "error"
      );
    },
  },
  methods: {
    onInput(event: any): void {
      this.element.value = event.toISOString();
    },
  },
});
