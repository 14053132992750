import { BaseWrapper, ProfilePathParser } from "@/lib-on-fhir";
import {
  GenericResourceError,
  useResourceIssueStore,
} from "@/state/resourceIssueStore";
import { computed, ComputedRef, Ref } from "@vue/composition-api";

export function useElementErrors(
  props: { element: BaseWrapper; errorStackSize: number },
  { localOnlyRef }: { localOnlyRef?: Ref<boolean> | ComputedRef<boolean> }
) {
  const allErrors = computed(() => {
    if (!props.element) {
      return [];
    }

    const localOnly = localOnlyRef?.value;

    const localErrors: GenericResourceError[] = localOnly
      ? props.element.localErrors
      : props.element.combinedErrors;

    const serverErrors: GenericResourceError[] =
      (localOnly
        ? useResourceIssueStore().serverErrorsByPath
        : useResourceIssueStore().serverErrorsByPathPropagated)[
        ProfilePathParser.toString(props.element.absolutePath)
      ] || [];

    return [...localErrors, ...serverErrors];
  });

  const childErrorStackSize = computed(() => {
    return allErrors.value.length > 0
      ? props.errorStackSize + 1
      : props.errorStackSize;
  });

  return {
    childErrorStackSize,
    allErrors,
  };
}
