import { config } from "@/services/config";
import FHIR from "fhir/r4";

const searchparameters = require("@/data/searchparameters.spec").entry.map(
  (entry: any) => entry.resource
) as FHIR.SearchParameter[];

const fhirpath = require("fhirpath");
const fhirpath_r4_model = require("fhirpath/fhir-context/r4");

export function resolveColumnPaths(resource: any, paths: string[]): string[] {
  return paths.map((path) => {
    let result;
    try {
      result = fhirpath.evaluate(resource, path, fhirpath_r4_model);
    } catch (ex) {
      console.warn(ex);
      return "Invalid path: " + path;
    }

    if (Array.isArray(result)) {
      return result[0];
    }
    return result;
  });
}

export function getResourceTitle(resource: any) {
  let resourceType = resource.resourceType;
  if (!resourceType) {
    return "(Invalid resource)";
  }

  let configEntry = config.appConfig.primaryResources.find(
    (entry) => entry.baseResource === resourceType
  );

  if (!configEntry) {
    return resource.id;
  }

  const column = configEntry.columns[0];
  if (!column) {
    return "(No column data)";
  }

  const values = resolveColumnPaths(resource, column.paths);
  return values.join(", ") || resource.id;
}

const searchParameterCacheByResource = new Map<
  string,
  FHIR.SearchParameter[]
>();

/**
 * Returns all availabe search parameters for a given resource type
 */
export function getSearchParametersForResource(
  resourceType: string
): FHIR.SearchParameter[] {
  if (searchParameterCacheByResource.has(resourceType)) {
    return searchParameterCacheByResource.get(resourceType)!;
  }
  const matching = ["Resource", "DomainResource", resourceType];
  let result: FHIR.SearchParameter[] = searchparameters.filter((entry) => {
    return matching.some((match) => entry.base.includes(match));
  });

  searchParameterCacheByResource.set(resourceType, result);
  return result;
}
