




























import Vue from "vue";

export default Vue.extend({
  inheritAttrs: false,
  props: {
    hasIssues: {
      type: Boolean,
      default: false,
    },
    textLike: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
});
