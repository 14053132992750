import { render, staticRenderFns } from "./Config.vue?vue&type=template&id=c7a8b9c2&scoped=true&lang=pug&"
import script from "./Config.vue?vue&type=script&lang=ts&"
export * from "./Config.vue?vue&type=script&lang=ts&"
import style0 from "./Config.vue?vue&type=style&index=0&id=c7a8b9c2&lang=scss&scoped=true&"
import style1 from "./Config.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7a8b9c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAlert,VBtn,VBtnToggle,VSnackbar})
