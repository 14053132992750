





































































































import { ChoiceDefinition, ElementDefinition, SystemTypeDefinition } from '@/lib-on-fhir';
import Vue from 'vue';
import ExplorerChoiceTypeRenderer from './ExplorerChoiceTypeRenderer.vue';
import ExplorerMetaInfo from './ExplorerMetaInfo.vue';
import ExplorerSystemTypeRenderer from './ExplorerSystemTypeRenderer.vue';

export default Vue.extend({
    name: 'ElementDefinitionRenderer',
    props: {
        element: {
            type: ElementDefinition,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },

    components: {
        ExplorerSystemTypeRenderer,
        ExplorerMetaInfo,
        ExplorerChoiceTypeRenderer,
    },

    data: () => ({
        childrenExpanded: false,
        slicesExpanded: false,
        ElementDefinition,
        SystemTypeDefinition,
        ChoiceDefinition,
    }),

    mounted() {
        this.childrenExpanded = this.depth < 1 && this.element.typeName !== 'Extension';
    },
});
