import { render, staticRenderFns } from "./ResourceRenderer.vue?vue&type=template&id=dee2052a&scoped=true&lang=pug&"
import script from "./ResourceRenderer.vue?vue&type=script&lang=ts&"
export * from "./ResourceRenderer.vue?vue&type=script&lang=ts&"
import style0 from "./ResourceRenderer.vue?vue&type=style&index=0&id=dee2052a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dee2052a",
  null
  
)

export default component.exports