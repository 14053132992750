import { config } from "@/services/config";
import { fhirApi } from "@/services/data";
import FHIR from "fhir/r4";

async function resolveProfileLocalServer(
  canonical: string
): Promise<FHIR.StructureDefinition> {
  const params = new URLSearchParams({
    _count: "1",
    _skip: "0",
    url: canonical,
  });

  const url =
    config.appConfig.BASE_FHIR_URL +
    "/StructureDefinition?" +
    params.toString();

  const result = (await fhirApi.getFhirResource(url)) as FHIR.Bundle;

  if (result.entry?.length !== 1) {
    throw new Error("Profile " + canonical + " not found on local fhir server");
  }

  return result.entry[0].resource as FHIR.StructureDefinition;
}

export const profileResolverLocalServer = {
  resolveProfileLocalServer,
};
