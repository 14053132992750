import { isNullOrUndefined, WrapperPatchOptions } from '@/lib-on-fhir';

/**
 * The effective definition of a primitive type - this is how a primitive type is
 * described by the FHIR standard, although its mapped entirely different within a
 * JSON encoded resource.
 */
type UnwrappedPrimitiveData = {
    id?: string;
    extension?: any;
    value?: any;
}[];

/**
 * Checks if an unwrapped object needs a meta key entry (e.g. '_key')
 */
function unwrappedNeedsMeta(v: UnwrappedPrimitiveData[0] | undefined): boolean {
    return !isNullOrUndefined(v?.id) || !isNullOrUndefined(v?.extension);
}

/**
 * A proxy object to access primitive fields stored as JSON. This is required because
 * the FHIR standard has some compiler magic to store primitive fields as well
 * as their id and extensions.
 */
export class PrimitiveJSONProxy {
    protected parent: any;
    protected key: string;
    protected index: number;

    constructor({ parent, key, index }: { parent: any; key: string; index: number }) {
        this.parent = parent;
        this.key = key;
        this.index = index;
    }

    /**
     * Unwraps all field values, ids, and extensions to a standardized list
     */
    protected unwrap(): UnwrappedPrimitiveData {
        let value = this.parent[this.key];
        let meta = this.parent['_' + this.key];
        if (!Array.isArray(value)) {
            if (isNullOrUndefined(value)) {
                value = [];
            } else {
                value = [value];
            }
        }
        if (!Array.isArray(meta)) {
            if (isNullOrUndefined(meta)) {
                meta = [];
            } else {
                meta = [meta];
            }
        }
        return new Array(value.length).fill(null).map((_, index) => ({
            id: meta[index]?.id,
            value: value[index],
            extension: meta[index]?.extension,
        }));
    }

    /**
     * Applies the given unwrapped data to the compressed json object
     */
    protected wrap(data: UnwrappedPrimitiveData) {
        // Only take entries which have a value
        data = data.filter(v => !isNullOrUndefined(v.value));

        if (data.length === 0) {
            this.parent[this.key] = undefined;
            this.parent['_' + this.key] = undefined;
        } else if (data.length === 1) {
            const entry: UnwrappedPrimitiveData[0] | undefined = data[0];
            this.parent[this.key] = entry.value;
            if (unwrappedNeedsMeta(entry)) {
                this.parent['_' + this.key] = {
                    extension: entry.extension,
                    id: entry.id,
                };
            } else {
                this.parent['_' + this.key] = undefined;
            }
        } else {
            // Need an array
            // Value is ALWAYS present
            this.parent[this.key] = data.map(v =>
                typeof v.value === 'undefined' ? null : v.value
            );

            // Only set meta if required
            if (data.some(v => unwrappedNeedsMeta(v))) {
                this.parent['_' + this.key] = data.map(v =>
                    unwrappedNeedsMeta(v)
                        ? {
                              id: v.id,
                              extension: v.extension,
                          }
                        : null
                );
            }
        }
    }

    /**
     * Allows to modify thie data within one transaction, first unwrapping and then
     * wrapping it again
     */
    protected modifyWithinTransaction(handler: (data: UnwrappedPrimitiveData) => void) {
        const data = this.unwrap();
        handler(data);
        this.wrap(data);
    }

    /**
     * Deletes the current value
     */
    deleteValue() {
        this.modifyWithinTransaction(data => {
            data[this.index].value = undefined;
            data[this.index].id = undefined;
            data[this.index].extension = undefined;
        });
    }

    // Getters
    get id() {
        return this.unwrap()[this.index]?.id;
    }

    get value(): any {
        return this.unwrap()[this.index]?.value;
    }

    get extension() {
        return this.unwrap()[this.index]?.extension;
    }

    // Setters
    set id(value: any) {
        this.modifyWithinTransaction(data => {
            data[this.index].id = value;
        });
    }

    set value(value: any) {
        this.modifyWithinTransaction(data => {
            data[this.index].value = value;
        });
    }

    set extension(value: any) {
        this.modifyWithinTransaction(data => {
            data[this.index].extension = value;
        });
    }

    $patch(other: PrimitiveJSONProxy, options: WrapperPatchOptions) {
        if (!(other instanceof PrimitiveJSONProxy)) {
            throw new Error('patch(): Type mismatch');
        }

        for (const prop of ['key', 'parent', 'index']) {
            options.$patch(other, prop, this[prop as keyof PrimitiveJSONProxy]);
        }
    }

    toJSON() {
        return {
            id: this.id,
            value: this.value,
            extension: this.extension,
        };
    }
}
