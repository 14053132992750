































import SystemTypeRenderer from "@/components/explore/renderer/SystemTypeRenderer.vue";
import {
  ArrayWrapper,
  ChoiceWrapper,
  ElementWrapper,
  SystemTypeWrapper,
} from "@/lib-on-fhir";
import Vue from "vue";
import { defineAsyncComponent } from "vue-demi";
export default Vue.extend({
  props: {
    element: {
      type: Object,
    },
    fieldId: {
      type: String,
    },
    depth: {
      type: Number,
    },
    errorStackSize: {
      type: Number,
      required: true,
    },
  },

  components: {
    ElementRenderer: defineAsyncComponent(() =>
      import("@/components/explore/renderer/ElementRenderer.vue")
    ),
    ArrayRenderer: defineAsyncComponent(() =>
      import("@/components/explore/renderer/ArrayRenderer.vue")
    ),
    ChoiceRenderer: defineAsyncComponent(() =>
      import("@/components/explore/renderer/ChoiceRenderer.vue")
    ),
    // Async breaks vuetify
    SystemTypeRenderer,
  },

  data: () => ({
    ArrayWrapper,
    ElementWrapper,
    SystemTypeWrapper,
    ChoiceWrapper,
  }),
});
