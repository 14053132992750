/**
 * Thrown whenever a profile is considered invalid
 */
export class InvalidProfileError extends Error {}

/**
 * Thrown whenever a profile is valid, but a particular feature is not supported
 */
export class ProfileNotSupportedError extends Error {}

/**
 * Thrown whenever a resource is considered invalid
 */
export class InvalidResourceError extends Error {}

/**
 * Localized validation errors
 */
export const ValidationErrors = {
    fieldCardinalityTooLow:
        'Field <fieldId> is missing but required by profile, minimum is <minimum>.',
    fieldCardinalityTooHigh: 'Field <fieldId> has too many occurrences, maximum is <maximum>.',

    fieldSliceCardinalityTooLow:
        'Field <fullFieldId> is missing but required by profile, minimum is <minimum>.',
    fieldSliceCardinalityTooHigh:
        'Field <fullFieldId> has too many occurrences, maximum is <maximum>.',

    fieldFixedMismatch:
        'Profile requires a fixed value of <fixedValue> for this field, but field has different value.',
    fieldPatternMismatch:
        'Profile requires a pattern of <pattern> for this field, but field has different value.',

    bindingNotResolvable:
        'The valueSet <valueSetUrl> could not be resolved, and such the field can not be validated locally.',

    bindingMismatchingValue:
        'Field value <value> is not contained within <valueSetUrl>, but the profile requires it to be.',

    emptyElement: '<typeId> has no children.',

    systemTypeEmpty: 'Field is empty.',
};
