




import Vue from "vue";
import { SystemTypeWrapper } from "@/lib-on-fhir";

export default Vue.extend({
  props: {
    element: {
      type: SystemTypeWrapper,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasIssues(): boolean {
      return this.element.combinedErrors.some(
        (error) => error.severity === "error"
      );
    },
  },
});
