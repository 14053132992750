import { PrimaryResourceConfig } from "@/config/config";
import { CompatProfileResolver, deepClone } from "@/lib-on-fhir";
import { InMemoryCache } from "@/lib-on-fhir";
import { config } from "@/services/config";
import { fhirBackend } from "@/services/http";
import { notifyError, notifyWarning } from "@/services/notify";
import { resourceResolver } from "@/services/resolvers/ResourceResolver";
import { useResourceFilterStore } from "@/state/resourceFilterStore";
import { useResourceValidationStore } from "@/state/resourceValidationStore";
import FHIR from "fhir/r4";
import { defineStore } from "pinia";
import Vue from "vue";

/**
 * Default state used per-resource type
 */
const DEFAULT_STATE = {
  resourceList: [] as FHIR.Resource[],
  resourceTotalCount: 0,

  paginationPage: 1,
  paginationPerPage: 50,

  sortColumnIndex: undefined as number | undefined,
  sortDescending: true,
};

const canonicalResolver = new CompatProfileResolver();

export const resourceResolverCache = new InMemoryCache({
  id: "resolvedReference",
  setter: (obj, key, val) => {
    // make it reactive
    Vue.set(obj, key, val);
  },
  resolver: async (url: string) => {
    if (!url.startsWith(config.appConfig.BASE_FHIR_URL)) {
      // try resolving with simplifier

      try {
        const result = await canonicalResolver.resolve(url);
        console.warn(result);
        return Object.freeze(result);
      } catch (ex) {
        return { $error: "invalid url" };
      }
    }
    try {
      const result = await resourceResolver.getFhirResource(url);
      return Object.freeze(result);
    } catch (ex) {
      return { $error: "Failed to resolve: " + ex };
    }
  },
});

// Make it reactive
new Vue({
  data: resourceResolverCache,
});

export const useResourceListStore = defineStore("resourceList", {
  state: () => ({
    resourceType: "",
    currentPromise: null as null | Promise<any>,

    statePerResource: {} as Record<string, typeof DEFAULT_STATE>,
  }),

  getters: {
    /**
     * Returns the current state of the resource, ONLY valid after
     * calling init()
     */
    currentState(): typeof DEFAULT_STATE {
      return this.statePerResource[this.resourceType];
    },

    /**
     * Current page count, depends on paginationPerPage, ONLY valid after
     * calling init()
     */
    pageCount(): number {
      return Math.ceil(
        this.currentState.resourceTotalCount /
          this.currentState.paginationPerPage
      );
    },

    /**
     * Current resource config from user config, contains column information etc,
     */
    resourceConfig(): PrimaryResourceConfig {
      let configEntry = config.appConfig.primaryResources.find(
        (entry) => entry.baseResource === this.resourceType
      )!;

      if (!configEntry) {
        // generate a default config
        configEntry = {
          name: this.resourceType,
          singularName: this.resourceType,
          icon: "mdi-question",
          baseResource: this.resourceType,
          columns: [
            {
              label: "ID",
              paths: ["id"],
            },
          ],
          inactiveColumns: [],
        };
      }

      return configEntry;
    },
  },
  actions: {
    /**
     * Initializes the per-resource state
     */
    initForResource() {
      if (!this.resourceType) {
        return;
      }

      if (!this.currentState) {
        console.warn("list:: initialize for", this.resourceType);
        Vue.set(
          this.statePerResource,
          this.resourceType,
          deepClone(DEFAULT_STATE)
        );
      } else {
        console.warn("list:: already initialized for", this.resourceType);
      }

      useResourceFilterStore().initForResource();
    },

    /**
     * Changes the current resource type - this also causes a refresh
     */
    setResourceType(type: string) {
      if (this.resourceType === type) {
        // Already the same type .. why??
        console.warn("list:: setResourceType twice on", type);
        return;
      }

      console.warn("list:: change resource type", this.resourceType, type);
      useResourceValidationStore().clearValidationRequests();
      this.resourceType = type;
      this.initForResource();

      // Sort by _lastUpdated by default
      if (typeof this.currentState.sortColumnIndex === "undefined") {
        console.warn("list:: defining initial sort");
        this.currentState.sortColumnIndex = Math.max(
          0,
          this.resourceConfig.columns.findIndex(
            (column) => column.internalField === "_lastUpdated"
          ) ||
            this.resourceConfig.columns.findIndex((column) =>
              Boolean(column.internalField)
            ) ||
            0
        );
      }
    },

    /**
     * Changes the current pagination and refreshes the list
     */
    async updatePaginationAndRefresh(options: {
      page: number;
      sortColumnIndex: number;
      sortDescending: boolean;
    }) {
      console.warn("list:: udpate pagination", options);

      if (!this.currentState) {
        throw new Error("list:: no current state set");
      }

      let needsRefresh = false;
      if (this.currentState.paginationPage !== options.page) {
        this.currentState.paginationPage = options.page;
        needsRefresh = true;
      }
      if (this.currentState.sortColumnIndex !== options.sortColumnIndex) {
        this.currentState.sortColumnIndex = options.sortColumnIndex;
        needsRefresh = true;
      }
      if (this.currentState.sortDescending !== options.sortDescending) {
        this.currentState.sortDescending = options.sortDescending;
        needsRefresh = true;
      }
      if (needsRefresh) {
        console.warn("list:: =>", "refresh");
        useResourceValidationStore().clearValidationRequests();
        await this.refresh();
      }
    },

    /**
     * Builds the full search query parameters including filtering, sorting and pagination
     */
    buildSearchQuery() {
      if (!this.currentState) {
        throw new Error("list:: no current state set");
      }

      const params: Record<string, string[] | string> = {
        _count: String(this.currentState.paginationPerPage),
        _skip: String(
          (this.currentState.paginationPage - 1) *
            this.currentState.paginationPerPage
        ),
      };

      if (this.currentState.sortColumnIndex) {
        const fieldConfig = this.resourceConfig.columns[
          this.currentState.sortColumnIndex
        ];

        if (!fieldConfig) {
          console.warn(
            "No field config for",
            this.currentState.sortColumnIndex,
            "on",
            this.resourceConfig
          );
        } else if (!fieldConfig.internalField) {
          console.warn("Field config has no internal field:", fieldConfig);
        } else {
          params._sort =
            (this.currentState.sortDescending ? "-" : "") +
            fieldConfig.internalField;
        }
      }

      const filters = useResourceFilterStore().buildSearchQuery();
      return { ...params, ...filters };
    },

    /**
     * Builds the final search url
     */
    buildSearchUrl() {
      const params = this.buildSearchQuery();
      let builtQuery: string[] = [];
      for (const [key, values] of Object.entries(params)) {
        if (Array.isArray(values)) {
          for (const value of values) {
            builtQuery.push(new URLSearchParams({ [key]: value }).toString());
          }
        } else {
          builtQuery.push(new URLSearchParams({ [key]: values }).toString());
        }
      }
      return this.resourceType + "?" + builtQuery.join("&");
    },

    /**
     * Refreshes the current data
     */
    async refresh() {
      if (!this.resourceType) {
        throw new Error("resourceType is not set");
      }

      if (this.currentPromise) {
        console.warn("list:: Cancelling previous request");
        this.currentPromise = null;
      }

      const state = this.currentState;
      const url = this.buildSearchUrl();
      console.log("list:: Fetching", url);

      const promise = (this.currentPromise = fhirBackend.get(url));

      let response: FHIR.Bundle;
      try {
        response = (await this.currentPromise) as FHIR.Bundle;
      } catch (ex) {
        notifyError(`Failed to load resource list: ${ex}`);
        throw ex;
      } finally {
        if (promise !== this.currentPromise) {
          console.warn("list:: promise expired");
          return;
        }
        this.currentPromise = null;
      }

      if (!response.entry) {
        state.resourceList = [];
        console.info("list:: got empty list");
        return;
      }

      const outcome = response.entry!.filter(
        (entry) => entry.search!.mode === "outcome"
      )![0]?.resource as FHIR.OperationOutcome;

      // List errors and warnings
      if (outcome) {
        for (const issue of outcome.issue) {
          const issueText = String(issue.details?.text || issue.diagnostics);
          if (
            issueText.includes("If-Modified-Since") ||
            issueText.includes("If-None-Match")
          ) {
            continue;
          }
          console.warn("list:: Operation outcome:", issueText);
          notifyWarning("Operation outcome: " + issueText);
        }
      }

      state.resourceTotalCount = response.total!;
      state.resourceList = response
        .entry!.filter((entry) => entry.search?.mode === "match")
        .map((entry) => entry.resource!);
      console.info("list:: Fetched", state.resourceList.length, "entries");
    },
  },
});
