




























import ReferenceLink from "@/components/ReferenceLink.vue";
import { SystemTypeWrapper } from "@/lib-on-fhir";
import { config } from "@/services/config";
import { resourceResolverCache } from "@/state/resourceListStore";
import Vue from "vue";

export default Vue.extend({
  props: {
    element: {
      type: SystemTypeWrapper,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ReferenceLink,
  },

  data: () => ({
    cache: resourceResolverCache,
  }),

  computed: {
    resolvedResource() {
      return this.cache.getResolvedValue(this.element.value);
    },

    isResolvableLocally() {
      const url = this.element.value;
      if (url.startsWith("http://") || url.startsWith("https://")) {
        return url.startsWith(config.appConfig.BASE_FHIR_URL);
      }

      // Just hope it's a valid string
      return true;
    },

    hasIssues(): boolean {
      return this.element.combinedErrors.some(
        (error) => error.severity === "error"
      );
    },
  },

  watch: {
    "element.value": {
      immediate: true,
      async handler(url: string) {
        await this.cache.resolve(url);
      },
    },
  },
});
