

















import Vue from "vue";

export default Vue.extend({
  data: () => ({
    showConfirmDialog: false,
  }),
  props: {
    color: { type: String, default: "error" },
    disabled: { type: Boolean },
    confirmTitle: { type: String, default: "Are you sure?" },
    actionText: { type: String, default: "Delete" },
    block: { type: Boolean },
  },
  watch: {},
  async mounted() {},
  computed: {},
  methods: {
    async confirmed() {
      this.$emit("confirmed");
      this.showConfirmDialog = false;
    },
  },
});
