import { ProfilePath, ProfilePathParser } from "@/lib-on-fhir";
import { defineStore } from "pinia";
import Vue from "vue";

export const usePathHighlightStore = defineStore("pathHighlightStore", {
  state: () => ({
    highlightedPath: null as null | ProfilePath,
    scrollToHighlightedPath: true,
  }),
  actions: {
    reset() {
      this.highlightedPath = null;
      this.scrollToHighlightedPath = true;
    },

    /**
     * Attempts to highlight the given path
     */
    requestPathHighlight(path: ProfilePath, scrollToHighlightedPath = true) {
      Vue.nextTick(() => {
        this.scrollToHighlightedPath = scrollToHighlightedPath;
        this.highlightedPath = path;
      });
    },

    /**
     * Consumes the given path highlight request, stopping any further expansion of elements
     */
    consumePathHighlight() {
      this.highlightedPath = null;
    },
  },
});
