



























































import {
  FilterComparator,
  FilterComparatorConfig,
  FILTER_COMPARATORS,
} from "@/state/filter-comparators";
import {
  ResourceFilterConfig,
  useResourceFilterStore,
} from "@/state/resourceFilterStore";
import Vue from "vue";
import { PropType } from "vue-demi";

export default Vue.extend({
  props: {
    filter: {
      type: Object as PropType<ResourceFilterConfig>,
    },
  },

  computed: {
    comparatorConfig(): FilterComparatorConfig {
      return FILTER_COMPARATORS[this.filter.parameter.type];
    },

    currentComparator(): FilterComparator {
      return this.comparatorConfig.comparators[this.filter.comparator];
    },

    pendingComparator(): FilterComparator {
      return this.comparatorConfig.comparators[this.filter.pendingComparator];
    },
  },

  methods: {
    show() {
      (this.$refs.dropdown as any).show();
    },

    hide() {
      (this.$refs.dropdown as any).hide();
    },

    apply() {
      useResourceFilterStore().applyFilter(this.filter);
      this.hide();
    },

    removeFilter() {
      console.log("remove");
      useResourceFilterStore().removeFilter(this.filter);
      this.hide();
    },
  },
});
