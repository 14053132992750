




















































































































































































































import Vue from "vue";
import AddItemButton from "@/components/explore/AddItemButton.vue";
import IssueList from "@/components/explore/IssueList.vue";
import ResourceRenderer from "@/components/explore/renderer/ResourceRenderer.vue";
import ReferencesList from "@/components/ReferencesList.vue";
import ProfileExplorerView from "@/lib-on-fhir/vue-example/profile-explorer/ProfileExplorerView.vue";
import ResourceExplorerView from "@/lib-on-fhir/vue-example/resource-explorer/ResourceExplorerView.vue";
import { confirmNavigationUnsavedChanges } from "@/router";
import { useResourceStore } from "@/state/resourceStore";
import { useAddableFields } from "@/views/Explore/addableFields.setup";
import { useJSONEditor } from "@/views/Explore/jsonEditor.setup";
import { useResource } from "@/views/Explore/resource.setup";
import { useViewOptions } from "@/views/Explore/viewOptions.setup";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";

export default Vue.extend({
  components: {
    ProfileExplorerView,
    ResourceExplorerView,
    AddItemButton,
    ResourceRenderer,
    IssueList,
    ReferencesList,
    PrismEditor,
  },
  props: {
    createMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      referencesActive: false,
      validationsActive: false,
      currentTab: "form",
    };
  },
  methods: {
    toggleReferences() {
      this.referencesActive = !this.referencesActive;
      if (this.referencesActive) {
        this.validationsActive = false;
      }
    },
    toggleValidations() {
      this.validationsActive = !this.validationsActive;
      if (this.validationsActive) {
        this.referencesActive = false;
      }
    },
  },
  watch: {
    currentTab() {
      this.referencesActive = false;
      this.validationsActive = false;
    }
  },
  setup(props, { root }) {
    return {
      ...useAddableFields(),
      ...useJSONEditor(),
      ...useViewOptions(root),
      ...useResource(props, root),
    };
  },

  beforeRouteLeave(to, from, next) {
    if (!useResourceStore().isDirty) {
      return next();
    }
    const answer = confirmNavigationUnsavedChanges();
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
});
