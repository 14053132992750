import {
    BaseDefinition,
    ElementDefinition,
    InvalidProfileError,
    isNullOrUndefined,
} from '@/lib-on-fhir';

/**
 * Given a pattern and an object, validates if object matches the pattern.
 */
export function validateSlicingPattern(pattern: any, object: any): boolean {
    if (isNullOrUndefined(pattern) || isNullOrUndefined(object)) {
        return false;
    }

    if (Array.isArray(pattern)) {
        return pattern.every((subPattern, index) =>
            validateSlicingPattern(subPattern, object[index])
        );
    }

    if (Array.isArray(object)) {
        // If the object is an array, but the pattern isn't, make sure we check it on EACH entry
        return object.every(subObject => validateSlicingPattern(pattern, subObject));
    }

    if (typeof pattern === 'object') {
        for (const key in pattern) {
            if (isNullOrUndefined(object[key])) {
                return false;
            }

            if (!validateSlicingPattern(pattern[key], object[key])) {
                return false;
            }
        }
        return true;
    }

    return pattern === object;
}

/**
 * Traverses a slicing path, that is, traversing the path, the
 * definition and the raw resource data to reach a point where the path has a length of 0, which
 * means we reached the target definition and value of the resource, returning those.
 *
 * @TODO: Handling of more complex fhir path expressions. Implementing as needed.
 */
export function traverseSlicePath(
    path: string[],
    resource: Record<string, any>,
    definition: BaseDefinition
): { resource: any; definition: BaseDefinition } | void {
    if (path.length === 0) {
        return { resource, definition };
    }

    if (!resource || !definition) {
        return;
    }

    const next = path[0];
    const remaining = path.slice(1);

    if (next === '$this') {
        // '$this' is instant
        return traverseSlicePath(remaining, resource, definition);
    }

    if (!(definition instanceof ElementDefinition)) {
        throw new InvalidProfileError('slice path invalid, results to non-element definition');
    }

    const childValue = resource[next];
    const childDefinition = definition.fieldTypes[next];

    if (!childDefinition) {
        throw new InvalidProfileError('slice path invalid, does not match profile definition');
    }

    if (!childValue) {
        return;
    }

    return traverseSlicePath(remaining, childValue, childDefinition);
}
