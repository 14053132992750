import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DatetimePicker from "vuetify-datetime-picker";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import VueCompositionAPI from "@vue/composition-api";
import { registerGlobalComponents } from "./registerGlobalComponents";
import PortalVue from "portal-vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import LogRocket from "logrocket";
import { warnIfMobile } from "./helpers/warnIfMobile";

warnIfMobile();

if (window.location.host.includes("demo")) {
  LogRocket.init("q53eoj/medvertical-demo");
}

Vue.use(VueToast);
Vue.use(DatetimePicker);
Vue.use(VueCompositionAPI);
Vue.use(PortalVue);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

registerGlobalComponents(Vue);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  pinia,
}).$mount("#app");
