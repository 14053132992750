




import Vue from "vue";
import { PropType } from "vue-demi";
import { ColumnConfig } from "@/config/config";

export default Vue.extend({
  props: {
    resource: Object,
    values: Array as PropType<string[]>,
    columnDefinition: Object as PropType<ColumnConfig>,
  },
});
