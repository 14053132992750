import { config } from "@/services/config";
import Config from "@/views/Config.vue";
import Explore from "@/views/Explore/Explore.vue";
import AllResources from "@/views/AllResources.vue";
import List from "@/views/List/List.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: "/explore",
  },
  {
    path: "/explore",
    name: "AllResources",
    component: AllResources,
    meta: {
      preventGoBack: true,
    },
  },
  {
    path: "/list/:resourceType",
    name: "List",
    component: List,
    meta: {
      preventGoBack: true,
    },
  },
  {
    path: "/explore/:resourceType/:id",
    name: "Explore",
    component: Explore,
  },
  {
    path: "/create/:resourceType",
    name: "Create",
    component: Explore,
    props: {
      createMode: true,
    },
  },
  {
    path: "/config",
    name: "Config",
    component: Config,
    meta: {
      preventGoBack: true,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// prevent router.back to exit the app
let originalBack = router.back;
router.back = function() {
  if (history.state) {
    originalBack.call(router);
  } else if (router.currentRoute.params.resourceType) {
    router.push(`/list/${router.currentRoute.params.resourceType}`);
  } else {
    router.push("/");
  }
};

function getFirstPrimaryResource() {
  return config.appConfig.primaryResources[0].baseResource;
}

export function confirmNavigationUnsavedChanges() {
  return window.confirm(
    "You have unsaved changes. Do you want to leave without saving?"
  );
}

export default router;
