
















import Vue from "vue";
import { SystemTypeWrapper } from "@/lib-on-fhir";

export default Vue.extend({
  props: {
    element: {
      type: SystemTypeWrapper,
    },
  },
});
