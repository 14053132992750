
























import ExplorerElementWrapper from '@/lib-on-fhir/vue-example/resource-explorer/ExplorerElementWrapper.vue';
import { useResourceStore } from '@/state/resourceStore';
import Vue from 'vue';

export default Vue.extend({
    components: { ExplorerElementWrapper },

    computed: {
        wrappedResource(): any {
            return useResourceStore().wrappedResource;
        },
    },
});
