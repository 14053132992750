

































































import { getSearchParametersForResource } from "@/services/helpers";
import {
  ResourceFilterConfig,
  useResourceFilterStore,
} from "@/state/resourceFilterStore";
import { useResourceListStore } from "@/state/resourceListStore";
import FilterEditor from "@/views/List/FilterEditor.vue";
import FHIR from "fhir/r4";
import Vue from "vue";

export default Vue.extend({
  components: {
    FilterEditor,
  },

  data: () => ({
    currentTab: 0,
  }),

  computed: {
    availableSortableFields(): FHIR.SearchParameter[] {
      return getSearchParametersForResource(
        useResourceListStore().resourceType
      );
    },

    store() {
      return useResourceFilterStore();
    },
  },

  methods: {
    shouldShowSortableField(field: FHIR.SearchParameter) {
      switch (this.currentTab) {
        case 0: {
          // resource
          return !field.code.startsWith("_");
        }
        case 1: {
          // meta
          return field.code.startsWith("_");
        }
        default: {
          return false;
        }
      }
    },

    getFilterDropdownHandle(
      filter: ResourceFilterConfig
    ): { show: () => void } {
      return (this.$refs[
        "filter-" + String(this.store.filters.indexOf(filter))
      ] as any)?.[0];
    },

    addFilter(parameter: FHIR.SearchParameter) {
      const filter = useResourceFilterStore().addFilter(parameter);
      (this.$refs.addDropdown as any).hide();
      Vue.nextTick(() => {
        this.getFilterDropdownHandle(filter).show();
      });
    },
  },
});
