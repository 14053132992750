import {
    BaseDefinition,
    BaseWrapper,
    DefaultFieldsDownstreamPayload,
    InvalidProfileError,
    InvalidResourceError,
    isNullOrUndefined,
    SystemTypeWrapper,
} from '@/lib-on-fhir';

/**
 * A system type is the most basic value fhir can store, e.g. a raw string.
 * Every primitive types value must be a valid system type
 */
export abstract class SystemTypeDefinition extends BaseDefinition {
    abstract getDefaultValue(): any;

    toString() {
        return 'raw';
    }

    copyShallowFrom() {
        throw this.logger.scopedException(InvalidProfileError, 'Can not shallow clone system type');
    }

    addDefaultFieldOnObject(
        target: any,
        key: string | number,
        payload: DefaultFieldsDownstreamPayload
    ) {
        // Choose value based on priority system
        let value =
            this.fixedValue ||
            this.pattern ||
            this.defaultValue ||
            payload.fixedValue ||
            payload.pattern ||
            payload.defaultValue ||
            this.getDefaultValue();

        if (Array.isArray(target[key])) {
            if (target[key].length > 0) {
                throw this.logger.scopedException(
                    InvalidResourceError,
                    'System type can not be added to array with length > 0'
                );
            }
            target[key].push(value);
        } else if (!isNullOrUndefined(target[key])) {
            throw this.logger.scopedException(
                InvalidResourceError,
                'System type can not be added twice'
            );
        } else {
            target[key] = value;
        }
    }

    wrapResourceWithinArray(): BaseWrapper | undefined {
        throw this.logger.scopedException(
            InvalidProfileError,
            'System type can not be wrapped as resource'
        );
    }

    wrapResourceWithinObject(resource: any, key: string): BaseWrapper | undefined {
        if (this.cardinality.max > 1) {
            throw this.logger.scopedException(
                InvalidProfileError,
                'System type MUST have cardinality of max <= 1'
            );
        }

        const value = resource[key];
        if (isNullOrUndefined(value)) {
            return;
        }

        const result = new SystemTypeWrapper();
        result._ref = {
            resource: resource,
            key: key,
        };
        result.$type = this;
        return result;
    }
}

export class SystemTypeString extends SystemTypeDefinition {
    getDefaultValue() {
        return '';
    }
}
export class SystemTypeInteger extends SystemTypeDefinition {
    getDefaultValue() {
        return 0;
    }
}
export class SystemTypeDateTime extends SystemTypeDefinition {
    getDefaultValue() {
        return new Date().toISOString();
    }
}
export class SystemTypeDate extends SystemTypeDefinition {
    getDefaultValue() {
        return new Date().toISOString().split('T')[0];
    }
}
export class SystemTypeBoolean extends SystemTypeDefinition {
    getDefaultValue() {
        return false;
    }
}
export class SystemTypeDecimal extends SystemTypeDefinition {
    getDefaultValue() {
        return 0;
    }
}
export class SystemTypeTime extends SystemTypeDefinition {
    getDefaultValue() {
        return new Date().toTimeString().split(' ')[0];
    }
}

export const SystemTypeMappings: Record<string, new () => SystemTypeDefinition> = {
    'http://hl7.org/fhirpath/System.String': SystemTypeString,
    'http://hl7.org/fhirpath/System.Integer': SystemTypeInteger,
    'http://hl7.org/fhirpath/System.DateTime': SystemTypeDateTime,
    'http://hl7.org/fhirpath/System.Date': SystemTypeDate,
    'http://hl7.org/fhirpath/System.Boolean': SystemTypeBoolean,
    'http://hl7.org/fhirpath/System.Decimal': SystemTypeDecimal,
    'http://hl7.org/fhirpath/System.Time': SystemTypeTime,
};
