


















































































































import { ProfilePathParser } from "@/lib-on-fhir";
import { GenericResourceError } from "@/state/resourceIssueStore";
import Vue, { PropType } from "vue";

const severities = {
  fatal: 3,
  error: 3,
  warning: 2,
  info: 1,
  debug: 0,
};

export default Vue.extend({
  props: {
    errors: {
      type: Array as PropType<GenericResourceError[]>,
    },

    stackSize: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    ProfilePathParser,
  }),

  computed: {
    highestSeverity(): number {
      return Math.max(
        0,
        ...this.errors?.map((error) =>
          error.outdated ? 0 : severities[error.severity]
        )
      );
    },
  },
});
