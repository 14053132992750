/**
 * Allows logging, and also carries a scope so we can log and throw within a
 * profile while being path-aware, and thus adding a call stack
 */
export class ContextualLogger {
    scope: string;
    depth: number;

    constructor(scope: string = '', depth = 0) {
        this.scope = scope;
        this.depth = depth;
    }

    child(subScope: string) {
        return new ContextualLogger(this.scope + ' > ' + subScope, this.depth + 1);
    }

    spam(...args: any[]) {
        console.debug(this.scope.padEnd(40, ' '), ' ', ...args);
    }

    debug(...args: any[]) {
        console.debug(this.scope.padEnd(40, ' '), ' ', ...args);
    }

    log(...args: any[]) {
        console.log(this.scope.padEnd(40, ' '), ' ', ...args);
    }

    warn(...args: any[]) {
        console.warn(this.scope.padEnd(40, ' '), ' ', ...args);
    }

    scopedException(errorType: new (...args: any[]) => Error, message: string): Error {
        throw new errorType(message + ' at ' + this.scope);
    }
}
