






































































































































































































































import Vue from "vue";

export default Vue.extend({
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    fieldId: {
      type: String,
      default: "",
    },
    renderAsBox: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    indent(): string {
      return Math.max(this.depth - 1, 0) * 25 + "px";
    },
  },
});
