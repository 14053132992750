import { config } from "@/services/config";
import { useResourceStore } from "@/state/resourceStore";
import { computed, ref } from "@vue/composition-api";

// View configuration like hiding meta fields
export const useViewOptions = (root: Vue) => {
  const resourceStore = useResourceStore();

  const toggleMetaFields = () => {
    resourceStore.setShowMetaFields(!resourceStore.showMetaFields);
  };

  // todo
  const showMetaFields = computed(() => resourceStore.showMetaFields);

  const currentTab = ref("form");

  const showDebugOptions = computed(
    () =>
      config.showDebug ||
      currentTab.value === "lib" ||
      currentTab.value === "lib-resource"
  );

  const sideBarContent = ref<"none" | "validations" | "references">(
    "validations"
  );
  const showReferences = () => {
    if (sideBarContent.value === "references") {
      sideBarContent.value = "none";
    } else {
      sideBarContent.value = "references";
    }
  };
  const showValidations = () => {
    if (sideBarContent.value === "validations") {
      sideBarContent.value = "none";
    } else {
      sideBarContent.value = "validations";
    }
  };
  const referencesActive = computed(
    () => sideBarContent.value === "references"
  );
  const validationsActive = computed(
    () => sideBarContent.value === "validations"
  );
  const sideBarActive = computed(() => sideBarContent.value !== "none");

  const goBack = () => {
    root.$router.push({
      name: "List",
      params: {
        resourceType: resourceStore.resourceType,
      },
    });
  };

  const readonly = computed(() => config.appConfig.readOnlyMode);

  return {
    showDebugOptions,
    currentTab,
    showMetaFields,
    toggleMetaFields,
    showReferences,
    showValidations,
    // sidebar
    sideBarActive,
    referencesActive,
    validationsActive,

    goBack,
    readonly,
  };
};
