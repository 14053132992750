import { demoConfig } from "@/config/demo.config";
import { devConfig } from "@/config/dev.config";

const LOCALSTORAGE_CONFIG_KEY = "records_config";

enum ENV {
  DEMO = "DEMO",
  DEV = "DEV",
}

const envConfigMap = {
  [ENV.DEMO]: demoConfig,
  [ENV.DEV]: devConfig,
};

function getEnv() {
  let host = location.host;

  if (
    host.endsWith("demo.medvertical.com") ||
    host.includes("localhost-demo")
  ) {
    return ENV.DEMO;
  }

  return ENV.DEV;
}

const environment = getEnv();
let appConfigInternal = envConfigMap[environment] as typeof demoConfig;

console.log("env", environment);

let localConfigOverride = false;
try {
  appConfigInternal = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_CONFIG_KEY) || "-"
  );
  console.warn("using localstorage config override");
  localConfigOverride = true;
} catch (e) {
  // ignore
}

export const env = {
  localConfigOverride,
  environment,
  isDev: location.hostname === "localhost",
};

export let config = { appConfig: appConfigInternal, showDebug: false };

export function setConfig(newConfig: any) {
  config.appConfig.primaryResources = newConfig.primaryResources;
}
export function resetLocalstorage() {
  localStorage.removeItem(LOCALSTORAGE_CONFIG_KEY);
}
export function saveLocalstorage() {
  localStorage.setItem(
    LOCALSTORAGE_CONFIG_KEY,
    JSON.stringify(config.appConfig)
  );
}
export function updateLocalConfigOverrideStatus() {
  env.localConfigOverride = true;
  return env;
}