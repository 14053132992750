import router from "@/router";
import { config } from "@/services/config";
import { fhirApi } from "@/services/data";
import { getResourceTitle } from "@/services/helpers";
import { notifyError, notifySuccess } from "@/services/notify";
import {
  IndirectReferencesResult,
  resolveIndirectReferences,
} from "@/services/resolvers/IndirectReferences";
import { resourceResolver } from "@/services/resolvers/ResourceResolver";
import { eventBus } from "@/state/eventBus";
import { usePathHighlightStore } from "@/state/pathHighlightStore";
import { useProfileStore } from "@/state/profileStore";
import { useResourceIssueStore } from "@/state/resourceIssueStore";
import { useResourceStore } from "@/state/resourceStore";
import Vue from "vue";
import { computed, watch, onBeforeUnmount, onMounted, ref } from "vue-demi";
import _ from "lodash";
import { ElementWrapper } from "@/lib-on-fhir";
import { profileResolver } from "@/services/resolvers/ProfileResolver";
import { valueSetResolver } from "@/services/resolvers/ValueSetResolver";
import { resourceResolverCache } from "@/state/resourceListStore";

// Resource stuff
export const useResource = (props: any, root: Vue) => {
  const loading = ref(false);
  const resourceStore = useResourceStore();
  const resourceIssueStore = useResourceIssueStore();

  let indirectReferencesResults = ref([] as IndirectReferencesResult[]);
  let indirectReferencesLoadingCount = ref(0);
  let indirectReferencesCount = computed(() => {
    return _.sumBy(indirectReferencesResults.value, (entry) => entry.total);
  });
  let indirectReferencesLoading = computed(
    () => indirectReferencesLoadingCount.value > 0
  );

  const reset = () => {
    resourceIssueStore.reset();
    usePathHighlightStore().reset();
    useResourceStore().reset();

    indirectReferencesResults.value = [];
    indirectReferencesLoadingCount.value = 0;
  };
  const resource = computed(() => {
    return resourceStore.resource;
  });
  const resourceUrl = computed(() => {
    return `${root.$route.params.resourceType}/${root.$route.params.id}`;
  });
  const resourceType = computed(() => {
    return root.$route.params.resourceType;
  });
  const resourceTitle = computed(() => {
    if (props.createMode) {
      return `New ${resourceConfig.value?.singularName || resourceType.value}`;
    }

    if (!resource.value) {
      return "";
    }
    return getResourceTitle(resource.value);
  });
  const profileCanonical = computed(() => {
    return resourceStore.getResourceProfileCanonical();
  });
  const resourceConfig = computed(() => {
    return config.appConfig.primaryResources.find(
      (entry) => entry.baseResource === resourceType.value
    );
  });
  const resourceIsDirty = computed(() => {
    return resourceStore.isDirty;
  });
  const wrappedResource = computed(() => {
    return resourceStore.wrappedResource;
  });

  const resourceAsJson = computed(() =>
    JSON.stringify(resource.value, null, 2)
  );

  const loadResource = async () => {
    if (!resourceUrl.value) {
      return;
    }
    let resource;
    try {
      resource = await resourceResolver.getFhirResource(resourceUrl.value);
    } catch (e) {
      notifyError("Error loading resource: " + (e as any));
      return;
    }
    return resource;
  };

  const saveResource = async () => {
    let editedResource = resource.value;
    try {
      let responseResource = await fhirApi.putFhirResource(
        resourceUrl.value,
        editedResource
      );
      resourceStore.setResource(responseResource);
      notifySuccess("The resource was successfully saved.");
    } catch (e) {
      notifyError("Error saving resource: " + (e as any));
    }
  };

  const createResource = async () => {
    try {
      let createdResource = await fhirApi.postFhirResource(
        resourceType.value,
        resource.value
      );
      resourceStore.setResource(createdResource);
      router.replace({
        name: "Explore",
        params: {
          resourceType: resourceType.value,
          id: createdResource.id,
        },
      });
      notifySuccess("The resource was successfully created.");
      eventBus.$emit("resourceCountChanged", resourceType.value);
    } catch (e) {
      notifyError("Error creating resource: " + (e as any).message);
    }
  };

  const deleteResource = async () => {
    try {
      let response = await fhirApi.deleteFhirResource(resourceUrl.value);
      console.log("delete response", response);
      notifySuccess("The resource was successfully deleted.");
      eventBus.$emit("resourceCountChanged", resourceType.value);
      router.back();
    } catch (e) {
      notifyError("Error deleting resource: " + (e as any));
    }
  };

  const initResource = async () => {
    loading.value = true;
    let resource: any;
    if (!props.createMode) {
      resource = await loadResource();
    } else {
      resource = {
        resourceType: resourceType.value,
        id: "creation-id",
        meta: {
          profile: resourceConfig.value?.profiles || [],
        },
      };
    }
    resourceStore.setResource(resource);
    await useProfileStore().initProfile(
      profileCanonical.value,
      resourceType.value
    );
    resourceStore.wrapResource();

    if (props.createMode) {
      (resourceStore.wrappedResource as ElementWrapper)?.addMissingRequiredFields();
      resourceStore.markCurrentResourceAsOriginal();
    }

    loading.value = false;

    validateResource();
    loadIndirectReferences(resource);
  };

  const loadIndirectReferences = async (resource: any) => {
    // store reference to current indirectReferencesResults for this resource since it changes when another resource is loaded
    let currentIndirectReferencesResults = indirectReferencesResults.value;

    config.appConfig.primaryResources.forEach(async (appResource) => {
      indirectReferencesLoadingCount.value++;

      try {
        let result = await resolveIndirectReferences({
          resourceUrl: `${resource.resourceType}/${resource.id}`,
          resourceProfileUrl:
            "http://hl7.org/fhir/StructureDefinition/" + resource.resourceType,
          referenceProfileUrl: appResource.baseResource,
        });
        if (result) {
          currentIndirectReferencesResults.push(result);
        }
      } catch (e) {
        console.error("Error resolving indirect references", e);
      } finally {
        indirectReferencesLoadingCount.value--;
      }
    });
  };

  const loadingOperations = computed(
    () =>
      profileResolver.ongoingRequests +
      valueSetResolver.ongoingRequests +
      resourceResolverCache.ongoingRequests
  );

  watch(resourceUrl, () => {
    reset();
    initResource();
  });

  // Validation
  const validateResource = async (notify = false) => {
    await resourceIssueStore.validateResource(
      useResourceStore().resource,
      notify
    );
  };

  onMounted(() => {
    initResource();
  });

  onBeforeUnmount(() => {
    reset();
  });

  const totalErrorCount = computed(
    () => resourceIssueStore.errorCount + resourceStore.localIssuesErrorCount
  );
  const totalWarningCount = computed(
    () =>
      resourceIssueStore.warningCount + resourceStore.localIssuesWarningCount
  );

  return {
    resource,
    wrappedResource,
    resourceAsJson,

    resourceConfig,
    resourceTitle,
    resourceIsDirty,

    profileCanonical,

    validateResource,
    saveResource,
    createResource,
    deleteResource,

    indirectReferencesResults,
    indirectReferencesCount,
    indirectReferencesLoading,

    totalErrorCount,
    totalWarningCount,

    loadingOperations,
  };
};
