import {
  CompatProfileResolver,
  Profile,
  ProfilePrefetcher,
} from "@/lib-on-fhir";
import { useResourceStore } from "@/state/resourceStore";
import { defineStore } from "pinia";
import FHIR from "fhir/r4";

export const useProfileStore = defineStore("profileStore", {
  state: () => {
    const resolver = new CompatProfileResolver();
    const prefetcher = new ProfilePrefetcher(resolver, () =>
      useResourceStore().patchResource()
    );

    return {
      resolver,
      prefetcher,
      profiles: {} as Record<string, Profile>,
      profilePromises: {} as Record<string, Promise<Profile>>,
    };
  },
  actions: {
    async initProfile(canonical: string, fallbackProfile: string) {
      if (!canonical) {
        console.log("profiles: no resource type");
        return;
      }

      console.log("profiles: initProfile:", canonical);
      if (typeof this.profilePromises[canonical] !== "undefined") {
        console.log("profiles: already stored or fetching");
        return this.profilePromises[canonical];
      }

      return (this.profilePromises[canonical] = (async () => {
        console.log("profiles: resolving", canonical);
        let definition: FHIR.StructureDefinition;
        console.log("profiles: prefetching", canonical);

        try {
          definition = await this.resolver.resolve(canonical);
        } catch (ex) {
          console.warn(
            "Failed to resolve",
            canonical,
            "-> fallback to",
            fallbackProfile
          );
          definition = await this.resolver.resolve(fallbackProfile);
        }

        await this.prefetcher.prefetch(definition);
        const profile = new Profile({
          prefetcher: this.prefetcher as ProfilePrefetcher,
        });
        console.log("profiles: loading", canonical);
        await profile.loader.loadProfile(definition);
        this.profiles[canonical] = profile;
        console.log("profiles: loaded", canonical);
        return profile;
      })());
    },
  },
});
