

















import { config } from "@/services/config";
import Vue from "vue";

let versions = {};

export default Vue.extend({
  data: () => ({
    versions: versions,
  }),
  computed: {
    valueSetCount(): number {
      return 0;
    },
    showDebug(): boolean {
      return config.showDebug;
    },
  },
});
