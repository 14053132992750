// FROM https://github.com/tibetty/PromiseScheduler/blob/master/promise-scheduler.js

export class PromiseScheduler {
    _stale = false;
    _queue = [] as any[];
    _concurrencyCount = 0;
    _maxConcurrency: number;

    constructor(maxConcurrency = 10) {
        this._maxConcurrency = maxConcurrency;
    }

    schedule<T>(promFunc: () => Promise<T>) {
        return new Promise((thenCb, catchCb) => {
            // assert(typeof promFunc === 'function');	// for debug purpose only
            if (typeof promFunc !== 'function') {
                throw new Error('Not a function');
            }

            if (this._stale) {
                return;
            }

            let self = this;

            function schedule() {
                if (self._stale) {
                    return;
                }

                self._concurrencyCount--;
                if (self._queue.length > 0 && self._concurrencyCount < self._maxConcurrency) {
                    self._concurrencyCount++; // markup first, and then issue
                    let promiseTuple = self._queue.shift();
                    let promise = promiseTuple.promFunc.apply(null, promiseTuple.params);
                    promise
                        .then((value: any) => {
                            if (self._stale) {
                                return;
                            }

                            if (promiseTuple.thenCb && typeof promiseTuple.thenCb === 'function') {
                                promiseTuple.thenCb.call(null, value);
                            }
                            schedule();
                        })
                        .catch((err: any) => {
                            if (self._stale) {
                                return;
                            }

                            if (
                                promiseTuple.catchCb &&
                                typeof promiseTuple.catchCb === 'function'
                            ) {
                                promiseTuple.catchCb.call(null, err);
                            }
                            schedule();
                        });
                }
            }

            if (this._stale) {
                return;
            }

            if (this._concurrencyCount < this._maxConcurrency) {
                this._concurrencyCount++; // markup first, and then issue
                let promise = promFunc();
                promise
                    .then(value => {
                        if (this._stale) {
                            return;
                        }
                        if (thenCb && typeof thenCb === 'function') {
                            thenCb.call(null, value);
                        }
                        schedule();
                    })
                    .catch(err => {
                        if (this._stale) {
                            return;
                        }
                        if (catchCb && typeof catchCb === 'function') {
                            catchCb.call(null, err);
                        }
                        schedule();
                    });
            } else
                this._queue.push({
                    promFunc: promFunc,
                    thenCb: thenCb,
                    catchCb: catchCb,
                });
        });
    }
}
