import {
    BaseWrapper,
    capitalizeString,
    ChoiceDefinition,
    DownstreamPassPayload,
    ElementDefinition,
    InvalidProfileError,
    isNullOrUndefined,
    WrapperPatchOptions,
} from '@/lib-on-fhir';

/**
 * Wrapper for a choice type, that is, a type which is allowed to have multiple
 * potential ElementDefinitions (although just one value)
 */
export class ChoiceWrapper extends BaseWrapper<ChoiceDefinition> {
    currentType?: ElementDefinition;
    value?: BaseWrapper;

    deleteValue() {
        if (!isNullOrUndefined(this._ref.index)) {
            throw this.logger.scopedException(
                InvalidProfileError,
                `Can not delete from non-array with index (yet)`
            );
        }

        for (const type of this.$type.allowedTypes) {
            const key = this._ref.key + capitalizeString(type.typeName);
            this._ref.resource[key] = undefined;
        }
        this._changeHandler();
    }

    _computeLocalErrors() {
        let combined = [];
        if (this.value) {
            this.value._computeLocalErrors();
            combined.push(...this.value.combinedErrors);
        }

        this.localErrors = [];
        // own errors, @todo

        this.combinedErrors = [...this.localErrors, ...combined];
    }

    /**
     * Changes the type of this choice type. The new type SHALL be included in the list
     * of allowed types. Since a choice type has a cardinality of max = 1, only one type
     * can be choosen at a time, so changing the type clears the current value.
     *
     * @NOTICE At some point we could look into converting the old value so it's not lost
     */
    changeType(newType: ElementDefinition) {
        if (!this.$type.allowedTypes.includes(newType)) {
            throw this.logger.scopedException(Error, `changeType(): Must be one of allowed types`);
        }

        const { resource, key } = this._resolveRef();

        // remove old type first
        if (this.currentType) {
            const oldKey = key + capitalizeString(this.currentType!.typeName);
            delete resource[oldKey];
        }

        // then add new type
        const newKey = key + capitalizeString(newType.typeName);
        newType.addDefaultFieldOnObject(resource, newKey, {
            pattern: this.pattern,
            fixedValue: this.fixedValue,
            defaultValue: this.defaultValue,
        });

        this._changeHandler();
    }

    _propagateDownstream(payload: DownstreamPassPayload) {
        super._propagateDownstream(payload);

        if (this.value) {
            // @todo: Not sure about this
            this.value._propagateDownstream(payload);
        }
    }

    patch(other: BaseWrapper, options: WrapperPatchOptions) {
        super.patch(other, options);
        if (!(other instanceof ChoiceWrapper)) {
            throw this.logger.scopedException(Error, 'patch(): Type mismatch');
        }

        options.$patch(other, 'currentType', this.currentType);

        if (!other.value || !this.value) {
            options.$patch(other, 'value', this.value);
        } else {
            this.value.patch(other.value, options);
        }
    }
}
