import { PromiseScheduler } from '@/lib-on-fhir';
import lZstring from 'lz-string';
import * as IndexedDBStore from 'idb-keyval';

type CacheResolver<P extends any[], R> = (...args: P) => Promise<R>;

/**
 * Generic cache using 2 layers, in memory and a user specified one
 */
abstract class ResolverCache<P extends any[], R> {
    id: string;
    resolver: CacheResolver<P, R>;
    keyGenerator: (...obj: P) => string;
    localCache = {} as Record<string, { promise: Promise<R>; resolved?: R }>;
    scheduler: PromiseScheduler;

    setter: (obj: any, key: string, value: any) => void;
    initializationPromise: undefined | Promise<void>;

    constructor({
        id,
        resolver,
        keyGenerator = (...args) => (args.length === 1 ? args : JSON.stringify(args)),
        setter = (obj, key, value) => (obj[key] = value),
        maxConcurrency = 10,
    }: {
        id: string;
        resolver: CacheResolver<P, R>;
        keyGenerator?: ResolverCache<any, any>['keyGenerator'];
        setter?: ResolverCache<any, any>['setter'];
        maxConcurrency?: number;
    }) {
        this.id = id;
        this.resolver = resolver;
        this.keyGenerator = keyGenerator;
        this.scheduler = new PromiseScheduler(maxConcurrency);
        this.setter = setter;
    }

    /**
     * Returns the amount of currently ongoing requests
     */
    get ongoingRequests() {
        return this.scheduler._queue.length + this.scheduler._concurrencyCount;
    }

    /**
     * Resolves the given payload, checking both the in memory cache and
     * derived cache strategy
     */
    async resolve(...args: P): Promise<R> {
        const key = this.keyGenerator(...args);

        // Initialization
        if (!this.initializationPromise) {
            this.initializationPromise = this.init();
        }
        await this.initializationPromise;

        // In memory caching
        if (this.localCache[key]) {
            const handle = this.localCache[key]!;
            await handle.promise;
            return handle.resolved!;
        }

        return this.scheduler.schedule<R>(async () => {
            const currentValue = await this.readCacheValue(key);

            // Check if cache entry exists
            if (typeof currentValue === 'undefined') {
                let promise = this.resolver(...args);

                // Add to our in memory cache, but make it a promise so others can
                // also wait for it
                const handle: { promise: Promise<R>; resolved?: R } = { promise };
                this.setter(this.localCache, key, handle);

                // Wait for the result and store the resolved value
                let resolved;
                try {
                    resolved = await promise;
                    this.setter(handle, 'resolved', resolved);
                } catch (ex) {
                    this.setter(this.localCache, key, undefined);
                    throw ex;
                }
                await this.storeCacheValue(key, resolved);
                return resolved as R;
            } else {
                return currentValue as R;
            }
        }) as Promise<R>;
    }

    /**
     * Resets all cache requests
     */
    clearOngoingRequests() {
        this.scheduler._stale = true;
        this.scheduler = new PromiseScheduler(this.scheduler._maxConcurrency);
    }

    /**
     * Returns the cached value, useful if the operation needs to be sync.
     * Returns undefined if the value has not been cached yet
     */
    getResolvedValue(...args: P): R | undefined {
        const key = this.keyGenerator(...args);
        return this.localCache[key]?.resolved;
    }

    /**
     * Can be overriden to reset the cache
     */
    async resetCache() {
        this.clearOngoingRequests();
        this.localCache = {};
    }

    // Subclass interface
    protected async init(): Promise<void> {}
    protected abstract readCacheValue(key: string): Promise<R | undefined>;
    protected abstract storeCacheValue(key: string, value: R): Promise<void>;
}

/**
 * In memory caching
 */
export class InMemoryCache<P extends any[], R> extends ResolverCache<P, R> {
    async readCacheValue(key: string): Promise<R | undefined> {
        return undefined;
    }

    async storeCacheValue(key: string, value: R): Promise<void> {}
}

/**
 * Cache utilizing local storage
 */
export class LocalStorageCache<P extends any[], R> extends ResolverCache<P, R> {
    async readCacheValue(key: string): Promise<R | undefined> {
        const rawValue = localStorage.getItem(this.id + '-' + key);
        return JSON.parse(rawValue || 'null') || undefined;
    }

    async storeCacheValue(key: string, value: R): Promise<void> {
        localStorage.setItem(this.id + '-' + key, JSON.stringify(value));
    }
}

/**
 * Cache utilizing local storage, compressing data to avoid surpassing
 * local storage limits
 */
export class CompressedLocalStorageCache<P extends any[], R> extends ResolverCache<P, R> {
    async readCacheValue(key: string): Promise<R | undefined> {
        const rawValue = localStorage.getItem(this.id + '.compressed-' + key);
        if (typeof rawValue !== 'string') {
            return;
        }
        const uncompressed = lZstring.decompressFromUTF16(rawValue);
        if (!uncompressed) {
            console.warn('Failed to decompress compressed profile');
            return;
        }
        return JSON.parse(uncompressed) || undefined;
    }

    async storeCacheValue(key: string, value: R): Promise<void> {
        const json = JSON.stringify(value);
        const compressed = lZstring.compressToUTF16(json);
        localStorage.setItem(this.id + '.compressed-' + key, compressed);
    }
}

/**
 * Cache utilizing indexed db
 */
export class IndexedDBCache<P extends any[], R> extends ResolverCache<P, R> {
    private store!: IndexedDBStore.UseStore;

    async init() {
        this.store = IndexedDBStore.createStore(this.id, 'cache');
    }

    async readCacheValue(key: string): Promise<R | undefined> {
        const value = await IndexedDBStore.get(key, this.store);
        if (typeof value === 'undefined') {
            return undefined;
        }
        return value;
    }

    async storeCacheValue(key: string, value: R): Promise<void> {
        await IndexedDBStore.set(key, value, this.store);
    }

    async resetCache() {
        super.resetCache();
        await IndexedDBStore.clear(this.store);
    }
}

/**
 * Cache using indexed db if available, otherwise falling back to local storage
 */
export const PolyfilledIndexedDBCache = window.indexedDB
    ? IndexedDBCache
    : CompressedLocalStorageCache;
