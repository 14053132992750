import { LocalStorageCache } from "@/lib-on-fhir";
import { config } from "@/services/config";
import { fhirApi } from "@/services/data";
import FHIR from "fhir/r4";
import { defineStore } from "pinia";
import rusha from "rusha";
import Vue from "vue";

export const validationCache = new LocalStorageCache({
  maxConcurrency: 5,
  id: "resourceValidationCache",

  // Generate keys based on the sha256 hash
  keyGenerator: (resource: FHIR.Resource) => {
    return (
      config.appConfig.BASE_FHIR_URL +
      "/" +
      rusha
        .createHash()
        .update(JSON.stringify(resource))
        .digest("hex")
    );
  },

  // Resolve using the configured fhir server
  resolver: async (resource: FHIR.Resource) => {
    const response = (await fhirApi.validateResource(
      resource
    )) as FHIR.OperationOutcome;

    const errors =
      response.issue?.filter((issue) => issue.severity === "error").length || 0;
    const warnings =
      response.issue?.filter((issue) => issue.severity === "error").length || 0;

    return { errors, warnings };
  },
});

// Make it reactive
new Vue({
  data: validationCache,
});

export const useResourceValidationStore = defineStore("resourceValidaion", {
  state: () => ({}),
  actions: {
    /**
     * Validates a given resource, caching it by the sha256 hash
     */
    async validate(resource: FHIR.Resource) {
      return await validationCache.resolve(resource);
    },

    /**
     * Clears all ongoing validation requests, e.g. for views which are no longer active
     */
    clearValidationRequests() {
      console.warn("validate:: clearing requests");
      validationCache.clearOngoingRequests();
    },
  },
});
