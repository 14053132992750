import {
    ArrayWrapper,
    BaseWrapper,
    DefaultFieldsDownstreamPayload,
    ElementDefinition,
    isNullOrUndefined,
    PrimitiveJSONProxy,
    PrimitiveWrapper,
    ProfileNotSupportedError,
    WrapperPatchOptions,
} from '@/lib-on-fhir';

/**
 * Base definition for all primitive types
 */
export class PrimitiveDefinition extends ElementDefinition {
    addDefaultFieldOnObject(
        target: any,
        key: string | number,
        payload: DefaultFieldsDownstreamPayload
    ) {
        payload.pattern = this.pattern || payload.pattern;
        payload.fixedValue = this.fixedValue || payload.fixedValue;
        payload.defaultValue = this.defaultValue || payload.defaultValue;

        let result: Record<string, any> = {};
        for (const [fieldId, fieldDefinition] of Object.entries(this.fieldTypes)) {
            if (fieldId === 'value') {
                // Value field inherits pattern
                fieldDefinition.addDefaultFieldOnObject(result, fieldId, {
                    pattern: payload.pattern,
                    fixedValue: payload.fixedValue,
                    defaultValue: payload.defaultValue,
                });
            } else if (
                // All other fields don't inherit anything
                fieldDefinition.cardinality.min > 0 ||
                fieldDefinition.fixedValue ||
                fieldDefinition.defaultValue ||
                fieldDefinition.pattern
            ) {
                fieldDefinition.addDefaultFieldOnObject(result, fieldId, {});
            }
        }

        const resultValue = result.value;

        if (Array.isArray(target[key])) {
            target[key].push(resultValue);
        } else if (!isNullOrUndefined(target[key])) {
            target[key] = [target[key], resultValue];
        } else {
            target[key] = resultValue;
        }
    }

    wrapResourceWithinArray(resource: any, key: string, index: number): BaseWrapper | undefined {
        const proxy = new PrimitiveJSONProxy({
            parent: resource,
            key,
            index,
        });

        const result = new PrimitiveWrapper();
        result.fields = {};
        result.$type = this;
        result._ref = {
            resource: {
                $proxy: proxy,
                $patch(other: any, options: WrapperPatchOptions) {
                    if (!(other.$proxy instanceof PrimitiveJSONProxy)) {
                        throw new Error('patch(): Bad proxy type');
                    }
                    proxy.$patch(other.$proxy, options);
                },
            },
            key: '$proxy',
        };

        for (const [fieldId, field] of Object.entries(this.fieldTypes)) {
            const value = field.wrapResourceWithinObject(proxy, fieldId);
            if (!isNullOrUndefined(value)) {
                result.fields[fieldId] = value!;
            }
        }
        return result;
    }

    wrapResourceWithinObject(resource: any, key: string): BaseWrapper | undefined {
        let value: any[] = resource[key];
        if (isNullOrUndefined(value)) {
            return;
        }

        if (!Array.isArray(value)) {
            value = [value];
        }

        if (Object.keys(this.slicingFieldTypes).length > 0) {
            throw this.logger.scopedException(
                ProfileNotSupportedError,
                `slicing on primitives not yet supported`
            );
        }

        const arrayWrapper = new ArrayWrapper();
        arrayWrapper.$type = this;
        arrayWrapper._ref = {
            resource: resource,
            key: key,
        };
        arrayWrapper.items = value.map((subValue: any, index) => {
            if (isNullOrUndefined(subValue)) {
                this.logger.warn('Empty sub value on primitive', subValue, value);
                return null;
            }
            return this.wrapResourceWithinArray(resource, key, index);
        }) as BaseWrapper[];
        return arrayWrapper;
    }

    toString(): string {
        return `<${this.typeName}>`;
    }
}
