






















































































































































































































import Vue from "vue";
import { config, env } from "@/services/config";
import { resourceResolver } from "@/services/resolvers/ResourceResolver";
import Footer from "@/components/Footer.vue";
import router from "@/router";
import { formatLargeNumber } from "@/lib-on-fhir/utils";
import { eventBus } from "@/state/eventBus";
import { resourceCategories } from "@/data/resourceCategories"; // Import resourceCategories

interface ResourceTypeCount {
  [resource: string]: number;
}

interface PrimaryResource {
  name: string;
  baseResource: string;
  icon: string;
}

interface ResourceCategory {
  displayName: string;
  level: number;
  resources: { name: string; icon: string }[];
}

export default Vue.extend({
  components: {
    Footer,
  },
  data() {
    return {
      appConfig: config.appConfig,
      env: env,
      config: config,
      user: {
        firstName: "Testina",
        lastName: "Test",
      },
      resourceTypeCounts: {} as ResourceTypeCount,
      buildInfoString: `${process.env.VUE_APP_BRANCH || "dev"}/${(
        process.env.VUE_APP_COMMIT_REF || "dev"
      ).substring(0, 8)}`,
    };
  },
  async mounted() {
    // load all resource counts
    this.loadAllResourcePrimaryCounts();

    eventBus.$on("resourceCountChanged", async (resource: string) => {
      // update all resources not just the one that changed
      this.loadAllResourcePrimaryCounts();
    });
    eventBus.$on("resource-counts-loaded", (loadedCounts: ResourceTypeCount) => {
      Object.assign(this.resourceTypeCounts, loadedCounts);
    });
  },
  computed: {
    routeName() {
      let tokens = this.$route.path.split("/");
      return tokens[tokens.length - 1] || "ExamplePatientPatient";
    },
    sortedPrimaryResources(): PrimaryResource[] {
      return this.appConfig.primaryResources.sort((a: PrimaryResource, b: PrimaryResource) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    currentRouteResourceType() {
      return this.$route.params["resourceType"];
    },
    showGoBack(): boolean {
      const isListView = this.$route.path.startsWith("/list/");
      const isPrimaryResource = this.sortedPrimaryResources.some(
        (entry: { baseResource: string }) => entry.baseResource === this.currentRouteResourceType
      );
      const isExploreView = this.$route.path === "/explore";
      const isAllResourcesView = this.$route.path === "/explore"; // Matches the route for All Resources view
      const isSettingsView = this.$route.path === "/config"; // Matches the route for Settings view
      return !isAllResourcesView && !isSettingsView && (isExploreView || (!isPrimaryResource && isListView) || !isListView);
    },
    categorizedPrimaryResources() {
      const categorized: { [key: string]: { level: number; resources: PrimaryResource[] } } = {};
      for (const categoryKey of Object.keys(resourceCategories)) {
        const category = resourceCategories[categoryKey as keyof typeof resourceCategories];
        const primaryResources = category.resources
          .map((resource: { name: string }) => resource.name)
          .filter((resourceName: string) => this.appConfig.primaryResources.some(pr => pr.baseResource === resourceName));

        if (primaryResources.length > 0) {
          const displayName = category.displayName || categoryKey;
          categorized[displayName] = {
            level: category.level,
            resources: primaryResources.map((resourceName: string) => {
              const resourceConfig = this.appConfig.primaryResources.find(pr => pr.baseResource === resourceName);
              if (resourceConfig) {
                return {
                  name: resourceConfig.name,
                  baseResource: resourceConfig.baseResource,
                  icon: resourceConfig.icon,
                };
              } else {
                return { name: "", baseResource: "", icon: "" }; // Fallback in case resourceConfig is undefined
              }
            }).filter(resource => resource.name !== ""), // Filter out any invalid resources
          };
        }
      }
      return categorized;
    },
  },
  methods: {
    routeContainsResource(resource: string): boolean {
      return (
        !!this.currentRouteResourceType &&
        this.currentRouteResourceType === resource
      );
    },
    goBack() {
      router.back();
    },
    async loadAllResourcePrimaryCounts() {
      this.appConfig.primaryResources
        .map((entry) => entry.baseResource)
        .forEach(async (resource) => {
          const count = await resourceResolver.getResourceTypeCount(resource);
          Vue.set(this.resourceTypeCounts, resource, count);
        });

      Vue.set(
        this.resourceTypeCounts,
        "__total",
        await resourceResolver.getResourceTypeCount("")
      );
    },
    formatLargeNumber,
  },
  destroyed() {
    eventBus.$off("resourceCountChanged");
    eventBus.$off("resource-counts-loaded");
  },
});
