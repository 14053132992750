











import { ChoiceDefinition } from '@/lib-on-fhir';
import Vue from 'vue';
import { defineAsyncComponent } from 'vue-demi';
import ExplorerMetaInfo from './ExplorerMetaInfo.vue';

export default Vue.extend({
    props: {
        element: {
            type: ChoiceDefinition,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },

    components: {
        ExplorerMetaInfo,
        ExplorerDefinitionRenderer: defineAsyncComponent(() =>
            import('@/lib-on-fhir/vue-example/profile-explorer/ExplorerDefinitionRenderer.vue')
        ),
    },
    data: () => ({
        ChoiceDefinition,
    }),

    mounted() {},
});
