import { config } from "@/services/config";
import axios, { AxiosRequestConfig } from "axios";
import axiosRateLimit from "axios-rate-limit";

const requestTimeout = 10000;

function buildBackend(baseURL: string, headers?: any, maxRequests = 100) {
  let backend = axiosRateLimit(
    axios.create({
      baseURL,
      timeout: requestTimeout,
      headers,
    }),
    {
      maxRequests,
      perMilliseconds: 500,
    }
  );

  // middleware to reject all non get-requests if readOnlyMode is enabled
  backend.interceptors.request.use((req) => {
    if (config.appConfig.readOnlyMode) {
      const allowedNonGetRequests = ["$validate"];
      if (
        req.method?.toLowerCase() !== "get" &&
        !allowedNonGetRequests.some((endpoint) => req.url?.endsWith(endpoint))
      ) {
        throw new Error(
          `${req.method} request not allowed in readonly-mode. ${req.method} ${req.url}`
        );
      }
    }
    return req;
  });

  backend.interceptors.response.use(
    (res) => res,
    (err) => {
      // notifyWarning(err.config.url + ": " + err.message);
      throw err;
    }
  );

  return {
    backend,
    async get<T = any>(path: string): Promise<T> {
      let response = await backend.get(path);
      return response.data;
    },
    async post<T = any>(path: string, body: any): Promise<T> {
      let response = await backend.post(path, body);
      return response.data;
    },
    async put<T = any>(path: string, body: any): Promise<T> {
      let response = await backend.put(path, body);
      return response.data;
    },
    async delete<T = any>(path: string, body?: any): Promise<T> {
      let response = await backend.delete(path, body);
      return response.data;
    },
  };
}

// FHIR BACKEND
let fhirServerHeaders: any = {};
if (config.appConfig.fhirVersion) {
  fhirServerHeaders[
    "Accept"
  ] = `application/fhir+json; fhirVersion=${config.appConfig.fhirVersion}`;
}

let baseFhirUrl = config.appConfig.BASE_FHIR_URL;
if (
  location.origin.includes("localhost") &&
  baseFhirUrl === "https://server.fire.ly"
) {
  baseFhirUrl = "/local-firely-proxy";
}
export const fhirBackend = buildBackend(baseFhirUrl, fhirServerHeaders);
// SIMPLIFIER BACKEND
export const simplifierBackend = buildBackend("https://simplifier.net");

// ONTOSERVER BACKEND
export const ontoserverBackend = buildBackend(
  config.appConfig.terminologyServer
);

// STATIC RESOURCES BACKEND
export const staticBackend = buildBackend("/");
