

































import { AddableFields } from "@/lib-on-fhir";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    items: {
      type: Object as PropType<AddableFields>,
    },
    icon: {
      type: String,
      default: "mdi-plus-circle-outline",
    },
  },
});
