








































import { config } from "@/services/config";
import { IndirectReferencesResult } from "@/services/resolvers/IndirectReferences";
import Vue, { PropType } from "vue";
import router from "@/router";
import { serializeReferenceResourceFilter } from "@/state/filterConfigSerialize";

export default Vue.extend({
  props: {
    indirectReferencesResults: Array as PropType<IndirectReferencesResult[]>,
  },
  computed: {
    indirectReferencesResultsAnnotated(): (IndirectReferencesResult & {
      resourceConfig: { icon: string; name: string };
    })[] {
      return this.indirectReferencesResults
        .filter((entry) => entry.resources.length > 0)
        .map((entry) => {
          let resourceConfig = config.appConfig.primaryResources.find(
            (configEntry) =>
              configEntry.baseResource ===
              entry.resources[0].resource.resourceType
          );

          let fallbackResourceConfig: any;
          if (!resourceConfig) {
            console.warn(
              `Resource config not found for ${entry.resources[0].resource.resourceType}`
            );
            // fake basic resourceConfig
            fallbackResourceConfig = {
              name: entry.resources[0].resource.resourceType,
              icon: "mdi-help-circle-outline",
            };
          }
          return {
            ...entry,
            resourceConfig: resourceConfig || fallbackResourceConfig,
          };
        });
    },
  },
  data: () => ({
    showIndirectReferencesResult: null as null | IndirectReferencesResult,
  }),
  mounted() {},
  methods: {
    showList(indirectReferencesResult: IndirectReferencesResult | null) {
      this.showIndirectReferencesResult = indirectReferencesResult;
    },
    navigateToFilteredList(
      indirectReferencesResult: IndirectReferencesResult & {
        resourceConfig: any;
      }
    ) {
      let filterCode = indirectReferencesResult.referencedKey;
      let filterValue = indirectReferencesResult.resourceUrl;
      let resourceType = indirectReferencesResult.resourceConfig.baseResource;

      let serializedReferenceFilter = serializeReferenceResourceFilter(
        filterCode,
        filterValue
      );

      router.push({
        name: "List",
        params: {
          resourceType,
        },
        query: {
          filters: serializedReferenceFilter,
        },
      });
    },
  },
});
