import { config } from "@/services/config";
import { useResourceStore } from "@/state/resourceStore";

// @ts-ignore
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-json";
import "prismjs/themes/prism.css";

// JSON Editor stuff
export const useJSONEditor = () => {
  const overrideResourceFromJSON = (resource: any) => {
    if (config.appConfig.readOnlyMode) {
      return;
    }
    useResourceStore().setResource(resource, false);
  };

  return {
    overrideResourceFromJSON,

    jsonCodeHighlighter(code: string) {
      return highlight(code, languages.json);
    },
  };
};
