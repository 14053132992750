import { BaseDefinition, BaseWrapper, ElementDefinition } from '@/lib-on-fhir';

// A list of available fields to add
export type AddableFields = Record<
    string,
    {
        key: string;
        display: string;
        definition: BaseDefinition;
        missing?: boolean;
    }
>;

/**
 * Computes the addable fields of a given type, given a set of already
 * present <items> for the given <fieldDefinition>
 */
export function computeAddableFields(
    fieldId: string,
    fieldDefinition: BaseDefinition,
    items: BaseWrapper[]
): AddableFields {
    if (items.length >= fieldDefinition.cardinality.max) {
        // Hitting max cardinality
        return {};
    }
    const result: AddableFields = {};

    // Notice: We don't have to double include the slices minimum cardinality
    let minimumCardinalityFromSlices = 0;

    // Also add possible slices
    if (fieldDefinition instanceof ElementDefinition) {
        for (const [sliceId, sliceDefinition] of Object.entries(
            fieldDefinition.slicingFieldTypes
        )) {
            minimumCardinalityFromSlices += sliceDefinition.cardinality.min;

            // Count matching slices and make sure we don't exceed max cardinality
            const matchingSlices = items.filter(item => item.$type === sliceDefinition);
            if (matchingSlices.length >= sliceDefinition.cardinality.max) {
                continue;
            }

            result[fieldId + ':' + sliceId] = {
                key: fieldId,
                display: fieldId + ':' + sliceId,
                definition: sliceDefinition,
                missing: matchingSlices.length < sliceDefinition.cardinality.min,
            };
        }
    }

    result[fieldId] = {
        key: fieldId,
        display: fieldId,
        definition: fieldDefinition,
        missing: items.length < fieldDefinition.cardinality.min - minimumCardinalityFromSlices,
    };

    return result;
}
