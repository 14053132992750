














































import TextRenderer from "@/components/explore/system-types/text.vue";
import {
  ElementBinding,
  SystemTypeWrapper,
  collectValueSetExpansionEntries,
} from "@/lib-on-fhir";
import Vue from "vue";

export default Vue.extend({
  props: {
    element: {
      type: SystemTypeWrapper,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    TextRenderer,
  },

  data: () => ({
    resolvedValueSet: undefined as
      | { value: string; text: string; display: string }[]
      | undefined,
    resolveError: null as null | string,
  }),

  computed: {
    binding(): ElementBinding {
      return this.element.binding!;
    },

    label(): string | undefined {
      return this.resolvedValueSet?.find(
        (entry) => entry.value === this.element.value
      )?.display;
    },

    hasIssues(): boolean {
      return this.element.combinedErrors.some(
        (error) => error.severity === "error"
      );
    },
  },

  watch: {
    binding() {
      this.loadValueSet();
    },
  },

  async mounted() {
    if (this.binding.valueSet) {
      this.loadValueSet();
    }
  },

  methods: {
    updateValue(value: any) {
      if (typeof value === "string") {
        this.element.value = value;
      } else if (value.value) {
        this.element.value = value.value;
      } else {
        console.warn("Bad payload:", value);
      }
    },

    async loadValueSet() {
      const valueSet = this.binding.valueSet;
      try {
        const resolved = await this.element.$type.$profile.prefetcher.expandValueSet(
          valueSet
        );
        const values = collectValueSetExpansionEntries(
          resolved?.expansion?.contains
        );

        this.resolvedValueSet = values.map((entry) => ({
          value: entry.code!,
          text: entry.code + (entry.display ? " - " + entry.display : ""),
          display: entry.display!,
        }));
        if (!this.resolvedValueSet) {
          throw new Error("Empty Value Set");
        }
      } catch (ex) {
        this.resolveError = String(ex);
      }
    },
  },
});
