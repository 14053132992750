




import Vue from "vue";
import Root from "@/views/Root.vue";
import { useResourceStore } from "./state/resourceStore";
import { env } from "./services/config";

export default Vue.extend({
  name: "App",
  components: { Root },
  data: () => ({}),
  mounted() {
    // warn if leaving with unsaved changes, not in dev mode
    window.onbeforeunload = function(e: any) {
      if (!env.isDev && useResourceStore().isDirty) {
        return "Do you really want to leave? You have unsaved changes!";
      }
    };
  },
});
