import { ExternalProfileResolver, InvalidProfileError } from '@/lib-on-fhir';
import { profileResolver } from '@/services/resolvers/ProfileResolver';
import { valueSetResolver } from '@/services/resolvers/ValueSetResolver';
import FHIR from 'fhir/r4';

/**
 * The interface between records and lib-on-fhir
 */
export class CompatProfileResolver implements ExternalProfileResolver {
    async resolve(canonical: string): Promise<FHIR.StructureDefinition> {
        if (!canonical.startsWith('http')) {
            canonical = 'http://hl7.org/fhir/StructureDefinition/' + canonical;
        }

        const result = (await profileResolver.resolve(canonical)) as FHIR.StructureDefinition;
        if (!result) {
            throw new InvalidProfileError(
                'Failed to resolve canonical: ' + canonical + ': result is empty'
            );
        }
        return result;
    }

    async expandValueSet(canonical: string): Promise<any> {
        return await valueSetResolver.resolve(canonical);
    }
}
