import {
    AddableFields,
    BaseDefinition,
    BaseWrapper,
    computeAddableFields,
    DownstreamPassPayload,
    ElementDefinition,
    ProfilePathParser,
    SlicedElementDefinition,
    WrapperPatchOptions,
} from '@/lib-on-fhir';

/**
 * Wrapper for all repeatable fields
 */
export class ArrayWrapper extends BaseWrapper<BaseDefinition> {
    // @NOTICE: Even if its a base wrapper, system types as items are not supported.
    items: BaseWrapper[] = [];

    deleteValue() {
        const { resource, key } = this._resolveRef();
        resource[key] = undefined;
        this._changeHandler();
    }

    /**
     * Returns the available fields which can be added. Fields which exclude the maximum
     * cardinality are exlcuded, and while we are on it we also compute if the field is missing,
     * that is, occurring less often than specified by its cardinality.
     */
    getAvailableFieldsToAdd(): AddableFields {
        let result: AddableFields = {};

        const type = this.$type;
        if (this.items.length >= type.cardinality.max) {
            return result;
        }

        let name = '<new value>';
        if (this.$type instanceof ElementDefinition) {
            name = this.$type.typeName;
        }

        // @NOTICE: Don't need a proper key, since addNewField() doesn't need a key in our case
        return computeAddableFields(name, this.$type, this.items);
    }

    /**
     * Adds a new field to this array, pushing it to the end, and using the given definition.
     * Definition should either be this.$type or a sliced variant of the type.
     */
    addNewField(definition: BaseDefinition) {
        const { resource, key } = this._resolveRef();
        definition.addDefaultFieldOnObject(resource, key, {
            pattern: this.pattern,
            defaultValue: this.defaultValue,
            fixedValue: this.fixedValue,
        });
        this._changeHandler();

        return ProfilePathParser.appendIndex(this.absolutePath, this.items.length + 1);
    }

    patch(other: BaseWrapper, options: WrapperPatchOptions) {
        super.patch(other, options);
        if (!(other instanceof ArrayWrapper)) {
            throw this.logger.scopedException(Error, 'patch(): Type mismatch');
        }

        while (other.items.length > this.items.length) {
            other.items.pop();
        }
        while (other.items.length < this.items.length) {
            other.items.push(undefined as any);
        }

        for (let i = 0; i < this.items.length; ++i) {
            if (!other.items[i]) {
                options.$patch(other.items, i, this.items[i]);
            } else {
                this.items[i].patch(other.items[i], options);
            }
        }
    }

    _propagateDownstream(payload: DownstreamPassPayload) {
        super._propagateDownstream(payload);

        for (let i = 0; i < this.items.length; i++) {
            const childPayload = {
                ...payload,

                meta: this.meta,
                hidden: this.hidden,
                readonly: this.readonly,
            };

            if (Array.isArray(childPayload.pattern)) {
                childPayload.pattern = childPayload.pattern[i];
            }

            if (Array.isArray(childPayload.defaultValue)) {
                childPayload.defaultValue = childPayload.defaultValue[i];
            }

            if (Array.isArray(childPayload.fixedValue)) {
                childPayload.fixedValue = childPayload.fixedValue[i];
            }

            const itemType = this.items[i].$type;
            let genericPathWithSlices = this.genericPathWithSlices;
            if (itemType instanceof SlicedElementDefinition) {
                genericPathWithSlices = ProfilePathParser.appendSlice(
                    genericPathWithSlices,
                    itemType.sliceName
                );
            }

            childPayload.genericPath = payload.genericPath.slice();
            childPayload.genericPathWithSlices = genericPathWithSlices;
            childPayload.absolutePath = ProfilePathParser.appendIndex(payload.absolutePath, i);

            const item = this.items[i];
            item.logger = this.logger.child('[' + i + ']');
            item._propagateDownstream(childPayload);
        }
    }

    _computeLocalErrors() {
        let combined = [];
        for (const fieldWrapper of this.items) {
            fieldWrapper._computeLocalErrors();
            combined.push(...fieldWrapper.combinedErrors);
        }

        this.localErrors = [];
        this.combinedErrors = [...this.localErrors, ...combined];
    }
}
