






























import { ArrayWrapper, ChoiceWrapper, ElementWrapper, SystemTypeWrapper } from '@/lib-on-fhir';
import Vue from 'vue';
import { defineAsyncComponent } from 'vue-demi';

export default Vue.extend({
    props: {
        element: {
            type: Object,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },

    components: {
        ExplorerElementWrapper: defineAsyncComponent(() =>
            import('@/lib-on-fhir/vue-example/resource-explorer/ExplorerElementWrapper.vue')
        ),
        ExplorerArrayWrapper: defineAsyncComponent(() =>
            import('@/lib-on-fhir/vue-example/resource-explorer/ExplorerArrayWrapper.vue')
        ),
        ExplorerChoiceWrapper: defineAsyncComponent(() =>
            import('@/lib-on-fhir/vue-example/resource-explorer/ExplorerChoiceWrapper.vue')
        ),
        ExplorerSystemType: defineAsyncComponent(() =>
            import('@/lib-on-fhir/vue-example/resource-explorer/ExplorerSystemType.vue')
        ),
    },

    data: () => ({
        ArrayWrapper,
        ElementWrapper,
        SystemTypeWrapper,
        ChoiceWrapper,
    }),

    mounted() {},
});
