
































































import Vue from "vue";

export default Vue.extend({
  props: {
    hoverable: {
      type: Boolean,
      default: false,
    },
  },
});
