





































import Vue from "vue";
import { PropType } from "vue-demi";
import { ColumnConfig } from "@/config/config";
import { useResourceValidationStore } from "@/state/resourceValidationStore";

export default Vue.extend({
  props: {
    resource: Object,
    values: Array as PropType<string[]>,
    columnDefinition: Object as PropType<ColumnConfig>,
  },

  data: () => ({
    validationResponse: null as null | {
      errors?: number;
      warnings?: number;
      failed?: true;
    },
  }),

  watch: {
    resource: {
      immediate: true,
      async handler(resource: any) {
        this.validationResponse = null;
        let response: any;
        try {
          response = await useResourceValidationStore().validate(resource);
        } catch (ex) {
          this.validationResponse = { failed: true };
          console.warn("Validation failed:", ex);
          return;
        }
        this.validationResponse = response;
      },
    },
  },
});
