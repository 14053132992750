




import { ColumnConfig } from "@/config/config";
import { formatDistance } from "date-fns";
import Vue from "vue";
import { PropType } from "vue-demi";

export default Vue.extend({
  props: {
    resource: Object,
    values: Array as PropType<(string | null)[]>,
    columnDefinition: Object as PropType<ColumnConfig>,
  },

  methods: {
    formatDate(date: string): string {
      if (!date) {
        return "";
      }
      return formatDistance(new Date(Date.parse(date)), new Date(), {
        addSuffix: true,
      });
    },
  },
});
