import { getSearchParametersForResource } from "@/services/helpers";
import { ResourceFilterConfig } from "./resourceFilterStore";

const filterConfigOuterSerparator = ",";
const filterConfigInnerSerparator = ".";
const filterConfigInnerSerparatorEncoded = "%2E";

export function serializeResourceFilterConfig(
  resourceFilterConfig: ResourceFilterConfig[]
) {
  return resourceFilterConfig
    .map((entry) =>
      [entry.parameter.code, entry.comparator, entry.value]
        .map((str) => encodeURIComponent(str))
        .map((str) =>
          str.replaceAll(
            filterConfigInnerSerparator,
            filterConfigInnerSerparatorEncoded
          )
        )
        .join(filterConfigInnerSerparator)
    )
    .join(filterConfigOuterSerparator);
}

export function serializeReferenceResourceFilter(key: string, value: string) {
  return serializeResourceFilterConfig([
    {
      parameter: {
        code: key,
      },
      comparator: "none",
      value,
    } as ResourceFilterConfig,
  ]);
}

export function deserializeResourceFilterConfig(
  resourceFilterConfigSerialized: string,
  resourceType: string
) {
  let filterConfig: ResourceFilterConfig[] = [];
  let availableFilters = getSearchParametersForResource(resourceType);

  resourceFilterConfigSerialized
    .split(filterConfigOuterSerparator)
    .forEach((filterEntry) => {
      let [parameterCode, comparator, value] = filterEntry
        .split(filterConfigInnerSerparator)
        .map((str) => decodeURIComponent(str));

      let parameter = availableFilters.find(
        (filter) => filter.code === parameterCode
      );

      if (!parameter) {
        console.warn(
          `deserialize filter: unknown parameterCode: ${parameterCode}`
        );
        return;
      }

      filterConfig.push({
        parameter,
        comparator,
        value,
        pendingValue: value,
        pendingComparator: comparator,
      });
    });

  return filterConfig;
}
