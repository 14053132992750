import { PolyfilledIndexedDBCache } from "@/lib-on-fhir";
import { fhirApi, ontoserverApi } from "@/services/data";
import Vue from "vue";

export const valueSetResolver = new PolyfilledIndexedDBCache({
  id: "valueset",
  resolver: async (canonical: string) => {
    // 1. try to resolve using the fhir server
    try {
      return await fhirApi.expandValueSet(canonical);
    } catch (e) {
      // noop
    }

    // 2. try to resolve using the onto server
    try {
      console.warn("ontoserver:: resolve", canonical);
      let res = await ontoserverApi.expandValueSet(canonical);
      console.warn("ontoserver:: got", res);
      return res;
    } catch (e) {
      console.warn("ontoserver:: error", e);
    }

    throw new Error("Failed to resolve value set: " + canonical);
  },
});

// Make it reactive
new Vue({
  data: {
    valueSetResolver,
  },
});
