import { AppConfig } from "@/config/config";

export const devConfig: AppConfig = {
  BASE_FHIR_URL: "https://server.fire.ly",
  terminologyServer: "https://r5.ontoserver.csiro.au/fhir",
  fhirVersion: null, // uses server default
  readOnlyKeys: [
    "Resource.id",
    "Resource.meta.versionId",
    "Resource.meta.lastUpdated",
  ],
  metaKeys: ["Resource.id", "Resource.meta"],
  hideKeys: [] as string[],
  readOnlyMode: true,
  primaryResources: [
    {
      name: "Patients",
      singularName: "Patient",
      icon: "mdi-account",
      baseResource: "Patient",
      profiles: [
        "https://www.medizininformatik-initiative.de/fhir/core/modul-person/StructureDefinition/Patient",
      ],
      inactiveColumns: [],
      columns: [
        {
          label: "Name",
          paths: ["name.given", "name.family"],
        },
        {
          label: "Identifier",
          paths: ["identifier.value"],
          internalField: "identifier",
        },
        {
          label: "Gender",
          paths: ["gender"],
        },
        {
          label: "Birth date",
          paths: ["birthDate"],
        },
        {
          label: "Address",
          paths: [
            "address.line",
            "address.city",
            "address.state",
            "address.postalCode",
            "address.country",
          ],
        },
        {
          label: "Contact",
          paths: ["telecom.value"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Profile",
          paths: ["meta.profile[0]"],
          internalField: "_profile",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Practitioners",
      singularName: "Practitioner",
      icon: "mdi-doctor",
      baseResource: "Practitioner",
      inactiveColumns: [],
      columns: [
        {
          label: "Name",
          paths: ["name.given", "name.family"],
        },
        {
          label: "Qualification",
          paths: ["qualification.code.coding.display"],
        },
        {
          label: "Contact",
          paths: ["telecom.value"],
        },
        {
          label: "Address",
          paths: [
            "address.line",
            "address.city",
            "address.state",
            "address.postalCode",
            "address.country",
          ],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Organizations",
      singularName: "Organization",
      icon: "mdi-domain",
      baseResource: "Organization",
      inactiveColumns: [],
      columns: [
        {
          label: "Name",
          paths: ["name"],
        },
        {
          label: "Type",
          paths: ["type.coding.display"],
        },
        {
          label: "Address",
          paths: [
            "address.line",
            "address.city",
            "address.state",
            "address.postalCode",
            "address.country",
          ],
        },
        {
          label: "Contact",
          paths: ["telecom.value"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Encounters",
      singularName: "Encounter",
      icon: "mdi-hospital-box-outline",
      baseResource: "Encounter",
      inactiveColumns: [],
      columns: [
        {
          label: "Identifier",
          paths: ["identifier.value"],
        },
        {
          label: "Class",
          paths: ["class.display"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Participant",
          paths: ["participant.individual.reference"],
          renderer: "reference",
        },
        {
          label: "Start",
          paths: ["period.start"],
        },
        {
          label: "End",
          paths: ["period.end"],
        },
        {
          label: "Location",
          paths: ["location.location.reference"],
          renderer: "reference",
        },
        {
          label: "Reason",
          paths: ["reasonCode.coding.display"],
        },
        {
          label: "Diagnosis",
          paths: ["diagnosis.condition.reference"],
          renderer: "reference",
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Conditions",
      singularName: "Condition",
      icon: "mdi-radiology-box-outline",
      baseResource: "Condition",
      inactiveColumns: [],
      columns: [
        {
          label: "Condition",
          paths: ["code.coding.display"],
        },
        {
          label: "Clinical Status",
          paths: ["clinicalStatus.coding.code"],
        },
        {
          label: "Verification Status",
          paths: ["verificationStatus.coding.code"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Onset",
          paths: ["onsetDateTime"],
        },
        {
          label: "Severity",
          paths: ["severity.coding.display"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Observations",
      singularName: "Observation",
      icon: "mdi-eye-outline",
      baseResource: "Observation",
      inactiveColumns: [],
      columns: [
        {
          label: "Observation",
          paths: ["code.coding.display"],
        },
        {
          label: "Category",
          paths: ["category.coding.display"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Value",
          paths: ["valueQuantity.value", "valueQuantity.unit"],
        },
        {
          label: "Date",
          paths: ["effectiveDateTime"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Medication Requests",
      singularName: "Medication Request",
      icon: "mdi-message-plus-outline",
      baseResource: "MedicationRequest",
      inactiveColumns: [],
      columns: [
        {
          label: "Medication",
          paths: ["medicationCodeableConcept.coding.display"],
        },
        {
          label: "Dosage Instruction",
          paths: ["dosageInstruction.text"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Requester",
          paths: ["requester.reference"],
          renderer: "reference",
        },
        {
          label: "Authored On",
          paths: ["authoredOn"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Service Requests",
      singularName: "Service Request",
      icon: "mdi-message-cog-outline",
      baseResource: "ServiceRequest",
      inactiveColumns: [],
      columns: [
        {
          label: "Identifier",
          paths: ["identifier.value"],
        },
        {
          label: "Category",
          paths: ["category.coding.display"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Reason",
          paths: ["reasonCode.coding.display"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Requester",
          paths: ["requester.reference"],
          renderer: "reference",
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Research Subjects",
      singularName: "Research Subject",
      icon: "mdi-account-search",
      baseResource: "ResearchSubject",
      inactiveColumns: [],
      columns: [
        {
          label: "Subject ID",
          paths: ["identifier.value"],
        },
        {
          label: "Study",
          paths: ["study.reference"],
          renderer: "reference",
        },
        {
          label: "Start",
          paths: ["period.start"],
        },
        {
          label: "End",
          paths: ["period.end"],
        },
        {
          label: "Consent",
          paths: ["consent.reference"],
          renderer: "reference",
        },
        {
          label: "Patient",
          paths: ["individual.reference"],
          renderer: "reference",
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Episodes of Care",
      singularName: "Episode of Care",
      icon: "mdi-calendar-week",
      baseResource: "EpisodeOfCare",
      inactiveColumns: [],
      columns: [
        {
          label: "Type",
          paths: ["type.coding.display"],
        },
        {
          label: "Patient",
          paths: ["patient.reference"],
          renderer: "reference",
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Start",
          paths: ["period.start"],
        },
        {
          label: "End",
          paths: ["period.end"],
        },
        {
          label: "Care Manager",
          paths: ["careManager.reference"],
          renderer: "reference",
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Allergy Intolerances",
      singularName: "Allergy Intolerance",
      icon: "mdi-allergy",
      baseResource: "AllergyIntolerance",
      inactiveColumns: [],
      columns: [
        {
          label: "Substance",
          paths: ["code.coding.display"],
        },
        {
          label: "Criticality",
          paths: ["criticality"],
        },
        {
          label: "Patient",
          paths: ["patient.reference"],
          renderer: "reference",
        },
        {
          label: "Reaction",
          paths: ["reaction.manifestation.coding.display"],
        },
        {
          label: "Clinical status",
          paths: ["clinicalStatus.coding.code"],
        },
        {
          label: "Recorded date",
          paths: ["recordedDate"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Procedures",
      singularName: "Procedure",
      icon: "mdi-chart-gantt",
      baseResource: "Procedure",
      inactiveColumns: [],
      columns: [
        {
          label: "Procedure",
          paths: ["code.coding.display"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Performer",
          paths: ["performer.actor.reference"],
          renderer: "reference",
        },
        {
          label: "Date",
          paths: ["performedDateTime"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Diagnostic Reports",
      singularName: "Diagnostic Report",
      icon: "mdi-stethoscope",
      baseResource: "DiagnosticReport",
      inactiveColumns: [],
      columns: [
        {
          label: "Report",
          paths: ["code.coding.display"],
        },
        {
          label: "Category",
          paths: ["category.coding.display"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Effective Date",
          paths: ["effectiveDateTime"],
        },
        {
          label: "Performer",
          paths: ["performer.reference"],
          renderer: "reference",
        },
        {
          label: "Result",
          paths: ["result.reference"],
          renderer: "reference",
        },
        {
          label: "Conclusion",
          paths: ["conclusion"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Specimens",
      singularName: "Specimen",
      icon: "mdi-package-variant-closed",
      baseResource: "Specimen",
      inactiveColumns: [],
      columns: [
        {
          label: "Type",
          paths: ["type.coding.display"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Collection Date",
          paths: ["collection.collectedDateTime"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Medication Administrations",
      singularName: "Medication Administration",
      icon: "mdi-needle",
      baseResource: "MedicationAdministration",
      inactiveColumns: [],
      columns: [
        {
          label: "Medication",
          paths: ["medicationCodeableConcept.coding.display"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Performer",
          paths: ["performer.actor.reference"],
          renderer: "reference",
        },
        {
          label: "Effective Time",
          paths: ["effectiveDateTime"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Medication Statements",
      singularName: "Medication Statement",
      icon: "mdi-clipboard-plus-outline",
      baseResource: "MedicationStatement",
      inactiveColumns: [],
      columns: [
        {
          label: "Medication",
          paths: ["medicationCodeableConcept.coding.display"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Category",
          paths: ["category.coding.display"],
        },
        {
          label: "Patient",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Effective Period",
          paths: ["effectivePeriod.start", "effectivePeriod.end"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Coverages",
      singularName: "Coverage",
      icon: "mdi-shield-outline",
      baseResource: "Coverage",
      inactiveColumns: [],
      columns: [
        {
          label: "Coverage Type",
          paths: ["type.coding.display"],
        },
        {
          label: "Beneficiary",
          paths: ["beneficiary.reference"],
          renderer: "reference",
        },
        {
          label: "Payor",
          paths: ["payor.reference"],
          renderer: "reference",
        },
        {
          label: "Period",
          paths: ["period.start", "period.end"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
    {
      name: "Accounts",
      singularName: "Account",
      icon: "mdi-account-circle-outline",
      baseResource: "Account",
      inactiveColumns: [
        {
          label: "Description",
          paths: ["description"],
        },
        {
          label: "Type",
          paths: ["type.coding.display"],
        },
        {
          label: "Service Period",
          paths: ["servicePeriod.start", "servicePeriod.end"],
        },
        {
          label: "Coverage",
          paths: ["coverage.coverage.reference"],
        },
      ],
      columns: [
        {
          label: "Name",
          paths: ["name"],
        },
        {
          label: "Status",
          paths: ["status"],
        },
        {
          label: "Balance",
          paths: ["balance.value"],
        },
        {
          label: "Subject",
          paths: ["subject.reference"],
          renderer: "reference",
        },
        {
          label: "Owner",
          paths: ["owner.reference"],
          renderer: "reference",
        },
        {
          label: "Last updated",
          paths: ["meta.lastUpdated"],
          internalField: "_lastUpdated",
          renderer: "relative-date",
        },
        {
          label: "Validity",
          paths: [],
          renderer: "validation",
        },
      ],
    },
  ],
};
