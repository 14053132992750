import {
  BaseWrapper,
  LocalResourceError,
  Profile,
  ProfileConfig,
} from "@/lib-on-fhir";
import { config } from "@/services/config";
import { useProfileStore } from "@/state/profileStore";
import { useResourceIssueStore } from "@/state/resourceIssueStore";
import _ from "lodash";
import { defineStore } from "pinia";
import Vue from "vue";

const VuePatcher = {
  $deleteKey(object: any, key: string | number) {
    if (typeof object[key] !== "undefined") {
      Vue.delete(object, key);
    }
  },
  $patch(object: any, key: string | number, value: any) {
    if (object[key] !== value) {
      Vue.set(object, key, value);
    }
  },
};

export const useResourceStore = defineStore("resourceStore", {
  state: () => ({
    originalResource: null as null | any,
    resource: null as null | any,
    resourceType: "",

    showMetaFields: true,

    wrappedResource: null as BaseWrapper | null,
  }),
  getters: {
    localIssues(): LocalResourceError[] {
      return this.wrappedResource?.combinedErrors || [];
    },
    localIssuesErrorCount(): number {
      return this.localIssues.filter((issue) => issue.severity === "error")
        .length;
    },
    localIssuesWarningCount(): number {
      return this.localIssues.filter((issue) => issue.severity === "warning")
        .length;
    },
    isDirty(): boolean {
      return !_.isEqual(this.resource, this.originalResource);
    },
    initialized(): boolean {
      return (
        this.resource && Object.keys(useProfileStore().profiles).length > 0
      );
    },

    profileConfig(): ProfileConfig {
      let hideKeys = config.appConfig.hideKeys;
      if (!this.showMetaFields) {
        // Easy solution: If we don't want to show meta keys, simply
        // add them to the hidden keys list
        hideKeys = [...hideKeys, ...config.appConfig.metaKeys];
      }

      return {
        hideKeys,
        metaKeys: config.appConfig.metaKeys,
        readonlyKeys: config.appConfig.readOnlyKeys,
      };
    },
  },
  actions: {
    onProfileResolved() {
      console.log(
        "Resource Store; Profile was resolved:",
        this.getResourceProfileCanonical()
      );
      this.wrapResource();
    },

    requestCurrentProfile(): Profile | undefined {
      if (!this.resource) {
        return;
      }

      const profile = useProfileStore().profiles[
        this.getResourceProfileCanonical()
      ] as Profile;

      if (!profile) {
        console.warn(
          "Can not wrap/patch resource, since profile '" +
            this.getResourceProfileCanonical() +
            "' is not loaded yet, waiting ..."
        );
        useProfileStore()
          .initProfile(this.getResourceProfileCanonical(), this.resourceType)
          .then(() => this.onProfileResolved());
        this.wrappedResource = null;
        return;
      }
      return profile;
    },

    /**
     * Wraps the resource initially
     */
    wrapResource() {
      const profile = this.requestCurrentProfile();
      if (!profile) {
        return;
      }
      if (!this.resource) {
        console.warn("wrapResource() without profile");
        return;
      }

      console.warn("Wrapping resource (slow)");
      useResourceIssueStore().markOutdated();
      this.wrappedResource = profile.wrapResource(this.resource, {
        config: this.profileConfig,
        changeHandler: () => this.patchResource(),
      });

      // Make sure to actually request the new profile, in case it changed
      this.requestCurrentProfile();
    },

    /**
     * Patches the resource after modification
     */
    patchResource() {
      const profile = this.requestCurrentProfile();
      if (!profile) {
        return;
      }

      console.warn("Patching resource (semi-slow)");
      if (!this.wrappedResource) {
        throw new Error("can't patch() without resource");
      }
      const newlyWrapped = profile.wrapResource(this.resource, {
        config: this.profileConfig,
        changeHandler: () => this.patchResource(),
      });
      newlyWrapped.patch(this.wrappedResource as BaseWrapper, VuePatcher);
      this.originalResource = { ...this.originalResource };

      useResourceIssueStore().markOutdated();

      // Make sure to actually request the new profile, in case it changed
      this.requestCurrentProfile();
    },

    reset() {
      this.resource = null;
      this.wrappedResource = null;
      this.originalResource = null;
      this.resourceType = "";
    },

    setResource(resource: any, initial = true) {
      if (!resource) {
        this.reset();
        return;
      }
      this.resourceType = resource.resourceType;
      this.resource = resource;
      this.wrappedResource = null;
      if (initial) {
        this.markCurrentResourceAsOriginal();
      }

      // Make sure to actually request the profile
      useProfileStore().initProfile(this.resourceType, this.resourceType);

      this.wrapResource();
    },

    markCurrentResourceAsOriginal() {
      this.originalResource = _.cloneDeep(this.resource);
    },

    setShowMetaFields(showMetaFields: boolean) {
      this.showMetaFields = showMetaFields;
      this.wrapResource();
    },

    getResourceType() {
      return this.resourceType;
    },
    getResourceProfileCanonical() {
      let meta = this.resource?.meta;
      if (Array.isArray(meta)) {
        meta = meta[0];
      }
      let profile = meta?.profile;
      if (Array.isArray(profile)) {
        profile = profile[0];
      }

      return profile || this.getResourceType();
    },
  },
});
