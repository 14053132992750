import { fhirApi } from "@/services/data";
import { profileResolver } from "@/services/resolvers/ProfileResolver";

export interface IndirectReferencesResult {
  resources: any[];
  total: number;
  referencedKey: string;
  resourceUrl: string;
}

export async function resolveIndirectReferences({
  resourceUrl,
  resourceProfileUrl,
  referenceProfileUrl,
}: {
  resourceUrl: string;
  resourceProfileUrl: string;
  referenceProfileUrl: string;
}) {
  let profile = await profileResolver.resolve(referenceProfileUrl);

  let possibleReferencedKeys = profile
    .snapshot!.element.map((entry: any) => {
      if (!entry.type) {
        return;
      }

      let references = entry.type.filter(
        (type: any) => type.code === "Reference"
      );

      if (references.length === 0) {
        return;
      }

      let targetProfiles: string[] = references.flatMap(
        (type: any) => type.targetProfile
      );

      if (!targetProfiles.includes(resourceProfileUrl)) {
        return;
      }

      return entry.path;
    })
    .filter((d: string | null) => !!d);

  if (possibleReferencedKeys.length === 0) {
    return;
  }

  // TODO search for all possible referenced keys, for now just use the first (propably possible to search for all keys in one request by OR chaning)
  let referencedPath = possibleReferencedKeys[0];
  let keyToSearchFor = referencedPath.split(".")[1];

  let indirectRefList = await fhirApi.getIndirectSubjectReferences(
    resourceUrl,
    referenceProfileUrl,
    keyToSearchFor
  );

  let total = indirectRefList.total;
  if (total === 0) {
    return;
  }

  // check if result if valid
  let operationOutcomes = indirectRefList.entry.filter(
    (e: any) => e.search.mode === "outcome"
  );
  // check if issue with severity error exists
  let containsError = operationOutcomes.some(
    (e: any) =>
      e.resource.issue?.some((i: any) => i.severity === "error") ||
      // this issue has serverity warning but results in invalid response
      e.resource.issue?.some((i: any) =>
        i.details.text.includes(
          `Parameter ${keyToSearchFor} is not implemented`
        )
      )
  );

  if (containsError) {
    console.warn("indirect result outcome contains error", {
      operationOutcomes,
      baseResource: referenceProfileUrl,
      keyToSearchFor,
    });
    return;
  }

  let matches = indirectRefList.entry.filter(
    (e: any) => e.search.mode === "match"
  );

  // validate matches, that they contain the referenced resource
  let firstWarning = true;
  let validMatches = matches.filter((e: any) => {
    let resource = e.resource;
    // dirty check
    let valid = JSON.stringify(resource).includes(resourceUrl);

    if (!valid && firstWarning) {
      firstWarning = false;
      console.warn("!! invalid indirect reference result found", {
        resourceUrl,
        keyToSearchFor,
        resource: e.resource,
        referenceProfileUrl: referenceProfileUrl,
        operationOutcomes: operationOutcomes,
      });
    }
    return valid;
  });

  if (validMatches.length === 0) {
    return;
  }

  return {
    resourceUrl,
    resources: validMatches,
    total: validMatches.length,
    referencedKey: keyToSearchFor,
  } as IndirectReferencesResult;
}

export const indirectReferenceResolver = {
  resolveIndirectReferences,
};
