import {
    BaseWrapper,
    DownstreamPassPayload,
    ElementWrapper,
    PrimitiveJSONProxy,
    ProfileNotSupportedError,
    ProfilePathParser,
    WrapperPatchOptions,
} from '@/lib-on-fhir';

const defaultValueToFrozenPattern: Record<string, any> = {};

/**
 * Caches values so we can quickly wrap them into frozen objects
 */
function getFrozenValue(value: string | undefined): { value: string } | undefined {
    if (!value) {
        return;
    }
    const cached = defaultValueToFrozenPattern[value];
    if (cached) {
        return cached;
    } else {
        return (defaultValueToFrozenPattern[value] = Object.freeze({ value }));
    }
}

export class PrimitiveWrapper extends ElementWrapper {
    deleteValue() {
        const { resource, key } = this._ref;
        const proxy = resource[key];
        if (!(proxy instanceof PrimitiveJSONProxy)) {
            throw this.logger.scopedException(
                ProfileNotSupportedError,
                'Deleting $ref primitive with stale value, is not proxy'
            );
        }
        proxy.deleteValue();
        this._changeHandler();
    }

    _propagateDownstream(payload: DownstreamPassPayload) {
        BaseWrapper.prototype._propagateDownstream.call(this, payload);

        // @HACK: special handling for primitive types
        this.pattern = getFrozenValue(this.pattern);
        this.defaultValue = getFrozenValue(this.defaultValue);
        this.fixedValue = getFrozenValue(this.fixedValue);

        for (const [fieldId, fieldWrapper] of Object.entries(this.fields)) {
            const childPayload: DownstreamPassPayload = {
                pattern: this.pattern?.[fieldId],
                fixedValue: this.fixedValue?.[fieldId],
                defaultValue: this.defaultValue?.[fieldId],

                genericPath: ProfilePathParser.appendMember(this.genericPath, fieldId),
                genericPathWithSlices: ProfilePathParser.appendMember(
                    this.genericPathWithSlices,
                    fieldId
                ),
                absolutePath: ProfilePathParser.appendMember(this.absolutePath, fieldId),

                readonly: this.readonly,
                meta: this.meta,
                hidden: this.hidden,

                changeHandler: payload.changeHandler,
                keyConfig: payload.keyConfig,
            };

            if (fieldId === 'value') {
                childPayload.binding = this.binding;
                childPayload.targetProfile = this.targetProfile;
            }

            fieldWrapper.logger = this.logger.child(fieldId);
            fieldWrapper._propagateDownstream(childPayload);
        }
    }

    patch(other: BaseWrapper, options: WrapperPatchOptions) {
        super.patch(other, options);
        if (!(other instanceof PrimitiveWrapper)) {
            throw this.logger.scopedException(Error, 'patch(): Type mismatch');
        }
    }
}
