import { simplifierApi } from "@/services/data";
import FHIR from "fhir/r4";

async function resolveProfileSimplifier(
  profileId: string
): Promise<FHIR.StructureDefinition> {
  const defaultSearchScope = "hl7.fhir.r4.core@4.0.1";
  // const mmiScope = "MedizininformatikInitiative-ModulPerson@current";
  // current HACK to make MII work
  const mmiScope =
    "de.medizininformatikinitiative.kerndatensatz.person@2.0.0-ballot2";
  const gematikScope = "de.gematik.isik-basismodul@2.0.5";

  const searchScopes = [defaultSearchScope, mmiScope, gematikScope];

  let simplifierResponse;

  for (const searchScope of searchScopes) {
    try {
      simplifierResponse = await simplifierApi.resolveCanonicalDownloadLink(
        profileId,
        searchScope
      );
      break;
    } catch (e) {
      console.warn(
        "Could not resolve profile",
        profileId,
        "with searchScope",
        searchScope,
        "on simplifier",
        e
      );
    }
  }

  if (!simplifierResponse) {
    throw new Error(
      "Could not resolve profile " + profileId + " on simplifier"
    );
  }

  let { downloadLink } = simplifierResponse;
  let resource = await simplifierApi.getSimplifierResource(downloadLink);
  return resource;
}

export const profileResolverSimplifier = {
  resolveProfileSimplifier,
};
