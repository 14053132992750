







import { ElementDefinition } from '@/lib-on-fhir';
import { useProfileStore } from '@/state/profileStore';
import { useResourceStore } from '@/state/resourceStore';
import Vue from 'vue';
import ExplorerDefinitionRenderer from './ExplorerDefinitionRenderer.vue';

export default Vue.extend({
    components: {
        ExplorerDefinitionRenderer,
    },

    computed: {
        currentCanonical(): string {
            return useResourceStore().getResourceProfileCanonical();
        },

        profile(): any {
            return useProfileStore().profiles[this.currentCanonical];
        },

        profileRoot(): ElementDefinition | any {
            return this.profile?.definitions[useResourceStore().resourceType];
        },
    },
});
