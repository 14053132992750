













































import { getResourceTitle } from "@/services/helpers";
import Vue from "vue";
import FHIR from "fhir/r4";
import { PropType } from "vue-demi";

export default Vue.extend({
  props: {
    morePadding: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object as PropType<FHIR.Resource>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    link(): any {
      return {
        name: "Explore",
        params: {
          resourceType: this.resource!.resourceType,
          id: this.resource!.id,
        },
      };
    },
  },

  methods: {
    getResourceTitle,
  },
});
