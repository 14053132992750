import { BaseDefinition, SlicedElementDefinition, SlicedPrimitiveDefinition } from '@/lib-on-fhir';
import { ValueSetExpansionContains } from 'fhir/r4';

/**
 * Creates a deep clone of the given object
 */
export function deepClone<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
}

/**
 * Capitalizes the first letter
 */
export function capitalizeString(value: string) {
    return value[0].toUpperCase() + value.substring(1);
}

/**
 * De-capitalizes the first letter
 */
export function decapitalizeString(value: string) {
    return value[0].toLowerCase() + value.substring(1);
}

/**
 * Checks if value === undefined || value === null
 */
export function isNullOrUndefined<T>(value: T): T extends null | undefined ? true : false {
    return (typeof value === 'undefined' || value === null) as any;
}

/**
 * Removes html characters from a string
 */
export function escapeHtml(unsafe: string) {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}

/**
 * Helper method to extract a slice name
 */
export function getSliceName(type: BaseDefinition): string | undefined {
    let sliceName = undefined;

    if (type instanceof SlicedElementDefinition) {
        sliceName = type.sliceName;
    }
    if (type instanceof SlicedPrimitiveDefinition) {
        sliceName = type.sliceName;
    }
    return sliceName;
}

/**
 * Collects all entries for a valueset expansion, most notable covers the nested contains case
 */
export function collectValueSetExpansionEntries(contains?: ValueSetExpansionContains[]) {
    if (!contains) {
        return [];
    }

    let subValues: ValueSetExpansionContains[] = [];
    contains.forEach(e => {
        subValues = subValues.concat(collectValueSetExpansionEntries(e.contains));
    });

    return [...contains, ...subValues];
}

export function formatLargeNumber(number: number) {
    if (number >= 1000) {
        const suffixes = ['', 'k', 'M', 'B', 'T']; // Add more suffixes as needed
        const suffixIndex = Math.floor(Math.log10(number) / 3);
        const shortNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
        return shortNumber + suffixes[suffixIndex];
    }
    return number.toString();
}
