import {
    BaseWrapper,
    ContextualLogger,
    DownstreamKeyConfig,
    ElementDefinition,
    InvalidProfileError,
    ProfileLoader,
    ProfilePrefetcher,
    ProfileTraverser,
} from '@/lib-on-fhir';
import { ProfilePathParser } from '@/lib-on-fhir';

export type ProfileConfig = {
    metaKeys: string[];
    readonlyKeys: string[];
    hideKeys: string[];
};

/**
 * Base class for storing a Profile, being able to store multiple root element definitions
 */
export class Profile {
    /**
     * Storage of all definitions, e.g. 'Patient' -> [...]
     */
    definitions: Record<string, ElementDefinition> = {};

    /**
     * Allows to resolve canonicals to snapshots/structure definitions
     */
    prefetcher: ProfilePrefetcher;

    /**
     * Allows to traverse the profile via a path, e.g. 'Patient.identifier:slice.id'
     */
    traverser: ProfileTraverser = new ProfileTraverser(this);

    /**
     * Allows to load JSON Profile definitions into this profile
     */
    loader: ProfileLoader = new ProfileLoader(this);

    /**
     * Basic logging, including path context
     */
    logger: ContextualLogger;

    constructor({
        prefetcher,
        logger = new ContextualLogger('Profile'),
    }: {
        prefetcher: ProfilePrefetcher;
        logger?: ContextualLogger;
    }) {
        this.prefetcher = prefetcher;
        this.logger = logger;
    }

    /**
     * Wraps a resource into a renderable tree
     */
    wrapResource(
        resource: Record<string, any> & { resourceType: string },
        { changeHandler, config }: { changeHandler: () => void; config: ProfileConfig }
    ): BaseWrapper {
        const definition = this.definitions[resource.resourceType];
        if (!definition) {
            throw this.logger.scopedException(
                InvalidProfileError,
                `Definition for resourceType ${resource.resourceType} not yet loaded.`
            );
        }
        const prepared = definition.wrapResourceWithinArray(
            { resource: [resource] },
            'resource',
            0
        )!;
        prepared._propagateDownstream({
            changeHandler,
            absolutePath: ProfilePathParser.parse(resource.resourceType),
            genericPath: ProfilePathParser.parse(resource.resourceType),
            genericPathWithSlices: ProfilePathParser.parse(resource.resourceType),

            keyConfig: this.generateKeyMapFromConfig(resource.resourceType, config),
        });
        prepared._computeLocalErrors();
        return prepared;
    }

    /**
     * Prepares a config key map to an actual usable map
     */
    protected generateKeyMapFromConfig(
        resourceType: string,
        config: ProfileConfig
    ): DownstreamKeyConfig {
        let result: DownstreamKeyConfig = {};

        const setFlag = (key: string, flag: keyof DownstreamKeyConfig[''], value: any) => {
            const data = result[key];
            if (data) {
                data[flag] = value;
            } else {
                result[key] = { [flag]: value };
            }
        };

        // Readonly
        for (const key of config.readonlyKeys) {
            setFlag(key, 'readonly', true);
            setFlag(key.replace(/^Resource/, resourceType), 'readonly', true);
        }

        // Meta
        for (const key of config.metaKeys) {
            setFlag(key, 'meta', true);
            setFlag(key.replace(/^Resource/, resourceType), 'meta', true);
        }

        // Hidden
        for (const key of config.hideKeys) {
            setFlag(key, 'hidden', true);
            setFlag(key.replace(/^Resource/, resourceType), 'hidden', true);
        }

        return result;
    }
}
