import {
    AddableFields,
    ArrayWrapper,
    BaseDefinition,
    BaseWrapper,
    ChoiceWrapper,
    computeAddableFields,
    DownstreamPassPayload,
    ElementDefinition,
    InvalidResourceError,
    isNullOrUndefined,
    ProfilePathParser,
    SlicedElementDefinition,
    SystemTypeWrapper,
    WrapperPatchOptions,
    ProfilePath,
} from '@/lib-on-fhir';

/**
 * Wrapper for a complex type, combining the field values with the type fields
 */
export class ElementWrapper extends BaseWrapper<ElementDefinition> {
    fields: Record<string, BaseWrapper> = {};

    deleteValue() {
        if (isNullOrUndefined(this._ref.index)) {
            if (this._ref.index !== 0) {
                throw this.logger.scopedException(
                    InvalidResourceError,
                    `Can not delete from non-array without index (yet)`
                );
            }
        } else {
            let target = this._ref.resource[this._ref.key];
            if (Array.isArray(target)) {
                target[this._ref.index!] = null;
                target = target.filter(x => !isNullOrUndefined(x));
                if (target.length === 0) {
                    target = undefined;
                }
                this._ref.resource[this._ref.key] = target;
            } else {
                if (this._ref.index !== 0) {
                    throw this.logger.scopedException(
                        InvalidResourceError,
                        `Can not delete from non-array if index != 0`
                    );
                }
                this._ref.resource[this._ref.key] = undefined;
            }
        }
        this._changeHandler();
    }

    /**
     * Returns the available fields which can be added. Fields which exclude the maximum
     * cardinality are exlcuded, and while we are on it we also compute if the field is missing,
     * that is, occurring less often than specified by its cardinality.
     */
    getAvailableFieldsToAdd(): AddableFields {
        let result: AddableFields = {};
        for (const [fieldId, fieldDefinition] of Object.entries(this.$type.fieldTypes)) {
            const fieldWrapper = this.fields[fieldId] as BaseWrapper | undefined;

            let items: BaseWrapper[] = [];
            if (fieldWrapper instanceof ArrayWrapper) {
                items = fieldWrapper.items;
            } else if (fieldWrapper) {
                items = [fieldWrapper];
            }

            result = {
                ...result,
                ...computeAddableFields(fieldId, fieldDefinition, items),
            };
        }
        return result;
    }

    addMissingRequiredFields() {
        let requiredFields = Object.entries(this.getAvailableFieldsToAdd()).filter(
            ([key, entry]) => entry.definition.cardinality.min > 0
        );
        requiredFields.forEach(([key, entry]) => this.addNewField(key));
    }

    /**
     * Adds a new field to the element, using the given definition  The definition should
     * match the definition of either the field or the field within a slice of this element definition.
     *
     * If the value is not yet present on the resource, it will simply add it. If the value is present,
     * the current value will be converted to an array and the new value will be pushed to the end.
     */
    addNewField(fieldId: string): ProfilePath {
        const { resource, key } = this._resolveRef();

        let definition: BaseDefinition;
        if (fieldId.includes(':')) {
            // Sliced field
            const [baseFieldId, slice] = fieldId.split(':');
            definition = (this.$type.fieldTypes[baseFieldId] as ElementDefinition)
                ?.slicingFieldTypes?.[slice];
            fieldId = baseFieldId;
        } else {
            // Regular field
            definition = this.$type.fieldTypes[fieldId];
        }

        if (!definition) {
            throw this.logger.scopedException(Error, `Field id is unknown: '${fieldId}'`);
        }

        definition.addDefaultFieldOnObject(resource[key], fieldId, {
            pattern: this.pattern?.[fieldId],
            defaultValue: this.defaultValue?.[fieldId],
            fixedValue: this.fixedValue?.[fieldId],
        });

        // Compute location of new field
        let index = 0;
        const field = this.fields[fieldId];
        if (field instanceof ArrayWrapper) {
            index = field.items.length;
        }

        this._changeHandler();

        return ProfilePathParser.appendIndex(
            ProfilePathParser.appendMember(this.absolutePath, fieldId),
            index
        );
    }

    patch(other: BaseWrapper, options: WrapperPatchOptions) {
        super.patch(other, options);
        if (!(other instanceof ElementWrapper)) {
            throw this.logger.scopedException(Error, 'patch(): Type mismatch');
        }

        for (const fieldId in other.fields) {
            if (!this.fields[fieldId]) {
                options.$deleteKey(other.fields, fieldId);
            }
        }

        for (const [fieldId, field] of Object.entries(this.fields)) {
            if (!other.fields[fieldId]) {
                options.$patch(other.fields, fieldId, field);
            } else {
                field.patch(other.fields[fieldId], options);
            }
        }
    }

    _computeLocalErrors() {
        let combined = [];
        for (const [fieldId, fieldWrapper] of Object.entries(this.fields)) {
            fieldWrapper._computeLocalErrors();
            combined.push(...fieldWrapper.combinedErrors);
        }

        this.localErrors = [];

        if (Object.keys(this.fields).length === 0) {
            this._emitError({
                id: 'emptyElement',
                payload: { typeId: this.$type.typeName },
            });
        }

        // Fields
        for (const [fieldId, fieldDefinition] of Object.entries(this.$type.fieldTypes)) {
            let items: BaseWrapper[] = [];
            if (this.fields[fieldId]) {
                const entry = this.fields[fieldId];
                if (entry instanceof ArrayWrapper) {
                    items = entry.items;
                } else if (entry instanceof SystemTypeWrapper) {
                    items = [entry];
                } else if (entry instanceof ChoiceWrapper) {
                    items = [entry];
                } else {
                    throw new Error('Bad type: ' + entry.constructor.name);
                }
            }

            let minimumFromSlices = 0;

            // Slices of that field
            if (fieldDefinition instanceof ElementDefinition) {
                for (const [sliceId, sliceDefinition] of Object.entries(
                    fieldDefinition.slicingFieldTypes
                )) {
                    minimumFromSlices += sliceDefinition.cardinality.min;

                    // Count matching slices and make sure we don't exceed max cardinality
                    const matchingSlices = items.filter(item => item.$type === sliceDefinition);
                    if (matchingSlices.length < sliceDefinition.cardinality.min) {
                        this._emitError({
                            id: 'fieldSliceCardinalityTooLow',
                            payload: {
                                fullFieldId: fieldId + ':' + sliceId,
                                minimum: sliceDefinition.cardinality.min,
                            },
                            fix: () => this.addNewField(fieldId + ':' + sliceId),
                        });
                    }
                    if (matchingSlices.length > sliceDefinition.cardinality.max) {
                        this._emitError({
                            id: 'fieldSliceCardinalityTooHigh',
                            payload: {
                                fullFieldId: fieldId + ':' + sliceId,
                                maximum: sliceDefinition.cardinality.max,
                            },
                        });
                    }
                }
            }

            // General cardinality
            if (items.length < fieldDefinition.cardinality.min - minimumFromSlices) {
                this._emitError({
                    id: 'fieldCardinalityTooLow',
                    payload: { fieldId, minimum: fieldDefinition.cardinality.min },
                    fix: () => this.addNewField(fieldId),
                });
            }
            if (items.length > fieldDefinition.cardinality.max) {
                this._emitError({
                    id: 'fieldCardinalityTooHigh',
                    payload: { fieldId, maximum: fieldDefinition.cardinality.max },
                });
            }
        }

        this.combinedErrors = [...this.localErrors, ...combined];
    }

    _propagateDownstream(payload: DownstreamPassPayload) {
        super._propagateDownstream(payload);

        let combinedErrors = [];
        for (const [fieldId, fieldWrapper] of Object.entries(this.fields)) {
            let genericPathWithSlices = this.genericPathWithSlices;

            if (fieldWrapper.$type instanceof SlicedElementDefinition) {
                genericPathWithSlices = ProfilePathParser.appendSlice(
                    genericPathWithSlices,
                    fieldWrapper.$type.sliceName
                );
            }

            const childPayload: DownstreamPassPayload = {
                pattern: this.pattern?.[fieldId],
                fixedValue: this.fixedValue?.[fieldId],
                defaultValue: this.defaultValue?.[fieldId],

                binding: this._shouldForwardBinding(fieldId) ? this.binding : undefined,
                targetProfile: this._shouldForwardTargetProfile(fieldId)
                    ? this.targetProfile
                    : undefined,

                genericPath: ProfilePathParser.appendMember(this.genericPath, fieldId),
                genericPathWithSlices: ProfilePathParser.appendMember(
                    genericPathWithSlices,
                    fieldId
                ),
                absolutePath: ProfilePathParser.appendMember(this.absolutePath, fieldId),

                // @NOTICE: Except for readonly, we don't propagate other options since
                // we would make it impossible to declare a parent as meta but not the children
                readonly: this.readonly,
                // hidden: this.hidden,
                // meta: this.meta,

                changeHandler: payload.changeHandler,
                keyConfig: payload.keyConfig,
            };
            fieldWrapper.logger = this.logger.child(fieldId);
            fieldWrapper._propagateDownstream(childPayload);

            combinedErrors.push(...fieldWrapper.combinedErrors);
        }

        this.combinedErrors = combinedErrors;
    }

    /**
     * Checks if a child node should inherit a binding - this is a fhir special case
     */
    protected _shouldForwardBinding(fieldId: string) {
        if (this.$type.typeName === 'Coding' && fieldId === 'code') {
            return true;
        }
        if (this.$type.typeName === 'CodeableConcept' && fieldId === 'coding') {
            return true;
        }
    }

    /**
     * Checks if a child node should inherit a target profile - this is a fhir special case
     */
    protected _shouldForwardTargetProfile(fieldId: string) {
        if (this.$type.typeName === 'Reference' && fieldId === 'reference') {
            return true;
        }
    }
}
