




























































import {
  capitalizeString,
  LocalResourceError,
  ProfilePathParser,
} from "@/lib-on-fhir";
import { usePathHighlightStore } from "@/state/pathHighlightStore";
import {
  GenericResourceError,
  useResourceIssueStore,
} from "@/state/resourceIssueStore";
import { useResourceStore } from "@/state/resourceStore";
import Vue from "vue";

export default Vue.extend({
  computed: {
    localIssues(): GenericResourceError[] {
      return useResourceStore().wrappedResource?.combinedErrors || [];
    },

    serverIssues(): GenericResourceError[] {
      return useResourceIssueStore().issues || [];
    },

    sortedIssues(): GenericResourceError[] {
      const issues = [...this.localIssues, ...this.serverIssues];
      return issues.sort((a, b) =>
        ProfilePathParser.toString(a.path).localeCompare(
          ProfilePathParser.toString(b.path)
        )
      );
    },
  },
  data: () => ({
    capitalizeString,
    ProfilePathParser,
  }),

  methods: {
    requestLocalErrorFocus(error: LocalResourceError) {
      usePathHighlightStore().requestPathHighlight(error.path);
    },
  },
});
