import { AppConfig } from "@/config/config";
import { devConfig } from "@/config/dev.config";

export const demoConfig: AppConfig = {
  BASE_FHIR_URL: "https://server.fire.ly",
  terminologyServer: "https://r5.ontoserver.csiro.au/fhir",
  fhirVersion: "4.0",
  readOnlyKeys: [
    "Resource.id",
    "Resource.meta.versionId",
    "Resource.meta.lastUpdated",
  ],
  metaKeys: ["Resource.id", "Resource.meta"],
  hideKeys: [] as string[],
  readOnlyMode: true,
  primaryResources: devConfig.primaryResources,
};
