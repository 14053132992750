
































import Vue from 'vue';
export default Vue.extend({
    props: {
        element: {
            type: Object,
        },
    },
});
