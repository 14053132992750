





























































































































































































































import { COLUMN_RENDERERS } from "@/components/column-renderers";
import router from "@/router";
import { config } from "@/services/config";
import { resolveColumnPaths } from "@/services/helpers";
import {
  restoreFilterStateFromRoute,
  useResourceFilterStore,
} from "@/state/resourceFilterStore";
import { useResourceListStore } from "@/state/resourceListStore";
import ColumnEditor from "@/views/List/ColumnEditor.vue";
import Filters from "@/views/List/Filters.vue";
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    loaded: false,

    columnEditorVisible: false,
    filtersVisible: false,
  }),
  components: {
    ColumnEditor,
    Filters,
  },
  computed: {
    anyFiltersActive() {
      return useResourceFilterStore().filters.length > 0;
    },

    loading() {
      return (
        Boolean(useResourceListStore().currentPromise) ||
        !useResourceListStore().currentState
      );
    },

    store() {
      return useResourceListStore();
    },

    state() {
      return useResourceListStore().currentState;
    },

    readonly() {
      return config.appConfig.readOnlyMode;
    },

    resourceConfig() {
      return useResourceListStore().resourceConfig;
    },

    noDataFound(): boolean {
      return !this.loading && this.state?.resourceList.length === 0;
    },

    tableHeaderConfig(): any[] {
      const columns = this.resourceConfig.columns;
      return columns.map((column, index) => ({
        text: column.label,
        value: index,
        slot: "item." + String(index),

        sortable: Boolean(column.internalField),
        filterable: Boolean(column.internalField),

        width: 100 / columns.length + "%",

        original: column,
        renderer:
          COLUMN_RENDERERS[column.renderer || "text"] ||
          COLUMN_RENDERERS["text"],
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler: async function() {
        try {
          this.store.setResourceType(this.$route.params.resourceType);

          restoreFilterStateFromRoute();

          if (this.anyFiltersActive) {
            this.filtersVisible = true;
          }

          await this.store.refresh();
        } finally {
          // Handle invalid routes
          if (!this.resourceConfig) {
            router.push("/");
            return;
          }
        }
      },
    },
  },

  mounted() {
    this.loaded = true;
  },

  methods: {
    resolveColumnPaths,

    changePage(page: number) {
      this.store.updatePaginationAndRefresh({
        page,
        sortColumnIndex: this.state.sortColumnIndex as number,
        sortDescending: this.state.sortDescending,
      });
    },

    selectEntry(row: any) {
      if (!this.resourceConfig) {
        console.warn("no config entry found, cannot navigate");
        return;
      }
      router.push({
        name: "Explore",
        params: {
          resourceType: this.resourceConfig.baseResource,
          id: row.id,
        },
      });
    },

    async updateOptions(options: any) {
      if (!this.loaded) {
        return;
      }

      await this.store.updatePaginationAndRefresh({
        page: this.state.paginationPage, // keep page
        sortDescending: Boolean(options.sortDesc[0]),
        sortColumnIndex: Number(options.sortBy[0] || 0),
      });
    },

    createResource() {
      router.push({
        name: "Create",
        params: {
          resourceType: this.$route.params.resourceType,
        },
      });
    },
  },
});
