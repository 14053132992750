import {
    ArrayWrapper,
    BaseDefinition,
    BaseWrapper,
    DefaultFieldsDownstreamPayload,
    ElementWrapper,
    InvalidProfileError,
    isNullOrUndefined,
    SlicedElementDefinition,
    SlicingDiscriminatorDefinition,
} from '@/lib-on-fhir';

/**
 * Definition for a generic element which can have child elements
 */
export class ElementDefinition extends BaseDefinition {
    typeName!: string;
    canonical?: string;

    fieldTypes: Record<string, BaseDefinition> = {};

    slicingFieldTypes: Record<string, SlicedElementDefinition> = {};
    slicingDiscriminators: SlicingDiscriminatorDefinition[] = [];

    addDefaultFieldOnObject(
        target: any,
        key: string | number,
        payload: DefaultFieldsDownstreamPayload
    ) {
        // Priority system: Local patterns ALWAYS override parent patterns
        payload.pattern = this.pattern || payload.pattern;
        payload.fixedValue = this.fixedValue || payload.fixedValue;
        payload.defaultValue = this.defaultValue || payload.defaultValue;

        let result: Record<string, any> = {};
        for (const [fieldId, fieldDefinition] of Object.entries(this.fieldTypes)) {
            const childPayload = {
                pattern: payload.pattern?.[fieldId],
                fixedValue: payload.fixedValue?.[fieldId],
                defaultValue: payload.defaultValue?.[fieldId],
            };

            // Add the field if its required, or has a pattern, fixed or default value
            if (
                fieldDefinition.cardinality.min > 0 ||
                fieldDefinition.fixedValue ||
                fieldDefinition.defaultValue ||
                fieldDefinition.pattern
            ) {
                fieldDefinition.addDefaultFieldOnObject(result, fieldId, childPayload);
            } else if (payload.fixedValue?.[fieldId]) {
                result[fieldId] = payload.fixedValue[fieldId];
            } else if (payload.pattern?.[fieldId]) {
                result[fieldId] = payload.pattern[fieldId];
            } else if (payload.defaultValue?.[fieldId]) {
                result[fieldId] = payload.defaultValue[fieldId];
            }
        }

        if (Array.isArray(target[key])) {
            target[key].push(result);
        } else if (!isNullOrUndefined(target[key])) {
            target[key] = [target[key], result];
        } else {
            target[key] = result;
        }
    }

    copyShallowFrom(other: ElementDefinition) {
        super.copyShallowFrom(other);
        if (!(other instanceof ElementDefinition)) {
            throw this.logger.scopedException(
                InvalidProfileError,
                'copyShallowFrom(): Target type mismatch, is not an element'
            );
        }

        this.typeName = other.typeName;
        this.fieldTypes = other.fieldTypes;
        this.slicingFieldTypes = other.slicingFieldTypes;
        this.slicingDiscriminators = other.slicingDiscriminators;
        this.canonical = other.canonical;
    }

    wrapResourceWithinArray(resource: any, key: string, index: number): BaseWrapper | undefined {
        const result = new ElementWrapper();
        result.fields = {};
        result.$type = this;
        result._ref = {
            resource,
            key,
            index,
        };

        let resolved = resource[key];
        if (Array.isArray(resolved)) {
            resolved = resolved[index];
        } else {
            if (index !== 0) {
                throw this.logger.scopedException(Error, 'Access out of bounds');
            }
        }

        for (const [fieldId, field] of Object.entries(this.fieldTypes)) {
            const value = field.wrapResourceWithinObject(resolved, fieldId);
            if (!isNullOrUndefined(value)) {
                result.fields[fieldId] = value!;
            }
        }
        return result;
    }

    wrapResourceWithinObject(resource: any, key: string): BaseWrapper | undefined {
        let value: any[] = resource[key];
        if (isNullOrUndefined(value)) {
            return;
        }

        if (!Array.isArray(value)) {
            value = [value];
        }

        const arrayWrapper = new ArrayWrapper();
        arrayWrapper.$type = this;
        arrayWrapper._ref = {
            resource: resource,
            key: key,
        };
        arrayWrapper.items = value.map((subValue: any, index) => {
            if (isNullOrUndefined(subValue)) {
                this.logger.warn(
                    'empty sub value on element definition:',
                    subValue,
                    value,
                    resource,
                    key
                );
                return null;
            }

            for (const sliceDefinition of Object.values(this.slicingFieldTypes)) {
                if (
                    this.slicingDiscriminators.every(discriminator =>
                        discriminator.test(subValue, sliceDefinition)
                    )
                ) {
                    return sliceDefinition.wrapResourceWithinArray(resource, key, index);
                }
            }
            return this.wrapResourceWithinArray(resource, key, index);
        }) as BaseWrapper[];
        return arrayWrapper;
    }

    toString(): string {
        return `#${this.typeName}`;
    }
}
